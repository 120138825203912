import router from '@/router'

export default {
  state: {
    detailedRubric: []
  },

  mutations: {
    setDetailedRubric (state, payload) {
      state.detailedRubric = payload
    }
  },

  actions: {
    async setDetailedRubric (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/rubric/${payload}`)
      res = await res.json()
      this.commit('setDetailedRubric', await res)
    },

    async deleteRubric (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/rubric/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Рубрика успешно удалена'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('getRubrics')
      })
        .catch(error => console.log('error', error))
    },

    async createRubric (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/rubric`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/rubric')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание рубрики прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editRubric (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/rubric/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/rubric')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение рубрики прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getDetailedRubric: (s) => s.detailedRubric
  }
}
