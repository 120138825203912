import router from '@/router'
export default {
  state: {
    detailUser: null
  },
  mutations: {
    setDetailUser (state, payload) {
      console.log(payload)
      state.detailUser = payload
    }
  },
  actions: {
    async setDetailUser (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/user/${payload}`,
        {
          headers: {
            Localization: 'admin',
            Authorization: localStorage.getItem('token')
          }
        })
      res = await res.json()
      ctx.commit('setDetailUser', await res.data[0])
      await router.push('/admin/users/create_user?type=edit')
    },
    async editUser (ctx, payload) {
      let url = `${ctx.getters.getUrlApi}api/admin/user/${payload.id}/update?firstName=${payload.firstName}&lastName=${payload.lastName}&email=${payload.email}&patronymic=${payload.patronymic}&changePassword=0&role=${payload.role}&status=${payload.status}`
      if (payload.phone) {
        url += `&phone=+${payload.phone}`
      }
      if (payload.memberId) {
        url += `&memberId=+${payload.memberId}`
      }
      if (payload.nomination_id) {
        url += `&nomination_id=+${payload.nomination_id}`
      }
      if (payload.max_appeal_count) {
        url += `&max_appeal_count=+${payload.max_appeal_count}`
      }
      console.log(url)
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/users')
            ctx.dispatch('setUserAll', ctx.getters.getUrlApi)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация о пользователе успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getDetailUser (s) {
      return s.detailUser
    }
  }
}
