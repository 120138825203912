import { render, staticRenderFns } from "./VInputSearch.vue?vue&type=template&id=6d8e24ed&scoped=true&"
import script from "./VInputSearch.vue?vue&type=script&lang=js&"
export * from "./VInputSearch.vue?vue&type=script&lang=js&"
import style0 from "./VInputSearch.vue?vue&type=style&index=0&id=6d8e24ed&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d8e24ed",
  null
  
)

export default component.exports