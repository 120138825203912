import { fetch as fetchPolifil } from 'whatwg-fetch'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CKEditor from 'ckeditor4-vue'
import trimLine from '@/directives/trimLine'
import VShowSlide from 'v-show-slide'
import VueNoty from 'vuejs-noty'
import VueYandexMetrika from 'vue-yandex-metrika'
import VueFetch from 'vue-fetch'
Vue.config.productionTip = false

Vue.use(VueFetch, {
  polyfill: true
})
window.fetch = fetchPolifil

Vue.directive('trim', trimLine)

Vue.use(CKEditor)

const CKEditorConfig = {
  extraAllowedContent: 'summary details'
}

Vue.prototype.$editorConfig = { ...CKEditorConfig }

Vue.prototype.$editorConfigEng = { ...CKEditorConfig }

Vue.use(VShowSlide)
Vue.use(VueNoty)
Vue.use(VueYandexMetrika, {
  id: 10063576,
  router: router,
  env: process.env.NODE_ENV
  // other options
})

new Vue({
  router,
  store,
  data () {
    return {
      editorUrl: ''
    }
  },
  created () {
    const splitedUrl = window.location.href.split('/')
    this.editorUrl = `${splitedUrl[0]}//${splitedUrl[2]}/vueDev/ckeditor/ckeditor.js`
  },
  render: h => h(App)
}).$mount('#app')
