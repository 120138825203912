export default {
  state: {
    sections: [],
    page: null
  },

  mutations: {
    setSections (state, payload) {
      state.sections = payload
    },

    setPage (state, payload) {
      state.page = payload
    }
  },

  actions: {
    async getSections (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/section`)
      res = await res.json()
      this.commit('setSections', await res.data)
    },

    async getPage (ctx, id) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/page/${id}`)
      res = await res.json()
      this.commit('setPage', await res)
    }
  },

  getters: {
    getSections: (s) => s.sections,
    getPage: (s) => s.page
  }
}
