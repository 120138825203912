import router from '@/router'

export default {

  state: {
    currentAdminMedia: null,
    medias: [],
    mediaTypes: [],

    adminMediaSearch: {
      key: null,
      date_start: null,
      date_end: null,
      rubric: null
    },
    adminMediaPagination: {
      offset: 0,
      limit: 9,
      count: 0,
      total: 0
    }
  },

  mutations: {
    setMedias (state, payload) {
      state.medias = payload
    },

    setMediaTypes (state, payload) {
      state.mediaTypes = payload
    },

    setCurrentAdminMedia (state, payload) {
      state.currentAdminMedia = payload
    },

    setAdminMediaSearch (state, payload) {
      state.adminMediaSearch = payload
    },

    setAdminMediaPaginationOffset (state, payload) {
      state.adminMediaPagination.offset = payload
    },

    setAdminMediaPagination (state, payload) {
      state.adminMediaPagination = {
        offset: payload.offset,
        limit: payload.limit,
        count: payload.count,
        total: payload.total
      }
    }
  },

  actions: {
    async getCurrentAdminMedia (ctx, id) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/media/${id}`, {
        method: 'GET',
        headers: {
          Localization: 'admin'
        }
      })
      res = await res.json()
      this.commit('setCurrentAdminMedia', await res.data)
    },

    async getMedias (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/media${ctx.getters.getAdminMediaPaginationParams}${ctx.getters.getAdminMediaSearch}`, {
        method: 'GET',
        headers: {
          Localization: 'admin',
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setAdminMediaPagination', await res)
      this.commit('setMedias', await res.media)
    },

    async deleteMedia (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/media/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Медиа №${payload} успешно удалена`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('getMedias')
      })
        .catch(error => console.log('error', error))
    },

    async createMedia (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/media`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/media')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание медиа прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async updateMedia (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/media/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/media')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение медиа прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async getMediaTypes (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/media_type`)
      res = await res.json()
      this.commit('setMediaTypes', await res.media_type)
    }
  },

  getters: {
    getMedias: (s) => s.medias,
    getAdminMediaSearch: (s) => {
      let queryString = ''

      for (const key in s.adminMediaSearch) {
        if (s.adminMediaSearch[key]) {
          queryString += `&${key}=${s.adminMediaSearch[key]}`
        }
      }

      return queryString
    },
    getAdminMediaPaginationParams: s => {
      return `?offset=${s.adminMediaPagination.offset}&limit=${s.adminMediaPagination.limit}`
    },
    getAdminMediaPagination: (s) => s.adminMediaPagination,
    getMediaTypes: (s) => s.mediaTypes,
    getCurrentAdminMedia: (s) => s.currentAdminMedia
  }

}
