import router from '@/router'

export default {
  state: {
    mainInformation: [],
    detailedMainInformation: []
  },
  mutations: {
    setMainInformation (state, payload) {
      state.mainInformation = payload
    },
    setDetailedMainInformation (state, payload) {
      state.detailedMainInformation = payload
    }
  },
  actions: {
    async setMainInformation (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/main_information`)
      res = await res.json()
      this.commit('setMainInformation', await res)
    },

    async setAdminMainInformation (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/main_information`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setMainInformation', await res)
    },

    async setDetailedMainInformation (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/main_information/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setDetailedMainInformation', await res)
    },

    async deleteMainInformation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/main_information/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Основная информация успешно удалена'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setMainInformation')
      })
        .catch(error => console.log('error', error))
    },

    async createMainInformation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/main_information`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/main_information')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание основной информации прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editMainInformation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/main_information/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/main_information')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение основной информации прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getMainInformation: (s) => s.mainInformation,
    getDetailedMainInformation: (s) => s.detailedMainInformation
  }
}
