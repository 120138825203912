import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/store/api'
import lang from '@/store/lang'
import MembersChamber from '@/store/members_chamber'
import News from '@/store/news'
import LiveStream from '@/store/liveStream'
import Media from '@/store/media'
import Blog from '@/store/blog'
import Topic from '@/store/topic'
import Announcement from '@/store/announcement'
import StructureChamber from '@/store/structure_chamber'
import MemberProfile from '@/store/chamber_member_profile'
import RegulatoryDocumentsList from '@/store/chamber_document_list'
import Appeal from '@/store/appeal'
import feedback from '@/store/feedback'
import search from '@/store/search'
import users from '@/store/admin/user/users'
import adminApi from '@/store/admin/adminApi'
import adminStatus from '@/store/admin/status'
import adminRole from '@/store/admin/role'
import news from '@/store/admin/news'
import rubric from '@/store/admin/rubric'
import subject from '@/store/admin/subject'
import publicCouncilBanner from '@/store/admin/publicCouncilBanner'
import posts from '@/store/posts'
import announcement from '@/store/admin/announcement'
import liveStream from '@/store/admin/liveStream'
import newsComment from '@/store/admin/newsComment'
import topic from '@/store/admin/topic'
import media from '@/store/admin/media'
import contacts from '@/store/admin/contacts'
import auth from '@/store/auth'
import forum from '@/store/forum'
import adminEditUser from '@/store/admin/user/userEdit'
import memberId from '@/store/admin/aboutChamber/member/memberId'
import adminMember from '@/store/admin/aboutChamber/member/members'
import Country from '@/store/admin/summaryData/country'
import personalAccount from '@/store/personal_account'
import Polls from '@/store/admin/summaryData/polls'
import SystemTag from '@/store/admin/summaryData/SystemTag'
import Convocation from '@/store/admin/summaryData/сonvocation'
import Unit from '@/store/admin/summaryData/unit'
import UnitType from '@/store/admin/summaryData/unitType'
import Documents from '@/store/admin/active/documents'
import billDraft from '@/store/admin/active/billDraft'
import Partners from '@/store/admin/aboutChamber/partners'
import Project from '@/store/admin/active/project'
import blogsAdmin from '@/store/admin/aboutChamber/blogs'
import Accreditation from '@/store/admin/summaryData/accreditation'
import Positions from '@/store/admin/summaryData/position'
import Сorruption from '@/store/admin/aboutChamber/corruption'
import Structure from '@/store/admin/aboutChamber/structure'
import commissionUpdate from '@/store/admin/active/commissionUpdate'
import commentsBlogs from '@/store/admin/comments/commentsBlogs'
import commentsBill from '@/store/admin/comments/commentsBill'
import commentsTopical from '@/store/admin/comments/commentsTopical'
import infoNko from '@/store/admin/active/infoNko'
import nkoPartners from '@/store/admin/active/nkoParthers'
import publicCouncils from '@/store/admin/publicCouncils'
import emailMarket from '@/store/admin/emailMarket'
import normativeBase from '@/store/admin/aboutChamber/normativeBase'
import InclusionGround from '@/store/admin/summaryData/inclusionGround'
import forumAdmin from '@/store/admin/forum'
import aboutTheWard from '@/store/admin/aboutTheWard'
import corruptionSection from '@/store/admin/summaryData/corruptionSection'
import corruptionGroup from '@/store/admin/summaryData/corruptionGroup'
import recommendation from '@/store/admin/aboutChamber/recommendation'
import nomination from '@/store/admin/myProject/nomination'
import period from '@/store/admin/myProject/period'
import engFlag from '@/store/admin/engFlag'
import projectPrinciple from '@/store/admin/myProject/projectPrinciple'
import additionalMaterial from '@/store/admin/myProject/additionalMaterial'
import projectAppealText from '@/store/admin/myProject/projectAppealText'
import mainInformation from '@/store/admin/myProject/mainInformation'
import projectTitleLink from '@/store/admin/myProject/projectTitleLink'
import indexTag from '@/store/admin/indexTag'
import projectEmail from '@/store/admin/myProject/projectEmail'
import keyDates from '@/store/admin/myProject/keyDates'
import projectAppeal from '@/store/projectAppeal'
import emailSubscription from '@/store/admin/feedback/emailSubscription'
import createInfoAdvice from '@/store/admin/active/infoAdvice'
import projectEvaluation from '@/store/admin/myProject/projectEvaluation'
import apparatusCategory from '@/store/admin/aboutChamber/apparatusCategory'
import apparatusDocuments from '@/store/admin/aboutChamber/apparatusDocuments'
import hotlineInfo from '@/store/admin/hotlineInfo'
import statusBill from '@/store/admin/statusBill'
import section from '@/store/section'
import sectionAdmin from '@/store/admin/sectionAdmin'
import pageAdmin from '@/store/admin/pageAdmin'
import logAdmin from '@/store/admin/logAdmin'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    lang,
    engFlag,
    section,
    sectionAdmin,
    logAdmin,
    pageAdmin,
    MembersChamber,
    News,
    Announcement,
    LiveStream,
    Media,
    Blog,
    Topic,
    StructureChamber,
    MemberProfile,
    RegulatoryDocumentsList,
    feedback,
    search,
    Appeal,
    users,
    adminApi,
    adminStatus,
    adminRole,
    adminEditUser,
    adminMember,
    auth,
    forum,
    memberId,
    news,
    rubric,
    subject,
    publicCouncilBanner,
    posts,
    announcement,
    media,
    contacts,
    liveStream,
    newsComment,
    topic,
    Country,
    aboutTheWard,
    Polls,
    SystemTag,
    personalAccount,
    Convocation,
    Unit,
    UnitType,
    Documents,
    billDraft,
    Partners,
    Project,
    blogsAdmin,
    Accreditation,
    Positions,
    Сorruption,
    Structure,
    commissionUpdate,
    commentsBlogs,
    commentsBill,
    commentsTopical,
    infoNko,
    nkoPartners,
    emailMarket,
    publicCouncils,
    normativeBase,
    InclusionGround,
    forumAdmin,
    corruptionSection,
    corruptionGroup,
    recommendation,
    nomination,
    period,
    projectPrinciple,
    additionalMaterial,
    projectAppealText,
    mainInformation,
    projectTitleLink,
    indexTag,
    projectEmail,
    keyDates,
    projectAppeal,
    emailSubscription,
    createInfoAdvice,
    projectEvaluation,
    apparatusCategory,
    apparatusDocuments,
    hotlineInfo,
    statusBill
  }
})
