import router from '@/router'

export default {
  state: {
    currentAdminAnnouncement: null,
    announcements: [],

    adminAnnouncementSearch: {
      key: null,
      date_start: null,
      date_end: null,
      rubric: null,
      system_tags_list: null
    },
    adminAnnouncementPagination: {
      offset: 0,
      limit: 9,
      count: 0,
      total: 0
    }
  },

  mutations: {
    setAnnouncements (state, payload) {
      state.announcements = payload
    },

    setCurrentAdminAnnouncement (state, payload) {
      state.currentAdminAnnouncement = payload
    },

    setAdminAnnouncementSearch (state, payload) {
      state.adminAnnouncementSearch = payload
    },

    setAdminAnnouncementPaginationOffset (state, payload) {
      state.adminAnnouncementPagination.offset = payload
    },

    setAdminAnnouncementPagination (state, payload) {
      state.adminAnnouncementPagination = {
        offset: payload.offset,
        limit: payload.limit,
        count: payload.count,
        total: payload.total
      }
    }
  },

  actions: {
    async getAdminAnnouncementById (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/announcement/${payload}`, {
        method: 'GET',
        headers: {
          Localization: 'admin'
        }
      })
      res = await res.json()
      this.commit('setCurrentAdminAnnouncement', await res.announcement)
    },

    async getAnnouncements (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/announcement${ctx.getters.getAdminAnnouncementPaginationParams}${ctx.getters.getAdminAnnouncementSearch}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setAdminAnnouncementPagination', await res)
      this.commit('setAnnouncements', await res.announcement)
    },

    async deleteAnnouncement (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/announcement/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Анонс №${payload} успешно удален`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('getAnnouncements')
      })
        .catch(error => console.log('error', error))
    },

    async createAnnouncement (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/announcement`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/announcement')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание анонса прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async updateAnnouncement (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/announcement/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/announcement')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение анонса прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getAnnouncements: (s) => s.announcements,
    getAdminAnnouncementSearch: (s) => {
      let queryString = ''

      for (const key in s.adminAnnouncementSearch) {
        if (s.adminAnnouncementSearch[key]) {
          queryString += `&${key}=${s.adminAnnouncementSearch[key]}`
        }
      }

      return queryString
    },
    getAdminAnnouncementPaginationParams: s => {
      return `?offset=${s.adminAnnouncementPagination.offset}&limit=${s.adminAnnouncementPagination.limit}`
    },
    getAdminAnnouncementPagination: (s) => s.adminAnnouncementPagination,
    getCurrentAdminAnnouncement: (s) => s.currentAdminAnnouncement
  }
}
