import router from '@/router'
export default {
  state: {
    users: [],
    counter: 0,
    message: {
      type: '',
      text: ''
    }
  },
  mutations: {
    setUserAll (state, payload) {
      state.users = payload.objects
      state.counter = payload.count
    },
    setMessage (state, payload) {
      state.message = payload
    }
  },
  actions: {
    async setUserAll (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/user`)
      res = await res.json()
      ctx.commit('setUserAll', await res.data)
    },
    async createUser (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/user/create`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            ctx.dispatch('setUserAll', `${ctx.getters.getUrlApi}`)
            router.push('/admin/users')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание пользователя прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteUser (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/user/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setUserAll', `${ctx.getters.getUrlApi}`)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Пользователь был удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async filterUser (ctx, payload) {
      const url = `${ctx.getters.getUrlApi}api/admin/user?role=${payload.role}&name=${payload.name}&status=${payload.status}&email=${payload.email}`
      let res = await fetch(url)
      res = await res.json()
      console.log(await res.data)
      ctx.commit('setUserAll', await res.data)
    }
  },
  getters: {
    getUsers (s) {
      return s.users
    },
    getUsersCounter (s) {
      return s.counter
    },
    getMessage (s) {
      return s.message
    }
  }
}
