import router from '@/router'

export default {
  state: {
    partners: [],
    detailPartners: null
  },
  mutations: {
    setPartners (state, payload) {
      state.partners = payload
    },
    setDetailPartners (state, payload) {
      state.detailPartners = payload
    }
  },
  actions: {
    async setPartners (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/partner`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setPartners', await res.data.internationalPartner)
    },
    async setFilterPartners (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/partner?name=${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setPartners', await res.data.internationalPartner)
    },
    async setDetailPartners (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/partner/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailPartners', await res.data)
      await router.push('/admin/partners/create?type=edit')
    },
    async createPartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/partner`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setPartners', ctx.getters.getUrlApi)
            router.push('/admin/partners')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание партнера прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deletePartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/partner/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setPartners')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Партнер был удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editPartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/partner/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setPartners')
            router.push('/admin/partners')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение профиля прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getPartners (s) {
      return s.partners
    },
    getDetailPartners (s) {
      return s.detailPartners
    }
  }
}
