<template>
  <div class="auth">
    <section v-if="!mainShow">
        <form @submit.prevent="login">
          <p class="auth__header">Войти</p>
          <p class="auth__title">Email</p>
          <input v-model.trim="form.login" class="input auth__input" type="email">
          <p class="auth__title">Пароль</p>
          <div class="auth__input-password" :class="{'show_password': isPasswordOpen}">
            <!-- <data-field key="dataField_3" :data-field="{isPassword: form.password}"/> -->
            <input v-model.trim="form.password" class="input auth__input" :type="!isPasswordOpen? 'password' : 'text'">
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" @click="togglePasswordOpen()">
              <path d="M17.8824 5.23578C15.652 1.86045 12.6571 0 9.44934 0C6.24155 0 3.24669 1.86045 1.01624 5.23578C0.859169 5.47314 0.859169 5.7838 1.01624 6.02115C3.24669 9.39649 6.24155 11.2569 9.44934 11.2569C12.6571 11.2569 15.652 9.39649 17.8824 6.02115C18.0395 5.7838 18.0395 5.47663 17.8824 5.23578ZM9.44934 9.8328C6.85938 9.8328 4.39507 8.34584 2.47179 5.63021C4.39158 2.91459 6.85589 1.42762 9.44934 1.42762C12.0393 1.42762 14.5036 2.91459 16.4269 5.63021C14.5036 8.34584 12.0428 9.8328 9.44934 9.8328Z" fill="#246CB7"/>
              <path d="M9.44958 1.87793C7.3797 1.87793 5.69727 3.56036 5.69727 5.63024C5.69727 7.70012 7.3797 9.38255 9.44958 9.38255C11.5195 9.38255 13.2019 7.70012 13.2019 5.63024C13.2019 3.56036 11.5195 1.87793 9.44958 1.87793ZM9.44958 8.0038C8.16506 8.0038 7.11791 6.93919 7.11791 5.63024C7.11791 4.3213 8.16506 3.25669 9.44958 3.25669C10.7341 3.25669 11.7812 4.3213 11.7812 5.63024C11.7812 6.93919 10.7341 8.0038 9.44958 8.0038Z" fill="#246CB7"/>
              <rect width="1.79656" height="19.3653" transform="matrix(-0.5 0.866025 0.866025 0.5 0.898438 0)" fill="#246CB7"/>
            </svg>
          </div>
<!--          <div class="g-recaptcha auth__title" data-size="compact" data-sitekey=""></div>-->
          <vue-recaptcha
            ref="recaptcha"
            size="normal"
            class="auth__title"
            sitekey="6LcGwIAaAAAAAJwMKzo3XCfNiwfIP6ewRHlM4WqL"
            :loadRecaptchaScript="true"
            @verify="register"
            @expired="onCaptchaExpired"
          />
          <button class="btn auth__button" type="submit" :disabled="isDisabledButton">Войти</button>
        </form>
        <div class="auth__footer">
          <router-link to="/registration" @click.native="close()">Зарегистрироваться</router-link>
          <p @click="clickMainShow">Забыли пароль?</p>
        </div>
    </section>

    <section v-if="mainShow">
        <form @submit.prevent="login">
          <p class="auth__header">Восстановить пароль</p>
          <p class="auth__title">Email</p>
          <input v-model.trim="form.login" class="input auth__input" type="email">
          <p class="auth__title">Новый пароль</p>
          <div class="auth__input-password" :class="{'show_password': isPasswordOpen}">
            <input v-model.trim="form.password" class="input auth__input" :type="!isPasswordOpen? 'password' : 'text'">
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" @click="togglePasswordOpen()">
              <path d="M17.8824 5.23578C15.652 1.86045 12.6571 0 9.44934 0C6.24155 0 3.24669 1.86045 1.01624 5.23578C0.859169 5.47314 0.859169 5.7838 1.01624 6.02115C3.24669 9.39649 6.24155 11.2569 9.44934 11.2569C12.6571 11.2569 15.652 9.39649 17.8824 6.02115C18.0395 5.7838 18.0395 5.47663 17.8824 5.23578ZM9.44934 9.8328C6.85938 9.8328 4.39507 8.34584 2.47179 5.63021C4.39158 2.91459 6.85589 1.42762 9.44934 1.42762C12.0393 1.42762 14.5036 2.91459 16.4269 5.63021C14.5036 8.34584 12.0428 9.8328 9.44934 9.8328Z" fill="#246CB7"/>
              <path d="M9.44958 1.87793C7.3797 1.87793 5.69727 3.56036 5.69727 5.63024C5.69727 7.70012 7.3797 9.38255 9.44958 9.38255C11.5195 9.38255 13.2019 7.70012 13.2019 5.63024C13.2019 3.56036 11.5195 1.87793 9.44958 1.87793ZM9.44958 8.0038C8.16506 8.0038 7.11791 6.93919 7.11791 5.63024C7.11791 4.3213 8.16506 3.25669 9.44958 3.25669C10.7341 3.25669 11.7812 4.3213 11.7812 5.63024C11.7812 6.93919 10.7341 8.0038 9.44958 8.0038Z" fill="#246CB7"/>
              <rect width="1.79656" height="19.3653" transform="matrix(-0.5 0.866025 0.866025 0.5 0.898438 0)" fill="#246CB7"/>
            </svg>
          </div>
          <vue-recaptcha
            ref="recaptcha"
            size="normal"
            class="auth__title"
            sitekey="6LcGwIAaAAAAAJwMKzo3XCfNiwfIP6ewRHlM4WqL"
            :loadRecaptchaScript="true"
            @verify="register"
            @expired="onCaptchaExpired"
          />
          <button class="btn auth__button" type="submit" :disabled="isDisabledButton">Отправить</button>
        </form>
        <div class="auth__footer">
          <p @click="clickMainShow">Вернуться назад</p>
        </div>
    </section>

    <svg @click="close()" class="auth__button-close" width="58" height="68" viewBox="0 0 58 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
      <rect width="37.2059" height="37.2059" rx="18.603" transform="matrix(-4.37114e-08 1 -0.999664 0.0259362 53.1934 6)" fill="white"/>
      </g>
      <rect x="28.6309" y="20.8525" width="2" height="8" rx="1" transform="rotate(-45 28.6309 20.8525)" fill="#5A79A8"/>
      <rect x="38.5303" y="19.4375" width="2" height="8" rx="1" transform="rotate(45 38.5303 19.4375)" fill="#5A79A8"/>
      <rect x="39.9453" y="29.3369" width="2" height="8" rx="1" transform="rotate(135 39.9453 29.3369)" fill="#5A79A8"/>
      <rect x="30.0459" y="30.752" width="2" height="8" rx="1" transform="rotate(-135 30.0459 30.752)" fill="#5A79A8"/>
      <defs>
      <filter id="filter0_d" x="0.824233" y="0.406256" width="67.545" height="67.5702" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
      <feOffset dy="9.10546"/>
      <feGaussianBlur stdDeviation="7.58788"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.740278 0 0 0 0 0.802183 0 0 0 0 0.866667 0 0 0 0.35 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
      </defs>
    </svg>

  </div>
</template>
<script>
// import DataField from '../personalAccount/DataField'
import VueRecaptcha from 'vue-recaptcha'
export default {
  name: 'VAuth',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loginTry: 0,
      mainShow: false,
      isPasswordOpen: false,
      recaptchaToken: null,
      form: {
        login: '',
        password: ''
      }
    }
  },
  methods: {
    togglePasswordOpen () {
      this.isPasswordOpen = !this.isPasswordOpen
    },
    close () {
      this.$emit('click')
    },
    validate () {
      this.$refs.recaptcha.execute()
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },
    register (recaptchaToken) {
      this.recaptchaToken = recaptchaToken
    },
    login () {
      if (!this.mainShow) {
        const cookies = document.cookie.split('; ')
        const cookiesObject = {}
        cookies.forEach((cookie) => {
          cookie = cookie.split('=')
          cookiesObject[cookie[0]] = cookie[1]
        })
        console.debug(cookiesObject)
        if ('loginTry' in cookiesObject) {
          this.$noty.error('Вы неправильно авторизовывались более 3-х раз. В целях безопасности авторизация под Вашим аккаунтом временно заблокирована. Попробуйте через сутки!')
        } else {
          if (this.recaptchaToken) {
            const formData = new FormData()
            formData.append('email', this.form.login)
            formData.append('password', this.form.password)
            formData.append('recaptchaToken', this.recaptchaToken)
            const requestOptions = {
              method: 'POST',
              body: formData
            }
            fetch(`${this.$store.getters.getUrlApi}api/auth/login`, requestOptions)
              .then(res => {
                console.log(res.ok)
                if (res.ok) {
                  this.$noty.success('Авторизация прошла успешно')
                } else {
                  this.loginTry++
                  if (this.loginTry >= 3) {
                    let date = new Date(Date.now() + 86400e3)
                    date = date.toUTCString()
                    document.cookie = 'loginTry=мы не тупили, просто очень спешили. уходи.; expires=' + date
                  }
                  this.$noty.error('При авторизации произошла ошибка')
                }
                return res
              })
              .then(response => response.json())
              .then(data => {
                console.log(data)
                this.$store.dispatch('saveToken', data.data)
                this.$store.dispatch('fetchUser')
                this.close()
              })
          } else {
            return false
          }
        }
      } else {
        if (this.recaptchaToken) {
          const formData = new FormData()
          formData.append('email', this.form.login)
          formData.append('password', this.form.password)
          fetch(`${this.$store.getters.getUrlApi}api/auth/resetPassword`, {
            method: 'POST',
            body: formData
          })
            .then(res => {
              if (res.status === 200) {
                this.close()
                this.isPasswordOpen = false
                this.$noty.success('Письмо отправлено на почту')
              } else {
                this.$noty.error('При восстановление произошла ошибка')
              }
            })
            .catch(error => console.log('error', error))
        } else {
          return false
        }
      }
    },
    clickMainShow () {
      this.mainShow = !this.mainShow
      this.form.password = ''
    }
  },
  computed: {
    isDisabledButton () {
      return !(this.form.login.length > 0 && this.form.password.length > 0)
    }
  },
  components: { VueRecaptcha }
}
</script>

<style scoped lang="scss">
  .auth {
    position: relative;
    padding: 1.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 3px;
    width: 90%;
    max-width: 36rem;
    margin: auto;

    &__header {
      font-weight: 600;
      font-size: 1rem;
      line-height: 137%;
      color: #1F3245;
      padding-bottom: 0.625rem;
      margin-bottom: 1.25rem;
      border-bottom: 1px solid #EEF4FA;
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      a, p {
        font-size: 0.75rem;
        line-height: 167%;
        color: #246CB7;
        cursor: pointer;
      }
    }
    &__title {
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 150%;
      color: #1F3245;
      margin: 0.75rem 0;
    }
    &__input {
      background: #EEF4FA;
      border-radius: 3px;
    }
    &__input-password {
      position: relative;
      svg {
        position: absolute;
        width: 1.1rem;
        right: 1rem;
        top: 0;
        height: 100%;
        cursor: pointer;
      }
    }
    &__button {
      border: none;
      padding: 0.625rem 1.625rem;
      line-height: 143%;
      background: #246CB7;
      border-radius: 3px;
      color: #FFFFFF;
      margin: 1.5rem 0 1.25rem;
    }
    &__button-close {
      position: absolute;
      top: -1rem;
      right: -1rem;
      cursor: pointer;
    }

  }
  .show_password svg rect{
    display: none;
  }
  @media screen and (max-width: 768px){

  }
  @media screen and (max-width: 420px){

  }
</style>
