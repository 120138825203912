import router from '@/router'

export default {
  state: {
    banners: [],
    detailedBanner: []
  },

  mutations: {
    setBanners (state, payload) {
      state.banners = payload
    },

    setDetailedBanner (state, payload) {
      state.detailedBanner = payload
    }
  },
  actions: {
    async setBanners (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/public_council_banner`)
      res = await res.json()
      this.commit('setBanners', await res)
    },

    async setDetailedBanner (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/public_council_banner/${payload}`)
      res = await res.json()
      this.commit('setDetailedBanner', await res)
    },

    async deleteBanner (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/public_council_banner/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Баннер успешно удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setBanners')
      })
        .catch(error => console.log('error', error))
    },

    async createBanner (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/public_council_banner`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/public_council_banner')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание баннера прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editBanner (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/public_council_banner/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/public_council_banner')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение баннера прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getBanners: (s) => s.banners,
    getDetailedBanner: (s) => s.detailedBanner
  }
}
