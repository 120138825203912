// import router from '@/router'

export default {
  state: {
    systemTagList: []
  },
  mutations: {
    setSystemTagList (state, payload) {
      state.systemTagList = payload
    }
  },
  actions: {
    async setSystemTagList (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/directory/system-tag-list`)
      res = await res.json()
      ctx.commit('setSystemTagList', await res.data)
    }
  },
  getters: {
    getSystemTagList (s) {
      return s.systemTagList
    }
  }
}
