export default {
  state: {
    allEntities: [],
    importantNews: [],
    similarNews: [],
    lightningNews: [],

    onlyWithTags: null,

    currentNews: null,
    publicNews: [],
    tags: [],

    newsCommissions: [],

    filter: {
      key: null,
      title: null,
      rubric: null,
      year: null,
      tagList: null,
      tagListEng: null,
      id: null,
      systemTagList: null,
      withActual: null
    },

    newsPagination: {
      offset: 0,
      limit: 9,
      count: 0,
      total: 0
    },

    isSearch: false,
    routeParams: {},
    lightBoxImage: null
  },

  mutations: {
    setRouteParams (state, payload) {
      state.routeParams = payload
    },
    setLightBoxImage (state, payload) {
      state.lightBoxImage = payload
    },
    setNewsOnlyWithTags (state, paylaod) {
      state.onlyWithTags = paylaod
    },

    // FILTER MUTATIONS
    setFilterKey (state, payload) {
      state.filter.key = payload
    },
    setFilterRubric (state, payload) {
      state.filter.rubric = payload
    },
    setFilterWithActual (state, payload) {
      state.filter.withActual = payload
    },
    setFilterYear (state, payload) {
      state.filter.year = payload
    },
    setFilterTag (state, payload) {
      state.filter.tagList = payload
    },
    setFilterTagEng (state, payload) {
      state.filter.tagListEng = payload
    },
    setFilterId (state, payload) {
      state.filter.id = payload
    },
    setFilterSystemTagList (state, payload) {
      state.filter.systemTagList = payload
    },
    setIsSearch (state, payload) {
      state.isSearch = payload
    },
    // FILTER MUTATIONS END

    setSimilarNews (state, payload) {
      state.similarNews = payload
    },
    setLightningNews (state, payload) {
      state.lightningNews = payload
    },
    setCurrentNews (state, payload) {
      state.currentNews = payload
    },
    setPublicNews (state, payload) {
      state.publicNews = payload
    },
    setTags (state, payload) {
      state.tags = payload
    },
    setAllEntities (state, payload) {
      state.allEntities = payload
    },
    setImportantNews (state, payload) {
      state.importantNews = payload
    },
    setPaginationOffset (state, payload) {
      state.newsPagination.offset = payload
    },
    setNewsCommissions (state, payload) {
      state.newsCommissions = payload
    },
    setPaginationLimit (state, payload) {
      state.newsPagination.limit = payload
    },
    setPagination (state, payload) {
      if (payload.total !== undefined) {
        state.newsPagination.total = payload.total
      }

      if (payload.count !== undefined) {
        state.newsPagination.count = payload.count
      }

      if (payload.offset !== undefined) {
        state.newsPagination.offset = payload.offset
      };
    }
  },

  actions: {
    async getPublicNewsById (ctx, payload, settings = {}) {
      console.debug(payload)
      console.warn(settings)
      let res = await fetch(`${ctx.getters.getUrlApi}api/news/${payload}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setCurrentNews', await res.news)
    },

    async getPublicNews (ctx, settings = {}) {
      const memberTagList = settings?.personTag ? `&tagList=${settings?.personTag}` : ''
      let value
      (settings.date) ? value = `&year=${settings.date}` : value = ''
      const convocationId = settings.convocationId
      let res = await fetch(`${ctx.getters.getUrlApi}api/news${ctx.getters.getPaginationParams}${value}${ctx.getters.getFilters}${memberTagList}${convocationId ? `&convocation_id=${convocationId}` : ''}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setPublicNews', await res.news)
      this.commit('setPagination', await res)
      if (settings.onlyWithTags) {
        this.commit('setNewsOnlyWithTags', await res.news)
      }
    },

    async getLightningNews (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news?systemTagList=lightning`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setLightningNews', await res.news)
    },

    async getSimilarNews (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news/similar`, {
        method: 'POST',
        body: payload,
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setSimilarNews', await res.content)
    },

    async getAllNews (ctx, settings = {}) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news/all?offset=0&limit=9&systemTagList=all`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setAllEntities', await res.content)
      this.commit('setImportantNews', await res.important)
    },

    async getAllEntitiesPagination (ctx, settings = {}) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news/pagination?systemTagList=all`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setAllEntities', await res.pages)
      this.commit('setImportantNews', await res.important)
    },

    // Новости для члена
    async getAllChambersNews (ctx, payload) {
      // console.log(ctx.getters.getPaginationParams)
      if (payload.split(' ')[0] !== 'undefined') {
        // let res = await fetch(`${ctx.getters.getUrlApi}api/news?offset=0&limit=3&tagList=${payload || ''}&systemTagList=all`, {
        let res = await fetch(`${ctx.getters.getUrlApi}api/news${ctx.getters.getPaginationParams}&withActual=true&tagList=${payload || ''}`, {
          headers: {
            Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
          }
        })
        res = await res.json()
        // console.error(res)
        this.commit('setAllEntities', await res.news)
        this.commit('setPagination', await res)
        // this.commit('setImportantNews', await res.important)
      }
    },
    // Новости членов коммитета
    async getAllCommitteeNews (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news/members${ctx.getters.getPaginationParams}${ctx.getters.getFilters}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setAllEntities', await res.news)
      this.commit('setPagination', await res)
    },
    async getNewsCommissions (ctx, url) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news?systemTagList=${url}`)
      res = await res.json()
      this.commit('setNewsCommissions', await res.news)
    },

    getTags ({ commit }, payload) {
      const tags = ['Иванов Иван Иванович', 'Иванов Иван Петрович', 'Иванов Иван Сергеевич', 'Сергеев Роман Иванович', 'Киреева Юлия Романовна', 'Иванов Иван']
      commit('setTags', tags)
    }
  },

  getters: {
    getRouteParams: state => state.routeParams,
    getFilterTag: state => state.filter.tagList,
    getFilterRubric: state => state.filter.rubric,
    getFilterSystemTagList: state => state.filter.systemTagList,

    getFilters: state => {
      let queryString = ''

      for (const key in state.filter) {
        if (state.filter[key]) {
          if (key === 'tagList' || key === 'tagListEng') {
            queryString += `&${key}=${encodeURI(state.filter[key])}`
          } else {
            queryString += `&${key}=${state.filter[key]}`
          }
        }
      }

      return queryString
    },

    getSimilarNews: state => state.similarNews,
    getOnlyWithTags: state => state.onlyWithTags,
    getLightningNews: state => state.lightningNews,

    getPublicNews: state => state.publicNews,
    getImportantPublicNews: state => {
      return state.publicNews.filter((news) => news.important)
    },
    getCurrentNews: state => state.currentNews,
    getContent: state => state.content,
    getTags: state => state.tags,

    getAllEntities: state => state.allEntities,
    getImportantNews: state => state.importantNews,

    getPaginationParams: state => {
      let paginationParam = '?'
      if (state.newsPagination.offset !== null) {
        paginationParam += `offset=${state.newsPagination.offset}`
      }
      if (state.newsPagination.limit !== null) {
        if (state.newsPagination.offset !== null) {
          paginationParam += '&'
        }
        paginationParam += `limit=${state.newsPagination.limit}`
      }
      return paginationParam.length > 1 ? paginationParam : '?offset=0'
    },
    getPaginationLimit: state => state.newsPagination.limit,
    getPaginationCurrentPage: state => {
      return state.newsPagination.offset / state.newsPagination.limit + 1
    },
    getPaginationTotal: state => {
      return state.newsPagination.total// / state.newsPagination.limit
    },
    getNewsCommissions (state) {
      return state.newsCommissions
    },

    getLightBoxImage: state => state.lightBoxImage
  }
}
