export default {
  state: {
    personalData: {},
    status: []
  },

  mutations: {
    setPersonalData (state, payload) {
      state.personalData = payload
    },
    setMessageStatus (state, payload) {
      state.status = payload
    }
  },

  actions: {
    async setMessageStatus (ctx, status) {
      ctx.commit('setMessageStatus', status)
    },
    async getPersonalData ({ commit }) {
      await fetch(`${this.getters.getUrlApi}api/user/profile`, {
        method: 'get',
        headers: {
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(function (response) {
          if (response.status !== 200) {
            console.log('Status Code: ' + response.status)
            return
          }
          response.json().then(function (data) {
            commit('setPersonalData', data.data)
            commit('setProjectUserRole', data.data.user_role.code)
          })
        })
        .catch(function (error) {
          console.log('error', error)
        })
    },
    async postPersonalData (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/user/profile`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setMessageStatus', res.status)
            ctx.dispatch('getPersonalData')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение профиля прошло успешно'
            })
          } else {
            alert('Произошла ошибка')
            ctx.dispatch('setMessageStatus', res)
            // ctx.commit('setMessage', {
            //   type: 'error',
            //   text: 'Произошла ошибка'
            // })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },

  getters: {
    getPersonalData: state => state.personalData,
    getMessageStatus: state => state.status
  }
}
