export default {
  state: {
    status: []
  },
  mutations: {
    setStatus (state, payload) {
      state.status = payload
    }
  },
  actions: {
    async setStatus (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/directory/user-status`)
      res = await res.json()
      ctx.commit('setStatus', await res.data)
    }
  },
  getters: {
    getStatus (s) {
      return s.status
    }
  }
}
