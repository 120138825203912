export default {
  state: {
    user: null,
    token: localStorage.getItem('token') || ''
  },
  mutations: {
    SAVE_TOKEN (state, token) {
      if (token !== undefined) {
        state.token = token
        localStorage.setItem('token', token)
      }
    },
    FETCH_USER_SUCCESS (state, { user }) {
      state.user = user
    },
    FETCH_USER_FAILURE (state) {
      state.token = null
      localStorage.removeItem('token')
    },
    LOGOUT (state) {
      state.user = null
      state.token = null
      localStorage.removeItem('token')
    }
  },
  actions: {
    logOut ({ commit }) {
      commit('LOGOUT')
    },
    saveToken ({ commit, dispatch }, payload) {
      commit('SAVE_TOKEN', payload)
    },
    async fetchUser ({ commit }, data) {
      data = fetch(`${this.getters.getUrlApi}api/user/me`, {
        method: 'get',
        headers: {
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              commit('FETCH_USER_SUCCESS', { user: data.data })
            })
          })
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    }
  },
  getters: {
    user: state => state.user,
    token: state => state.token
  }
}
