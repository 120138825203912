export default {
  state: {
    member: {},
    member_units: [],
    convocation_list: [],
    unit: [],
    work_group: []
  },
  mutations: {
    SET_MEMBER (state, payload) {
      state.member = payload
    },
    SET_MEMBER_UNIT (state, payload) {
      state.member_units = payload
    },
    SET_CONVOCATION (state, payload) {
      state.convocation_list = payload
    },
    SET_UNITS (state, payload) {
      state.unit = payload
    },
    SET_WORK_GROUP (state, payload) {
      state.work_group = payload
    }
  },
  actions: {
    apiMember ({ commit }, payload) {
      fetch(`${this.getters.getUrlApi}api/member/${payload}`, {
        headers: {
          Localization: this.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              commit('SET_MEMBER', data.data)
            })
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },
    apiMemberUnits ({ commit }, [payload, ...params]) {
      // console.log(payload, params)
      fetch(`${this.getters.getUrlApi}api/member/${payload}/units${params}`, {
        headers: {
          Localization: this.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              commit('SET_MEMBER_UNIT', data.data)
            })
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },
    // Возвращает список составов ОП
    apiGetConvocationList ({ commit }, data) {
      let url
      data === undefined ? url = 'api/directory/convocation-list' : url = data
      fetch(`${this.getters.getUrlApi}${url}`, {
        headers: {
          Localization: this.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              commit('SET_CONVOCATION', data.data.convocation)
            })
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
      // const convocation = [
      //   {
      //     id: '2',
      //     name: 'Восьмой состав',
      //     startPeriod: 2024,
      //     endPeriod: 2027
      //   },
      //   {
      //     id: '1',
      //     name: 'Седьмой состав',
      //     startPeriod: 2020,
      //     endPeriod: 2023
      //   }
      // ]
      // commit('SET_CONVOCATION', convocation)
    },
    // Возвращает детакльную информацию по подразделению палаты
    apiGetUnit ({ commit }, id) {
      const unit = {
        id: '2',
        name: 'Комиссия Общественной палаты по вопросам развития гражданского общества и участия общественности' +
          ' в реализации национальных проектов',

        convocation: {
          id: '2',
          name: 'Восьмой состав',
          startPeriod: 2024,
          endPeriod: 2027
        },
        type: 'COMISSION',
        membershipList: [
          {
            member: {
              id: 1,
              firstName: 'Михаил',
              lastName: 'Бажинов',
              patronymic: 'Александрович',
              fullName: 'Бажинов Михаил Александрович',
              photo: 'image2.png'
            },
            position: 'COMMISSION_CHAIRMAN' // Председатель комиссии
          },
          {
            member: {
              id: 2,
              firstName: 'Иван',
              lastName: 'Абажер',
              patronymic: 'Иванович',
              fullName: 'Абажер Иван Иванович',
              photo: 'image2.png'
            },
            position: 'COMMISSION_FIRST_DEPUTY_CHAIRMAN' // Первый заместитель председателя комиссии
          },
          {
            member: {
              id: 3,
              firstName: 'Ксения',
              lastName: 'Алфёрова',
              patronymic: 'Александровна',
              fullName: 'Алфёрова Ксения Александровна',
              photo: 'image6.png'
            },
            position: 'COMMISSION_DEPUTY_CHAIRMAN' // Заместитель председателя комиссии
          },
          {
            member: {
              id: 1,
              firstName: 'Иван',
              lastName: 'Копытин',
              patronymic: 'Иванович',
              fullName: 'Коптытин Иван Иванович',
              photo: 'image2.png'
            },
            position: 'COMMISSION_MEMBER' // Члены комиссии
          },
          {
            member: {
              id: 1,
              firstName: 'Иван',
              lastName: 'Македонский',
              patronymic: 'Иванович',
              fullName: 'Македонский Иван Иванович',
              photo: 'image2.png'
            },
            position: 'COMMISSION_MEMBER' // Члены комиссии
          },
          {
            member: {
              id: 1,
              firstName: 'Иван',
              lastName: 'Абажер',
              patronymic: 'Иванович',
              fullName: 'Абажер Иван Иванович',
              photo: 'image2.png'
            },
            position: 'COMMISSION_MEMBER' // Члены комиссии
          }
          // {
          //   member: {
          //     id: 1,
          //     firstName: 'Иван',
          //     lastName: 'Абажер',
          //     patronymic: 'Иванович',
          //     fullName: 'Абажер Иван Иванович',
          //     photo: 'image2.png'
          //   },
          //   position: 'COMMISSION_ADVISORY_VOTE_MEMBER' // Члены комиссии с правом совещательного голоса
          // }
        ],
        recomendationTagList: {},
        newsTagList: {}
      }
      commit('SET_UNITS', unit)
    },
    /*
     Возвращает список рабочих групп определенной комиссии GET /Unit/workingGroups
     Параметры запроса parentId : строка
     */
    apiGetWorkingGroupList ({ commit }, id) {
      const subsidiariesUnit = [
        {
          id: 0,
          name: 'Рабочая группа по вопросам миграции и этнокультурной адаптации мигрантов',
          convocation: [
            {
              id: '2',
              name: 'Восьмой состав',
              startPeriod: 2024,
              endPeriod: 2027
            }
          ],
          parent: {
            name: 'Комиссия Общественной палаты по вопросам развития гражданского общества' +
              ' и участия общественности в реализации национальных проектов',
            convocation: [
              {
                id: '2',
                name: 'Восьмой состав',
                startPeriod: 2024,
                endPeriod: 2027
              }
            ],
            type: 'COMISSION'
          },
          type: 'WORKING_GROUP'
        },
        {
          id: 1,
          name: 'Рабочая группа по сохранению и поддержанию памятников советским воинам',
          convocation: [
            {
              id: '2',
              name: 'Восьмой состав',
              startPeriod: 2024,
              endPeriod: 2027
            }
          ],
          parent: {
            name: 'Комиссия Общественной палаты по вопросам развития гражданского общества' +
              ' и участия общественности в реализации национальных проектов',
            convocation: [
              {
                id: '2',
                name: 'Восьмой состав',
                startPeriod: 2024,
                endPeriod: 2027
              }
            ],
            type: 'COMISSION'
          },
          type: 'WORKING_GROUP'
        }
      ]
      commit('SET_WORK_GROUP', subsidiariesUnit)
    }
  },
  getters: {
    GET_MEMBER: state => state.member,
    GET_MEMBER_UNIT: state => state.member_units,
    GET_CONVOCATION_LIST: state => state.convocation_list,
    GET_UNIT: state => state.unit,
    GET_MEMBERSHIP: state => state.unit.membershipList,
    GET_WORK_GROUP: state => state.work_group
  }
}
