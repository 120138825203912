import router from '@/router'

export default {
  state: {
    projectAppeals: [],
    listDistricts: [],
    regions: [],
    individualProject: [],
    projectUserRole: null,
    individualProjectExpertReviews: [],
    individualProjectExpertReviewsFinal: [],
    individualProjectExperts: [],
    individualProjectExpertsFinal: [],

    isSubmitted: false,

    projectAppealData: {},
    projectAppealSearch: {
      key: null,
      nomination: null,
      status: null
    }
  },
  mutations: {
    setIsSubmitted (state, payload) {
      state.isSubmitted = payload
    },

    setProjectAppeals (state, payload) {
      state.projectAppeals = payload
    },

    setProjectUserRole (state, payload) {
      state.projectUserRole = payload
    },

    setListDistricts (state, payload) {
      state.listDistricts = payload
    },

    setListRegions (state, payload) {
      state.regions = payload
    },

    setIndividualProject (state, payload) {
      state.individualProject = payload
    },

    setProjectAppealData (state, payload) {
      state.projectAppealData = payload
    },

    setProjectAppealSearch (state, payload) {
      state.projectAppealSearch = payload
    },

    setIndividualProjectExpertReviews (state, payload) {
      state.individualProjectExpertReviews = payload
    },

    setIndividualProjectExpertReviewsFinal (state, payload) {
      state.individualProjectExpertReviewsFinal = payload
    },

    setIndividualProjectExperts (state, payload) {
      state.individualProjectExperts = payload
    },

    setIndividualProjectExpertsFinal (state, payload) {
      state.individualProjectExpertsFinal = payload
    }
  },
  actions: {
    async setListDistricts (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/directory/federal-district`)
      res = await res.json()
      this.commit('setListDistricts', await res.data)
    },

    async setListRegions (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/regions`)
      res = await res.json()
      var list = res.map((region) => {
        return {
          ...region,
          title: region.name
        }
      })
      this.commit('setListRegions', await list)
    },

    async setIndividualProject (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals/${payload}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      if (res.status === 200) {
        res = await res.json()
        this.commit('setIndividualProject', await res.data)
        this.commit('setProjectUserRole', await res.user_role)
        if (await res.reviews) {
          this.commit('setIndividualProjectExpertReviews', await res.reviews)
        }
        if (await res.reviews_final) {
          this.commit('setIndividualProjectExpertReviewsFinal', await res.reviews_final)
        }
        if (await res.experts) {
          this.commit('setIndividualProjectExperts', await res.experts)
        }
        if (await res.experts_final) {
          this.commit('setIndividualProjectExpertsFinal', await res.experts_final)
        }
      } else {
        router.push('/')
      }
    },

    async getAllProjectAppeals (ctx, payload) {
      let search = ''
      let expertUri = ''

      if (ctx.getters.getProjectUserRole === 'EXPERT') {
        expertUri = '_expert'
      }

      for (const key in ctx.getters.getProjectAppealSearch) {
        if (ctx.getters.getProjectAppealSearch[key]) {
          search += `&${key}=${ctx.getters.getProjectAppealSearch[key]}`
        }
      }

      if (search) {
        search = `?${search.substring(1)}`
      }

      let res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals${search}${expertUri}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      if (res.status === 200) {
        res = await res.json()
        this.commit('setProjectAppeals', await res.data)
      } else {
        router.push('/')
      }
    },

    async saveProjectAppeal (ctx, payload) {
      ctx.commit('setIsSubmitted', true)
      let res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
      if (res.status === 201) {
        ctx.commit('setIsSubmitted', false)
        res = await res.json()
        console.log(res)
        router.push(`/project-submit-appeal/${res.id}/edit`)
        ctx.commit('setMessage', {
          type: 'success',
          text: 'Сохранение черновика заявки прошло успешно'
        })
      } else {
        ctx.commit('setIsSubmitted', false)
        ctx.commit('setMessage', {
          type: 'error',
          text: 'Ошибка сохранения'
        })
      }
      /*
        .then(res => {
          if (res.status === 201) {
            res = res.json()
            console.log(res)
            router.push(`/project-submit-appeal/${res.id}/edit`)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Сохранение черновика заявки прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка сохранения'
            })
          }
        })
        .catch(error => console.log('error', error))
        */
    },

    async updateProjectAppeal (ctx, payload) {
      ctx.commit('setIsSubmitted', true)
      await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.formData
      })
        .then(res => {
          ctx.commit('setIsSubmitted', false)
          if (res.status === 200) {
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Сохранение черновика заявки прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка сохранения'
            })
          }
        })
        .catch(error => {
          ctx.commit('setIsSubmitted', false)
          console.log('error', error)
        })
    },

    async submitProjectAppeal (ctx, payload) {
      ctx.commit('setIsSubmitted', true)
      let res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals_submit`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
      if ([200, 201].includes(res.status)) {
        ctx.commit('setIsSubmitted', false)
        res = await res.json()
        router.push(`/project-map/${res.id}`)
        ctx.commit('setMessage', {
          type: 'success',
          text: 'Сохранение заявки прошло успешно'
        })
      } else {
        ctx.commit('setIsSubmitted', false)
        ctx.commit('setMessage', {
          type: 'error',
          text: 'Ошибка сохранения'
        })
      }
    },

    async getProjectAppealData (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals/${payload}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      if (res && res.status === 200) {
        res = await res.json()
        this.commit('setProjectAppealData', await res.data)

        ctx.commit('setMessage', {
          type: 'success',
          text: 'Черновик успешно загружен'
        })
      } else {
        ctx.commit('setMessage', {
          type: 'error',
          text: 'Ошибка загрузки черновика'
        })
      }
    },

    async addProjectAppealExpert (ctx, payload) {
      const res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals_expert`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
      if ([200, 201].includes(res.status)) {
        ctx.dispatch('setIndividualProject', payload.get('project_appeal_id'))
        ctx.commit('setMessage', {
          type: 'success',
          text: 'Добавления эксперта прошло успешно'
        })
      } else {
        ctx.commit('setMessage', {
          type: 'error',
          text: 'Ошибка добавления эксперта'
        })
      }
    },

    async deleteProjectAppeal (ctx, payload) {
      const res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
      if ([200, 201].includes(res.status)) {
        router.push('/projects-list')
        ctx.commit('setMessage', {
          type: 'success',
          text: 'Удаление заявки прошло успешно'
        })
      } else {
        ctx.commit('setMessage', {
          type: 'error',
          text: 'Ошибка удаления заявки'
        })
      }
    },

    async changeMaxExpertCount (ctx, payload) {
      const res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals/${payload.id}/expert_count`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
      if ([200, 201].includes(res.status)) {
        ctx.commit('setMessage', {
          type: 'success',
          text: 'Изменение макс. количества экспертов прошло успешно'
        })
      } else {
        ctx.commit('setMessage', {
          type: 'error',
          text: 'Ошибка изменение макс. количества экспертов'
        })
      }
    },

    async removeProjectAppealExpert (ctx, payload) {
      const res = await fetch(`${ctx.getters.getUrlApi}api/user/project_appeals_expert/${payload.expert_id}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      if ([200, 201].includes(res.status)) {
        ctx.dispatch('setIndividualProject', payload.project_appeal_id)
        ctx.commit('setMessage', {
          type: 'success',
          text: 'Удаление эксперта прошло успешно'
        })
      } else {
        ctx.commit('setMessage', {
          type: 'error',
          text: 'Ошибка удаления эксперта'
        })
      }
    }
  },

  getters: {
    getIsSubmitted: (s) => s.isSubmitted,
    getProjectAppealData: (s) => s.projectAppealData,
    getProjectUserRole: (s) => s.projectUserRole,
    getProjectAppeals: (s) => s.projectAppeals,
    getListDistricts: (s) => s.listDistricts,
    getListRegions: (s) => s.regions,
    getIndividualProject: (s) => s.individualProject,
    getProjectAppealSearch: (s) => s.projectAppealSearch,
    getIndividualProjectExpertReviews: (s) => s.individualProjectExpertReviews,
    getIndividualProjectExpertReviewsFinal: (s) => s.individualProjectExpertReviewsFinal,
    getIndividualProjectExperts: (s) => s.individualProjectExperts,
    getIndividualProjectExpertsFinal: (s) => s.individualProjectExpertsFinal
  }
}
