import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'

export default {
  state: {
    apparatusCategory: [],
    detailApparatusCategory: null
  },
  mutations: {
    setApparatusCategory (state, payload) {
      state.apparatusCategory = payload
    },
    setDetailApparatusCategory (state, payload) {
      state.detailApparatusCategory = payload
    }
  },
  actions: {
    async setApparatusCategory (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/apparat`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setApparatusCategory', await res.data)
    },
    async setDetailApparatusCategory (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/apparat/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailApparatusCategory', await res.data)
      await router.push('/admin/apparatus_category/create?type=edit')
    },
    async createApparatusCategory (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/apparat`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setApparatusCategory')
            router.push('/admin/apparatus_category')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteApparatusCategory (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/apparat/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setApparatusCategory')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Категория была удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editApparatusCategory (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/admin/apparat/${payload.id}`, payload)
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/apparatus_category')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getApparatusCategory (s) {
      return s.apparatusCategory
    },
    getDetailApparatusCategory (s) {
      return s.detailApparatusCategory
    }
  }
}
