<template>
  <div class="search-container">
    <input class="search-container__input"
           v-model="search"
           @keyup.enter="setSearch()"
           :placeholder="$store.getters.GET_LANG ? 'Введите слово для поиска' : 'Enter a search word'"
    />

    <div class="search-container__button" type="button" @click="setSearch()">
      <svg v-if="!!icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.7661 16.6116L13.0934 11.9614C14.1494 10.6635 14.7272 9.04188 14.73 7.36839C14.73 5.91106 14.2981 4.48646 13.4888 3.27474C12.6795 2.06301 11.5293 1.11858 10.1835 0.560889C8.83772 0.00319257 7.35685 -0.142726 5.92818 0.141585C4.49951 0.425896 3.18718 1.12767 2.15717 2.15815C1.12715 3.18864 0.425701 4.50157 0.14152 5.93089C-0.142661 7.36022 0.00319111 8.84176 0.560632 10.1882C1.11807 11.5346 2.06207 12.6853 3.27324 13.495C4.48441 14.3046 5.90836 14.7368 7.36503 14.7368C9.03775 14.734 10.6586 14.1559 11.9559 13.0994L16.604 17.7578C16.6801 17.8346 16.7706 17.8955 16.8703 17.937C16.9701 17.9786 17.077 18 17.1851 18C17.2931 18 17.4 17.9786 17.4998 17.937C17.5995 17.8955 17.69 17.8346 17.7661 17.7578C17.916 17.6048 18 17.399 18 17.1847C18 16.9704 17.916 16.7647 17.7661 16.6116ZM7.36503 13.0994C6.23206 13.0994 5.12455 12.7633 4.18252 12.1335C3.2405 11.5038 2.50628 10.6087 2.07272 9.56154C1.63915 8.51435 1.52571 7.36204 1.74674 6.25034C1.96777 5.13864 2.51335 4.11748 3.31447 3.31599C4.11559 2.5145 5.13629 1.96867 6.24748 1.74754C7.35867 1.52641 8.51045 1.6399 9.55717 2.07367C10.6039 2.50743 11.4985 3.24198 12.128 4.18444C12.7574 5.12689 13.0934 6.23492 13.0934 7.36839C13.0934 8.88834 12.4899 10.346 11.4156 11.4208C10.3413 12.4956 8.88428 13.0994 7.36503 13.0994Z"
          fill="white"/>
      </svg>
      <span v-else>{{ $store.getters.GET_LANG ? 'Найти' : 'To find' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VInputSearch',
  props: {
    tags: {
      type: Array
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: ''
    }
  },
  mounted () {
    if (this.$route.path === '/search' && this.$route.query.search) {
      this.search = this.$route.query.search
      this.setSearch()
    }
  },
  methods: {
    setSearch () {
      if (this.$route.path === '/search') {
        if (this.search) {
          this.$store.dispatch('setSearch', { search: encodeURI(this.search), tag: this.tags })
          this.$emit('click')
        } else {
          this.$noty.error(this.$store.getters.GET_LANG ? 'Заполните поле поиска' : 'Fill in the search box')
        }
      } else {
        if (this.search.length !== 0) {
          this.$router.push(`/search?search=${this.search}`)
          this.search = ''
          this.$emit('closeHeaderSearch')
        } else {
          this.$noty.error(this.$store.getters.GET_LANG ? 'Заполните поле поиска' : 'Fill in the search box')
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search-container {
  display: flex;
  justify-content: space-between;

  &__input {
    flex: 1;
    border: 1px solid #D5D6D9;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0.625rem 1rem;
    font-size: 0.875rem;
  }

  &__button {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 143%;
    padding: 0.625rem 2rem;
    background: #246CB7;
    border-radius: 0px 3px 3px 0px;
    color: #FFFFFF;
    border: 1px solid #246CB7;
    margin-left: -1px;
    cursor: pointer;
  }

  &__input::placeholder {
    color: rgb(159, 164, 167);
  }
}

@media screen and (max-width: 768px) {

}

@media screen and (max-width: 420px) {
  .search-container {
    &__button {
      width: 40%;
      padding: 0.625rem 0;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__input{
      width: 60%;
    }
  }
}
</style>
