export default {
  state: {
    publicMedias: [],
    currentMedia: null
  },

  mutations: {
    setPublicMedias (state, payload) {
      state.publicMedias = payload
    },

    setCurrentMedia (state, payload) {
      state.currentMedia = payload
    }
  },

  actions: {
    async getPublicMedias (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/media${ctx.getters.getPaginationParams}${ctx.getters.getFilters}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setPublicMedias', await res.media)
      this.commit('setPagination', await res)
    },

    async getCurrentMedia (ctx, id) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/media/${id}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setCurrentMedia', await res.data)
    }
  },

  getters: {
    getPublicMedias: (s) => s.publicMedias,
    getCurrentMedia: (s) => s.currentMedia
  }
}
