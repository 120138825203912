<template>
    <section>
        <div class="loader" id="loader"> </div>
    </section>
</template>
<script>
export default {
}
</script>
<style scoped lang="scss">
section{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 100;
    padding: 3rem 0;
    background: #fffbfb82;

}
.loader {
    border: 1rem solid #EEF4FA;
    border-top: 1rem solid #246CB7;;
    border-radius: 50%;
    width: 7.5rem;
    height: 7.5rem;
    animation: spin 2s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.hide-loader{
    display:none;
}
</style>
