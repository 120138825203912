import router from '@/router'

export default {
  state: {
    corruptionSection: [],
    detailCorruptionSection: null
  },
  mutations: {
    setCorruptionSection (state, payload) {
      state.corruptionSection = payload
    },
    setDetailCorruptionSection (state, payload) {
      state.detailCorruptionSection = payload
    }
  },
  actions: {
    async setCorruptionSection (ctx, settings = {}) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/corruption/section`, settings)
      res = await res.json()
      ctx.commit('setCorruptionSection', await res.data)
    },
    async AddCorruptionSection (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/corruption/section`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/corruption_section')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание секции прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteCorruptionSection (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/corruption/section/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCorruptionSection')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Структура удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailCorruptionSection (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/corruption/section/${payload}`, {
        headers: {
          admin: true
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailCorruptionSection', await res.data)
      await router.push('/admin/corruption_section/create?type=edit')
    },
    async editCorruptionSection (ctx, payload) {
      const url = `${ctx.getters.getUrlApi}api/admin/corruption/section/${payload.id}`
      await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/corruption_section')
            ctx.dispatch('setCorruptionSection')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация о cnherneht успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getCorruptionSection (s) {
      return s.corruptionSection
    },
    getDetailCorruptionSection (s) {
      return s.detailCorruptionSection
    }
  }
}
