import router from '@/router'

export default {
  state: {
    inclusionGround: [],
    detailInclusionGround: null
  },
  mutations: {
    setAllInclusionGroundAdmin (state, payload) {
      state.inclusionGround = payload
    },
    setDetailPositionAdmin (state, payload) {
      state.detailInclusionGround = payload
    }
  },
  actions: {
    async setAllInclusionGroundAdmin (ctx) {
      // список всех долностей
      let position = await fetch(`${ctx.getters.getUrlApi}api/admin/directory/inclusion-ground`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      position = await position.json()
      ctx.commit('setAllInclusionGroundAdmin', await position.data)
    },
    async AddInclusionGroundAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/inclusion-ground`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllInclusionGroundAdmin')
            router.push('/admin/inclusion_ground')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deletInclusionGroundAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/country/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCountry')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Страна удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailInclusionGroundAdmin (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/inclusion-ground/${payload}`)
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailPositionAdmin', await res.data)
      await router.push('/admin/inclusion_ground/create?type=edit')
    },
    async editInclusionGroundAdmin (ctx, payload) {
      const url = `${ctx.getters.getUrlApi}api/admin/inclusion-ground/${payload.id}?title=${payload.title}&code=${payload.code}&titleEng=${payload.titleEng}`
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/inclusion_ground')
            ctx.dispatch('setAllInclusionGroundAdmin')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getInclusionGroundAdmin (s) {
      return s.inclusionGround
    },
    getDetailInclusionGroundAdmin (s) {
      return s.detailInclusionGround
    }
  }
}
