export default {
  state: {
    publicAnnouncements: [],
    currentAnnouncement: null
  },

  mutations: {
    setPublicAnnouncements (state, payload) {
      state.publicAnnouncements = payload
    },

    setCurrentAnnouncement (state, payload) {
      state.currentAnnouncement = payload
    }
  },

  actions: {
    async getPublicAnnouncements (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/announcement${ctx.getters.getPaginationParams}${ctx.getters.getFilters}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setPublicAnnouncements', await res.announcement)
      this.commit('setPagination', await res)
    },

    async getPublicAnnouncementById (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/announcement/${payload}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setCurrentAnnouncement', await res.announcement)
    }
  },

  getters: {
    getPublicAnnouncements: state => state.publicAnnouncements,
    getCurrentAnnouncement: state => state.currentAnnouncement
  }
}
