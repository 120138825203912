import router from '@/router'

export default {
  state: {
    news: [],
    detailNews: {},
    rubrics: [],
    contentBlockTypes: [],

    adminNewsSearch: {
      key: null,
      date_start: null,
      date_end: null,
      rubric: null,
      system_tags_list: null
    },
    adminNewsPagination: {
      offset: 0,
      limit: 9,
      count: 0,
      total: 0
    }
  },
  mutations: {
    setNews (state, payload) {
      state.news = payload
    },

    setDetailNews (state, payload) {
      state.detailNews = payload
    },

    setRubrics (state, payload) {
      state.rubrics = payload
    },

    setContentBlockTypes (state, payload) {
      state.contentBlockTypes = payload
    },

    setAdminNewsSearch (state, payload) {
      state.adminNewsSearch = payload
    },

    setAdminNewsPaginationOffset (state, payload) {
      state.adminNewsPagination.offset = payload
    },

    setAdminNewsPaginationLimit (state, payload) {
      state.adminNewsPagination.limit = payload
    },

    setAdminNewsPagination (state, payload) {
      state.adminNewsPagination = {
        offset: payload.offset,
        limit: payload.limit,
        count: payload.count,
        total: payload.total
      }
    }
  },
  actions: {
    async getNews (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/news${ctx.getters.getAdminNewsPaginationParams}${ctx.getters.getAdminNewsSearch}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setAdminNewsPagination', await res)
      this.commit('setNews', await res.news)
    },

    async getDetailNews (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news/${payload}`, {
        method: 'GET',
        headers: {
          Localization: 'admin'
        }
      })
      res = await res.json()
      this.commit('setDetailNews', await res.news)
    },

    async deleteNews (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/news/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Новость №${payload} успешно удалена`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('getNews')
      })
        .catch(error => console.log('error', error))
    },

    async getRubrics (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/rubric`)
      res = await res.json()
      this.commit('setRubrics', await res.rubric)
    },

    async getContentBlockTypes (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/content-block-types`)
      res = await res.json()
      this.commit('setContentBlockTypes', await res)
    },

    async createNews (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/news`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/news')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание новости прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async updateNews (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/news/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/news')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение новости прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getNews: (s) => s.news,
    getAdminNewsSearch: (s) => {
      let queryString = ''

      for (const key in s.adminNewsSearch) {
        if (s.adminNewsSearch[key]) {
          queryString += `&${key}=${s.adminNewsSearch[key]}`
        }
      }

      return queryString
    },
    getAdminNewsPaginationParams: s => {
      let pagination = `?offset=${s.adminNewsPagination.offset}`

      if (s.adminNewsPagination.limit) {
        pagination += `&limit=${s.adminNewsPagination.limit}`
      }

      return pagination
    },
    getAdminNewsPagination: (s) => s.adminNewsPagination,
    getDetailNews: (s) => s.detailNews,
    getRubrics: (s) => s.rubrics,
    getContentBlockTypes: (s) => s.contentBlockTypes
  }
}
