import router from '@/router'

export default {
  state: {
    subjects: [],
    detailedSubject: []
  },

  mutations: {
    setSubjects (state, payload) {
      state.subjects = payload
    },

    setDetailedSubject (state, payload) {
      state.detailedSubject = payload
    }
  },
  actions: {
    async setSubjects (ctx, payload) {
      let url = `${ctx.getters.getUrlApi}api/subject`

      if (payload) {
        url = `${ctx.getters.getUrlApi}api/subject?federal_district=${payload}`
      }

      let res = await fetch(url)
      res = await res.json()
      this.commit('setSubjects', await res.data)
    },

    async setDetailedSubject (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/subject/${payload}`)
      res = await res.json()
      this.commit('setDetailedSubject', await res.data)
    },

    async deleteSubject (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/subject/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Субъект успешно удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setSubjects')
      })
        .catch(error => console.log('error', error))
    },

    async createSubject (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/subject`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/subject')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание субъекта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editSubject (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/subject/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/subject')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение субъекта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getSubjects: (s) => s.subjects,
    getDetailedSubject: (s) => s.detailedSubject
  }
}
