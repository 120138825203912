import router from '@/router'

export default {

  state: {
    allCommentsNews: [],
    publicNewsComments: [],
    newsComments: [],
    adminNewsCommentsPagination: {
      offset: 0,
      limit: 9,
      count: 0,
      total: 0
    },
    commentStatuses: [],
    currentComment: null
  },

  mutations: {
    setNewsComments (state, payload) {
      state.newsComments = payload
    },

    setPublicNewsComments (state, payload) {
      state.publicNewsComments = payload
    },

    setCommentStatuses (state, payload) {
      state.commentStatuses = payload
    },

    setCurrentComment (state, payload) {
      state.currentComment = payload
    },

    setAllCommentsNews (state, payload) {
      state.allCommentsNews = payload
    },

    setAdminNewsCommentsPaginationOffset (state, payload) {
      state.adminNewsCommentsPagination.offset = payload
    },

    setAdminNewsCommentsPaginationLimit (state, payload) {
      state.adminNewsCommentsPagination.limit = payload
    },

    setAdminNewsCommentsPagination (state, payload) {
      state.adminNewsCommentsPagination = {
        offset: payload.offset,
        limit: payload.limit,
        count: payload.count,
        total: payload.total
      }
    }
  },

  actions: {

    async getAllNewsComments (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news_comments${ctx.getters.getPaginationParams}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setAllCommentsNews', await res.comment)
      this.commit('setPagination', await res)
    },

    async getCommentStatuses (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/news_comments/status`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setCommentStatuses', await res)
    },

    async getPublicNewsComments (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/news/${payload}/comments`)
      res = await res.json()
      this.commit('setPublicNewsComments', await res.comment)
    },

    async getNewsComments (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/news/${payload}/comments`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setNewsComments', await res.comment)
      this.commit('setAdminNewsCommentsPagination', {
        offset: 0,
        limit: 9,
        count: 0,
        total: 0
      })
    },

    async getAllAdminNewsComments (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/news_comments${ctx.getters.getAdminNewsCommentPaginationParams}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setNewsComments', await res.comment)
      this.commit('setAdminNewsCommentsPagination', await res)
    },

    async getDetailNewsComment (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/news_comments/${payload}`)
      res = await res.json()
      this.commit('setCurrentComment', await res.data)
    },

    async deleteNewsComment (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/news_comments/${payload.comment}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Комментарий №${payload.comment} успешно удален`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('getNewsComments', payload.news)
      })
        .catch(error => console.log('error', error))
    },

    async createNewsComment (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/news/${payload.id}/comment`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/news_comments')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание комментария новости прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async updateNewsComment (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/news_comments/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/news_comments')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение комментария новости прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },

  getters: {
    getAdminNewsCommentPaginationParams: s => {
      let pagination = `?offset=${s.adminNewsCommentsPagination.offset}`

      if (s.adminNewsCommentsPagination.limit) {
        pagination += `&limit=${s.adminNewsCommentsPagination.limit}`
      }

      return pagination
    },
    getAdminNewsCommentPagination: (s) => s.adminNewsCommentsPagination,
    getNewsComments: (s) => s.newsComments,
    getPublicNewsComments: (s) => s.publicNewsComments,
    getCommentStatuses: (s) => s.commentStatuses,
    getDetailNewsComment: (s) => s.currentComment,
    getAllNewsComments: (s) => s.allCommentsNews
  }

}
