export default {
  state: {
    publicLiveStreams: [],
    currentLiveStream: null
  },

  mutations: {
    setPublicLiveStreams (state, payload) {
      state.publicLiveStreams = payload
    },

    setCurrentLiveStream (state, payload) {
      state.currentLiveStream = payload
    }
  },

  actions: {
    async getPublicLiveStreams (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/live_stream${ctx.getters.getPaginationParams}${ctx.getters.getFilters}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setPublicLiveStreams', await res.live_stream)
      this.commit('setPagination', await res)
    },

    async getPublicLiveStreamById (ctx, id) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/live_stream/${id}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setCurrentLiveStream', await res)
    }
  },

  getters: {
    getPublicLiveStreams: (s) => s.publicLiveStreams,
    getCurrentLiveStream: (s) => s.currentLiveStream
  }
}
