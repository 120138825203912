import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'

export default {
  state: {
    commissionUpdateTypes: [],
    detailCommissionUpdateTypes: []
  },
  mutations: {
    setCommissionUpdateTypes (state, payload) {
      state.commissionUpdateTypes = payload
    },
    setDetailTypes (state, payload) {
      state.detailCommissionUpdateTypes = payload
    }
  },
  actions: {
    async setCommissionUpdateTypes (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdateTypes`)
      res = await res.json()
      ctx.commit('setCommissionUpdateTypes', await res.data)
    },
    async setDetailTypes (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdateTypes/${payload}`)
      res = await res.json()
      ctx.commit('setDetailTypes', await res.data)
      //   await router.push('/admin/apparatus_category/create?type=edit')
    },
    async createCommissionUpdateTypes (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdateTypes`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCommissionUpdateTypes')
            router.push('/admin/status_bill')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteCommissionUpdateTypes (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdateTypes/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCommissionUpdateTypes')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Категория была удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editCommissionUpdateTypes (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/admin/commissionUpdateTypes/${payload.id}`, payload)
      // ?title=
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/status_bill')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getCommissionUpdateTypes (s) {
      return s.commissionUpdateTypes
    },
    getDetailCommissionUpdateTypes (s) {
      return s.detailCommissionUpdateTypes
    }
  }
}
