import router from '@/router'

export default {
  state: {
    project: [],
    detailProject: null
  },
  mutations: {
    setProject (state, payload) {
      state.project = payload
    },
    setDetailProject (state, payload) {
      state.detailProject = payload
    }
  },
  actions: {
    async setProject (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/projects/list`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setProject', await res.data.projects)
    },
    async AddProject (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setProject')
            router.push('/admin/project')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание проекта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteProject (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setProject')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Проект удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailProject (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/projects/${payload}`)
      res = await res.json()
      ctx.commit('setDetailProject', await res.data)
      await router.push('/admin/project/create?type=edit')
    },
    async editProject (ctx, payload) {
      const url = `${ctx.getters.getUrlApi}api/admin/project/${payload.id}`
      await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/project')
            ctx.dispatch('setProject', ctx.getters.getUrlApi)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация о проекте успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getProject (s) {
      return s.project
    },
    getDetailProject (s) {
      return s.detailProject
    }
  }
}
