import router from '@/router'

export default {
  state: {
    membersBlogs: [],
    allMembers: [],
    statusBlogs: [],
    specificMembers: [],
    detailBlogAdmin: [],
    tagsBlogs: []
  },
  mutations: {
    setAllMembersBlog (state, payload) {
      state.membersBlogs = payload
    },
    setAllMembers (state, payload) {
      state.allMembers = payload
    },
    setStatusBlogs (state, payload) {
      state.statusBlogs = payload
    },
    setSpecificMembersBlogs (state, payload) {
      state.specificMembers = payload
    },
    setDetailBlogAdmin (state, payload) {
      state.detailBlogAdmin = payload
    },
    setTagsBlogs (state, payload) {
      state.tagsBlogs = payload
    }
  },
  actions: {
    async setAllMembers (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/member/name`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setAllMembers', await res.data)
    },
    async setAllMembersBlog (ctx, param) {
      let par
      param ? par = param : par = ''
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/member/bloggers${par}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setAllMembersBlog', await res.data)
    },
    async setStatusBlogs (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/directory/post-status`)
      res = await res.json()
      ctx.commit('setStatusBlogs', await res.data)
    },
    async setSpecificMembersBlogs (ctx, payload) {
      let par
      payload.param ? par = payload.param : par = ''
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/member/${payload.id}/posts${par}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setSpecificMembersBlogs', await res.data.posts)
    },
    async deleteBlogAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/posts/${payload.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllMembersBlog', ctx.getters.getUrlApi)
            ctx.dispatch('setSpecificMembersBlogs', payload.allBlog)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Блог удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailBlogAdmin (ctx, payload) {
      // детальная информация о блоге
      let res = await fetch(`${ctx.getters.getUrlApi}api/member/${payload.member}/posts/${payload.post}`)
      res = await res.json()
      ctx.commit('setDetailBlogAdmin', await res.data)
    //   console.log(await res.data)
    //   await router.push('/admin/members/create_member?type=edit')
    },
    async updateBlogAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/posts/${payload[1]}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllMembersBlog', ctx.getters.getUrlApi)
            router.push('/admin/blogs')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание блога прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async createBlogAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/member/${payload[1]}/blog`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllMembersBlog', ctx.getters.getUrlApi)
            router.push('/admin/blogs')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание блога прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async setTagsBlogs (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/blogs/tags`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setTagsBlogs', await res.data)
    }
  },
  getters: {
    getAllMembersBlogs (s) {
      return s.membersBlogs
    },
    getAllMembers (s) {
      return s.allMembers
    },
    getStatusBlogs (s) {
      return s.statusBlogs
    },
    getSpecificMembersBlogs (s) {
      return s.specificMembers
    },
    getDetailBlogAdmin (s) {
      return s.detailBlogAdmin
    },
    getTagsBlogs (s) {
      return s.tagsBlogs
    }
  }
}
