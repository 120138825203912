import router from '@/router'

export default {
  state: {
    detailСorruption: null
  },
  mutations: {
    setDetailСorruption (state, payload) {
      state.detailСorruption = payload
    }
  },
  actions: {
    async setDetailСorruption (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/corruption/section/group/item/${payload}`, {
        headers: {
          admin: true,
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailСorruption', await res.data)
      await router.push('/admin/corruption/create?type=edit')
    },
    async createСorruption (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/corruption/section/group/${payload.id}/item`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setСorruption')
            router.push('/admin/corruption')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание задела коррупции прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteСorruption (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/corruption/section/group/item/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Раздел был удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editСorruption (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/corruption/section/group/item/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/corruption')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение пункта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getDetailСorruption (s) {
      return s.detailСorruption
    }
  }
}
