import router from '@/router'

export default {
  state: {
    projectQestion: [],
    detailedProjectQestion: [],
    experts: [],

    projectReview: null,
    projectReviewFinal: null
  },
  mutations: {
    setProjectQestion (state, payload) {
      state.projectQestion = payload
    },
    setDetailedProjectQestion (state, payload) {
      state.detailedProjectQestion = payload
    },
    setProjectReview (state, payload) {
      state.projectReview = payload
    },
    setProjectReviewFinal (state, payload) {
      state.projectReviewFinal = payload
    },
    setExperts (state, payload) {
      state.experts = payload
    }
  },
  actions: {
    async setProjectQestion (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/project_review_question`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setProjectQestion', await res)
    },

    async setDetailedProjectQestion (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/project_review_question/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setDetailedProjectQestion', await res)
    },

    async deleteProjectQestion (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_review_question/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Вопрос удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setProjectQestion')
      })
        .catch(error => console.log('error', error))
    },

    async createProjectQestion (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_review_question`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/project_evaluation')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание вопроса прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editProjectQestion (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_review_question/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/project_evaluation')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение вопроса прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async getProjectReview (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/project_review/${payload}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      if (res.status === 200) {
        ctx.commit('setMessage', {
          type: 'success',
          text: 'Загрузка оценки прошло успешно'
        })
        res = await res.json()
        this.commit('setProjectReview', res)
      } else {
        this.commit('setProjectReview', null)
      }
      console.log(res)
    },

    async getProjectReviewFinal (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/project_review_final/${payload}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      if (res.status === 200) {
        ctx.commit('setMessage', {
          type: 'success',
          text: 'Загрузка оценки финала прошло успешно'
        })
        res = await res.json()
        this.commit('setProjectReviewFinal', res)
      } else {
        this.commit('setProjectReviewFinal', null)
      }
      console.log(res)
    },

    async storeProjectReview (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_review`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Сохранение оценки прошло успешно'
            })
            // location.reload()
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка сохранения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async updateProjectReview (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_review/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Сохранение оценки прошло успешно'
            })
            // location.reload()
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка сохранения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async getExperts (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/user/experts`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      if (res.status === 200) {
        res = await res.json()
        this.commit('setExperts', res)
      }
      console.log(res)
    }
  },
  getters: {
    getProjectQestion: (s) => s.projectQestion,
    getDetailedProjectQestion: (s) => s.detailedProjectQestion,
    getProjectReview: s => s.projectReview,
    getProjectReviewFinal: s => s.projectReviewFinal,
    getExperts: s => s.experts
  }
}
