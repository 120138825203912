import router from '@/router'
export default {
  state: {
    unit: [],
    detailUnit: null,
    parents: []
  },
  mutations: {
    setUnit (state, payload) {
      state.unit = payload
    },
    setParents (state, payload) {
      console.log(payload)
      state.parents = payload
    },
    setDetailUnit (state, payload) {
      console.log(payload)
      state.detailUnit = {
        id: payload.id,
        parent: payload.parent,
        name: payload.name,
        files: payload.files,
        nameEng: payload.nameEng,
        convocation: payload.convocation.id,
        type: payload.type,
        order: payload.order
      }
    }
  },
  actions: {
    async setUnit (ctx, payload) {
      // год созыва
      let unit = await fetch(`${ctx.getters.getUrlApi}api/unit/convocation/${payload}`)
      unit = await unit.json()
      ctx.commit('setUnit', await unit)
    },
    async setParents (ctx, payload) {
      let parents = await fetch(`${ctx.getters.getUrlApi}api/admin/unit/convocation/${payload}/parents`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      parents = await parents.json()
      ctx.commit('setParents', await parents.data)
    },
    async createUnit (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/unit`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setConvocation')
            router.push('/admin/unit')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание подраздела прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка, проверьте год созыва'
            })
          }
        })
    },
    async deleteUnit (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/unit/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setUnit')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Подразделение было удалено'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async setDetailUnit (ctx, payload) {
      // детальная информация члена ОПРФ для редактирования
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/unit/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setDetailUnit', await res.data)
      console.log(await res.data)
      await router.push('/admin/unit/create?type=edit')
    },
    async editUnit (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/unit/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setConvocation')
            router.push('/admin/unit')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание подраздела прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка, проверьте год созыва'
            })
          }
        })
    }
  },
  getters: {
    getUnitData (s) {
      return s.unit
    },
    getDetailUnit (s) {
      return s.detailUnit
    },
    getParents (s) {
      return s.parents
    }
  }
}
