export default {
  computed: {
    getLang () {
      return this.$store.getters.GET_LANG
    }
  },
  methods: {
    changeLang (bool) {
      this.$store.dispatch('SET_LANG', bool)
    }
  }
}
