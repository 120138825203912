import router from '@/router'

export default {
  state: {
    positions: [],
    detailPosition: null
  },
  mutations: {
    setAllPosition (state, payload) {
      state.positions = payload
    },
    setDetailPosition (state, payload) {
      state.detailPosition = payload
    }
  },
  actions: {
    async setAllPosition (ctx) {
      // список всех долностей
      let position = await fetch(`${ctx.getters.getUrlApi}api/admin/directory/positions`)
      position = await position.json()
      ctx.commit('setAllPosition', await position.data)
    },
    async AddPosition (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/position`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllPosition')
            router.push('/admin/positions')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание должности прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deletePosition (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/position/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllPosition')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Должность удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailPosition (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/position/${payload}`)
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailPosition', await res.data)
      await router.push('/admin/positions/create?type=edit')
    },
    async editPosition (ctx, payload) {
      const url = `${ctx.getters.getUrlApi}api/admin/position/${payload.id}?title=${payload.title}&code=${payload.code}&titleEng=${payload.titleEng}&order=${payload.order}`
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/positions')
            ctx.dispatch('setAllPosition')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация о должности успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getPositions (s) {
      return s.positions
    },
    getDetailPosition (s) {
      return s.detailPosition
    }
  }
}
