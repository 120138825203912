import router from '@/router'

export default {
  state: {
    keyDates: [],
    detailedKeyDates: []
  },
  mutations: {
    setKeyDates (state, payload) {
      state.keyDates = payload
    },
    setDetailedKeyDates (state, payload) {
      state.detailedKeyDates = payload
    }
  },
  actions: {
    async setKeyDates (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/key_dates`)
      res = await res.json()
      this.commit('setKeyDates', await res)
    },
    async setDetailedKeyDates (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/key_dates/${payload}`)
      res = await res.json()
      this.commit('setDetailedKeyDates', await res)
    },
    async deleteKeyDates (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/key_dates/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Ключевая дата удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setKeyDates')
      })
        .catch(error => console.log('error', error))
    },

    async createKeyDates (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/key_dates`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/key_dates')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание ключевой даты прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editKeyDates (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/key_dates/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/key_dates')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение ключевой даты прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getKeyDates: (s) => s.keyDates,
    getDetailedKeyDates: (s) => s.detailedKeyDates
  }
}
