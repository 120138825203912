import router from '@/router'

export default {
  state: {
    allComments: [],
    detailCommentAdmin: []
  },
  mutations: {
    setAllComments (state, payload) {
      state.allComments = payload
    },
    setDetailCommentAdmin (state, payload) {
      state.detailCommentAdmin = payload
    }
  },
  actions: {
    /* TODO проверить, кому нужны все статусы? */
    async setAllComments (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/member/${payload.idMember}/posts/${payload.idPost}?status=ALL`)
      res = await res.json()
      ctx.commit('setAllComments', await res.data)
    },
    async deleteCommentAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/comment/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            // ctx.dispatch('setAllMembersBlog', ctx.getters.getUrlApi)
            // ctx.dispatch('setSpecificMembersBlogs', payload.allBlog)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Блог удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailCommentAdmin (ctx, payload) {
      // детальная информация комментарий
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/comment/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setDetailCommentAdmin', await res.data)
    },
    async updateCommemtAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/comment/${payload[1]}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/comments_blogs')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Редактирование комментария прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async createCommemtAdmin (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/post/${payload[1]}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/comments_blogs')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание комментария прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getAllComments (s) {
      return s.allComments
    },
    getDetailCommentAdmin (s) {
      return s.detailCommentAdmin
    }
  }
}
