import router from '@/router'

export default {
  state: {
    period: [],
    detailedPeriod: []
  },
  mutations: {
    setPeriod (state, payload) {
      state.period = payload
    },
    setDetailedPeriod (state, payload) {
      state.detailedPeriod = payload
    }
  },
  actions: {
    async setPeriod (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/project_appeal_periods`)
      res = await res.json()
      this.commit('setPeriod', await res)
    },

    async setDetailedPeriod (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/project_appeal_periods/${payload}`)
      res = await res.json()
      this.commit('setDetailedPeriod', await res)
    },

    async deletePeriod (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_appeal_periods/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Период успешно удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setPeriod')
      })
        .catch(error => console.log('error', error))
    },

    async createPeriod (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_appeal_periods`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/project_appeal_periods')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание периода прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editPeriod (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_appeal_periods/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/project_appeal_periods')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение периода прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getPeriod: (s) => s.period,
    getDetailedPeriod: (s) => s.detailedPeriod
  }
}
