import router from '@/router'

function checkPage () {
  const formData = new FormData()
  let redirectUrl = null
  if (window.location.href.indexOf('documents') !== -1) {
    formData.append('systemTagList', JSON.stringify(['/activity_documents']))
    redirectUrl = 'documents'
  } else if (window.location.href.indexOf('apparatus') !== -1) {
    formData.append('systemTagList', JSON.stringify(['/device_chamber']))
    redirectUrl = 'apparatus'
  } else if (window.location.href.indexOf('expert_opinions') !== -1) {
    formData.append('systemTagList', JSON.stringify(['/expert_opinions']))
    redirectUrl = 'expert_opinions'
  } else if (window.location.href.indexOf('activity_plan') !== -1) {
    formData.append('systemTagList', JSON.stringify(['/activity_plan']))
    redirectUrl = 'activity_plan'
  } else if (window.location.href.indexOf('onk_document') !== -1) {
    formData.append('systemTagList', JSON.stringify(['/onk_document']))
    redirectUrl = 'onk_document'
  } else if (window.location.href.indexOf('public_doc') !== -1) {
    formData.append('systemTagList', JSON.stringify(['/public_doc']))
    redirectUrl = 'public_doc'
  } else if (window.location.href.indexOf('community_councils_file') !== -1) {
    formData.append('systemTagList', JSON.stringify(['/community_councils_file']))
    redirectUrl = 'community_councils_file'
  }
  return {
    redirectUrl,
    formData
  }
}

function checkEdit (item) {
  const formData = new FormData()
  let redirectUrl = null
  if (item[0] === '/activity_documents') {
    redirectUrl = 'documents'
  } else if (item[0] === '/device_chamber') {
    redirectUrl = 'apparatus'
  } else {
    redirectUrl = item[0].slice(1)
  }
  formData.append('systemTagList', JSON.stringify(item))
  return {
    redirectUrl,
    formData
  }
}

export default {
  state: {
    document: [],
    detailedDocument: []
  },
  mutations: {
    setDocuments (state, payload) {
      state.document = payload
    },
    setDetailedDocument (state, payload) {
      state.detailedDocument = payload
    }
  },
  actions: {
    async setDocuments (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/document/list`, {
        ...payload.settings,
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.formData
      })
      res = await res.json()
      ctx.commit('setDocuments', await res.data.documents)
    },
    async setDetailedDocument (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/document/${payload}`, {
        headers: {
          admin: true
        }
      })
      res = await res.json()
      ctx.commit('setDetailedDocument', await res.data)
    },
    async EditDocuments (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/document/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.formData
      })
        .then(res => {
          if (res.ok) {
            ctx.dispatch('setDocuments', checkEdit(payload.systemTagList).formData)
            // const formData = new FormData()
            // console.log(payload.systemTagList)
            // formData.append('systemTagList', JSON.stringify(payload.systemTagList))
            // this.$store.dispatch('setDocuments', formData)
            router.push(`/admin/${checkEdit(payload.systemTagList).redirectUrl}`)

            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание документа прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async AddDocuments (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/document`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.ok) {
            ctx.dispatch('setDocuments', checkPage().formData)
            router.push(`/admin/${checkPage().redirectUrl}`)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание документа прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteDocuments (ctx, payload) {
      let data = checkPage()
      data = data.formData
      await fetch(`${ctx.getters.getUrlApi}api/admin/document/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setDocuments', data)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Документ удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    }
  },
  getters: {
    getDocuments (s) {
      return s.document
    },
    getDetailedDocument (s) {
      return s.detailedDocument
    }
  }
}
