import router from '@/router'

export default {
  state: {
    contacts: [],
    currentContact: null,
    hotLine: [],
    phoneInfo: [],
    informationService: [],
    internetReception: [],
    socialNetwork: [],
    socialNetworkDetails: [],
    contactInformationOnk: [],
    feedbackHotline: [],
    forMedia: false
  },
  mutations: {
    setContacts (state, payload) {
      state.contacts = payload
    },

    setForMedia (state, payload) {
      state.forMedia = payload
    },

    setCurrentContact (state, payload) {
      state.currentContact = payload
    },
    setHotLine (state, payload) {
      state.hotLine = payload
    },
    setPhoneCertificateAdmission (state, payload) {
      state.phoneInfo = payload
    },
    setInformationService (state, payload) {
      state.informationService = payload
    },
    setInternetReception (state, payload) {
      state.internetReception = payload
    },
    setSocialNetwork (state, payload) {
      state.socialNetwork = payload
    },
    setSocialNetworkDetails (state, payload) {
      state.socialNetworkDetails = payload
    },
    setContactInformationOnk (state, payload) {
      state.contactInformationOnk = payload
    },
    setFeedbackHotline (state, payload) {
      state.feedbackHotline = payload
    }
  },
  actions: {
    async getContacts (ctx, settings = {}) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/contacts`, settings)
      res = await res.json()
      this.commit('setContacts', await res.data)
    },

    async getCurrentContact (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/contacts/${payload}`, {
        headers: {
          Localization: 'admin'
        }
      })
      res = await res.json()
      this.commit('setCurrentContact', await res.contacts)
    },

    async deleteContact (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/contacts/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Контакт №${payload} успешно удален`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('getContacts')
      })
        .catch(error => console.log('error', error))
    },

    async createContacts (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/contacts`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            const path = ctx.getters.getForMediaKey ? '/admin/for_media_contacts' : '/admin/contacts'
            router.push(path)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание контакта прошло успешно'
            })
            ctx.commit('setForMedia', false)
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editContacts (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/contacts/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            const path = ctx.getters.getForMediaKey
              ? '/admin/for_media_contacts'
              : '/admin/contacts'
            router.push(path)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение контакта прошло успешно'
            })
            ctx.commit('setForMedia', false)
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // раздел контакты
    async setHotLine (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/back-coupling/hot-line`, {
        headers: {
          admin: true,
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setHotLine', await res.data)
    },
    async editHotLine (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/back-coupling/hot-line`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/connect_contacts')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение контакта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    //
    async setPhoneCertificateAdmission (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/back-coupling/citizens-appeals`, {
        headers: {
          admin: true,
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setPhoneCertificateAdmission', await res.data)
    },
    async editCertificateAdmission  (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/back-coupling/citizens-appeals`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/connect_contacts')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение контакта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    //
    async setInformationService (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/back-coupling/information-service`, {
        headers: {
          admin: true,
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setInformationService', await res.data)
    },
    async editInformationService  (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/back-coupling/information-service`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/connect_contacts')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение контакта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    //
    async setInternetReception (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/back-coupling/internet-reception`, {
        headers: {
          admin: true,
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setInternetReception', await res.data)
    },
    async editInternetReception (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/back-coupling/internet-reception`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/connect_contacts')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение контакта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    async setSocialNetwork (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/social-network`)
      res = await res.json()
      this.commit('setSocialNetwork', await res.data)
    },

    async setSocialNetworkDetails (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/social-network/${payload}`)
      res = await res.json()
      this.commit('setSocialNetworkDetails', await res.data)
    },

    async deleteSocialNetwork (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/social-network/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Социальная сеть №${payload} успешно удален`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('setSocialNetwork')
      })
        .catch(error => console.log('error', error))
    },

    async createSocialNetwork (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/social-network`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/social_network')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание соц. сети прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editSocialNetwork (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/social-network/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/social_network')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение соц. сети прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    async setContactInformationOnk (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/regional-public-сhamber-contacts`)
      res = await res.json()
      this.commit('setContactInformationOnk', await res.data)
    },
    async editContactInformationOnk (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/regional-public-сhamber-contacts/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/contact_information_onk')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение контактов прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка при изменении'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    async setFeedbackHotline (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/feedback`)
      res = await res.json()
      this.commit('setFeedbackHotline', await res)
    },
    async editFeedbackHotline (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/feedback`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/feedback_hotline')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение информации прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка при изменении'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getForMediaKey: (s) => s.forMedia,
    getContacts: (s) => s.contacts,
    getCurrentContact: (s) => s.currentContact,
    getHotLine: (s) => s.hotLine,
    getPhoneCertificateAdmission: (s) => s.phoneInfo,
    getInformationService: (s) => s.informationService,
    getInternetReception: (s) => s.internetReception,
    getSocialNetwork: (s) => s.socialNetwork,
    getSocialNetworkDetails: (s) => s.socialNetworkDetails,
    getContactInformationOnk: (s) => s.contactInformationOnk,
    getFeedbackHotline: (s) => s.feedbackHotline
  }
}
