import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'
export default {
  state: {
    accreditation: [],
    accreditationAdminPagination: {
      offset: 0,
      limit: 9,
      count: 0,
      total: 0
    },
    detailAccreditation: null,
    statusAccreditation: []
  },
  mutations: {
    setAccreditation (state, payload) {
      state.accreditation = payload
    },
    setStatusAccreditation (state, payload) {
      state.statusAccreditation = payload
    },
    setDetailAccreditation (state, payload) {
      state.detailAccreditation = payload
    },
    setAdminAccreditationPaginationOffset (state, payload) {
      state.accreditationAdminPagination.offset = payload
    },

    setAdminAccreditationPaginationLimit (state, payload) {
      state.accreditationAdminPagination.limit = payload
    },

    setAdminAccreditationPagination (state, payload) {
      state.accreditationAdminPagination = {
        offset: +payload.offset,
        limit: +payload.limit,
        count: +payload.count,
        total: +payload.total
      }
    }
  },
  actions: {
    async setAccreditation (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/accreditation${ctx.getters.getAccreditationPaginationParams}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.debug(await res.meta)
      ctx.commit('setAccreditation', await res.data)
      ctx.commit('setAdminAccreditationPagination', await res.meta)
    },
    async setStatusAccreditation (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/accreditation-status`)
      res = await res.json()
      ctx.commit('setStatusAccreditation', await res.data)
    },
    async setFilterAccreditation (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/survey/list`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
      res = await res.json()
      ctx.commit('setPolls', await res.data.survey)
    },
    async setDetailAccreditation (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/accreditation/${payload}`)
      res = await res.json()
      ctx.commit('setDetailAccreditation', await res.data[0])
      await router.push('/admin/accreditation/create?type=edit')
    },
    async createAccreditation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/accreditation/`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAccreditation')
            router.push('/admin/accreditation')
            ctx.commit('setPolls', {
              type: 'success',
              text: 'Создание СМИ прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteAccreditation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/accreditation/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAccreditation')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'СМИ был удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editAccreditation (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/admin/accreditation/${payload.id}`, payload)
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAccreditation')
            router.push('/admin/accreditation')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение CМИ прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getAccreditation (s) {
      return s.accreditation
    },
    getStatusAccreditation (s) {
      return s.statusAccreditation
    },
    getDetailAccreditation (s) {
      return s.detailAccreditation
    },
    getAccreditationPagination: (s) => s.accreditationAdminPagination,
    getAccreditationPaginationParams (s) {
      let pagination = `?offset=${s.accreditationAdminPagination.offset}`

      if (s.accreditationAdminPagination.limit) {
        pagination += `&limit=${s.accreditationAdminPagination.limit}`
      }

      return pagination
    }
  }
}

/*
:selected="convocations[i].units[j].position === item.title"
 */
