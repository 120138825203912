import router from '@/router'

export default {
  state: {
    polls: [],
    detailPolls: null
  },
  mutations: {
    setPolls (state, payload) {
      state.polls = payload
    },
    setDetailPolls (state, payload) {
      state.detailPolls = payload
    }
  },
  actions: {
    async setPolls (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/survey/list`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setPolls', await res.data.survey)
    },
    async setFilterPolls (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/survey/list`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
      res = await res.json()
      ctx.commit('setPolls', await res.data.survey)
    },
    async setDetailPolls (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/survey/${payload}`)
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailPolls', await res.data)
      await router.push('/admin/polls/create_polls?type=edit')
    },
    async createPolls (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/survey`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllMembers', ctx.getters.getUrlApi)
            router.push('/admin/polls')
            ctx.commit('setPolls', {
              type: 'success',
              text: 'Создание опроса прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deletePolls (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/survey/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setPolls', ctx.getters.getUrlApi)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Опрос был удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editPolls (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/survey/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setPolls', ctx.getters.getUrlApi)
            router.push('/admin/polls')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение профиля прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getPolls (s) {
      return s.polls
    },
    getDetailPolls (s) {
      return s.detailPolls
    }
  }
}
