<template>
  <transition-group name="fade" mode="in-out" tag="div" class="light-box__container">
    <div class="background" @click="closeLightBox" v-if="getLightBoxImage" key="backdrop"></div>
    <img v-if="getLightBoxImage" :src="getLightBoxImage" class="light-box__image" key="image">
  </transition-group>
</template>

<script>

export default {
  name: 'LightBoxImage',

  methods: {
    closeLightBox () {
      this.$store.commit('setLightBoxImage', null)
    }
  },

  computed: {
    getLightBoxImage () {
      return this.$store.getters.getLightBoxImage
    }
  }
}

</script>

<style scoped lang="scss">

  .light-box__container {
    position: relative;

    > img {
      width: 15rem;
      height: 11.25rem;
      object-fit: cover;
      object-position: center;
      border-radius: .5rem;
      cursor: pointer;
    }

    > .background {
      position: fixed;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: .7;
      left: 0;
      top: 0;
      z-index: 100;
      cursor: pointer;
    }

    > .light-box__image {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 70vh;
      width: auto;
      z-index: 110;
      cursor: unset;
    }
  }

</style>
