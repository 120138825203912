import router from '@/router'

export default {
  state: {
    projectAppealText: [],
    detailedProjectAppealText: []
  },
  mutations: {
    setProjectAppealText (state, payload) {
      state.projectAppealText = payload
    },
    setDetailedProjectAppealText (state, payload) {
      state.detailedProjectAppealText = payload
    }
  },
  actions: {
    async setProjectAppealText (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/project_appeal_texts`)
      res = await res.json()
      this.commit('setProjectAppealText', await res)
    },

    async setDetailedProjectAppealText (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/project_appeal_texts/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setDetailedProjectAppealText', await res)
    },

    async deleteProjectAppealText (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_appeal_texts/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Текст подачи заявки успешно удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setProjectAppealText')
      })
        .catch(error => console.log('error', error))
    },

    async createProjectAppealText (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_appeal_texts`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/project_appeal_texts')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание текста подачи заявки прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editProjectAppealText (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_appeal_texts/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/project_appeal_texts')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение текста подачи заявки прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getProjectAppealText: (s) => s.projectAppealText,
    getDetailedProjectAppealText: (s) => s.detailedProjectAppealText
  }
}
