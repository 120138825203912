export default {
  state: {
    appeal: [],
    specificAppeal: []
    // filter: {
    //   location: '',
    //   type: []
    // }
  },

  mutations: {
    setAppeal (state, payload) {
      state.appeal = payload
    },
    setSpecificAppeal (state, payload) {
      state.specificAppeal = payload
    }
  },

  actions: {
    async getAppeal ({ commit }) {
      // const appeal = [
      //   {
      //     type: 'Экология',
      //     icon: 'ecology.svg',
      //     small_icon: 'eco_small.svg',
      //     number: '145124',
      //     title: 'Сброс сточных вод, нарушение использования охраны водных объектов',
      //     subtitle: 'Прошу вас на 7 и 6 этажах первого подъезда повесить плафоныПрошу вас на 7 и 6 этажах первого подъезда повесить плафоныПрошу вас на 7 и а 7 и 6 этажах первого подъезда повесить плафоныПрошу плафоныПрошу',
      //     location: 'Россия, Московская область, Талдомский городской округ',
      //     date: 'Вчера в 12:34',
      //     link: '/',
      //     photo: [],
      //     public_appeal: true,
      //     avatar: 'appeal_avatar.png',
      //     name: 'Силиванова Светлана',
      //     documents: [{ title: 'Заявление в городской суд.pdf', url: '/', size: '217', type: 'PDF' }],
      //     like: 134
      //   }
      // ]
      await fetch(`${this.getters.getUrlApi}api/appeal`, {
        method: 'get',
        headers: new Headers({
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(function (response) {
          if (response.status !== 200) {
            console.log('Status Code: ' + response.status)
            return
          }
          response.json().then(function (data) {
            commit('setAppeal', data.data)
          })
        })
        .catch(function (error) {
          console.log('error', error)
        })
    },
    async getSpecificAppeal ({ commit }, id) {
      await fetch(`${this.getters.getUrlApi}api/appeal/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
        .then(function (response) {
          console.log('response', response)
          return response.json()
        })
        .then(function (data) {
          commit('setSpecificAppeal', data.data)
        })
        .catch(function (error) {
          console.log('error', error)
        })
    }
  },

  getters: {
    getAppeal: state => state.appeal,
    getSpecificAppeal: state => state.specificAppeal
  }
}
