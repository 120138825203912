import router from '@/router'

export default {
  state: {
    engFlag: [],
    detailedEngFlag: []
  },
  mutations: {
    setEngFlag (state, payload) {
      state.engFlag = payload
    },
    setDetailedEngFlag (state, payload) {
      state.detailedEngFlag = payload
    }
  },
  actions: {
    async setEngFlag (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/eng_flag`)
      res = await res.json()
      this.commit('setEngFlag', await res)
    },

    async setDetailedEngFlag (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/eng_flag/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setDetailedEngFlag', await res)
    },

    async deleteEngFlag (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/eng_flag/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Флаг успешно удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setEngFlag')
      })
        .catch(error => console.log('error', error))
    },

    async createEngFlag (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/eng_flag`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/eng_flag')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание флага прошло успешно'
            })
            ctx.dispatch('setEngFlag')
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editEngFlag (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/eng_flag/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/eng_flag')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение флага прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getEngFlag: (s) => s.engFlag,
    getDetailedEngFlag: (s) => s.detailedEngFlag
  }
}
