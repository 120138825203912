import router from '@/router'

export default {
  state: {
    emailMarket: [],
    mailingList: [],
    mailingItem: []
  },
  mutations: {
    setEmailMarket (state, payload) {
      state.emailMarket = payload
    },
    setMailingList (state, payload) {
      state.mailingList = payload
    },
    setMailingItem (state, payload) {
      state.mailingItem = payload
    }
  },
  actions: {
    async updateEmailMarket (ctx, payload) {
      const formData = new FormData()
      formData.append('emails', JSON.stringify(payload.emails))
      await fetch(`${ctx.getters.getUrlApi}api/admin/emails/${payload.title}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: formData
      })
        .then(res => {
          if (res.ok) {
            ctx.dispatch('setEmailMarket', payload.title)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'email добавлены'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async setEmailMarket (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/emails/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setEmailMarket', await res.data.emails)
    },
    // Get mailingList
    async getMailingList (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/mailing/templates`, {
      // let res = await fetch('http://195.208.58.53:8080/api/admin/mailing/templates', {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setMailingList', await res.data)
    },
    // Get current mailing
    async getMailingItem (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/mailing/template/${payload}`, {
      // let res = await fetch(`http://195.208.58.53:8080/api/admin/mailing/template/${payload}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setMailingItem', await res.data)
    },
    // ----- Create Mailing Request -----
    // Params
    // title - заголовок
    // text - (не обязателен)
    // ----- Response -----
    // 200
    async createMailing (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/mailing/template`, {
      // await fetch('http://195.208.58.53:8080/api/admin/mailing/template', {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('getMailingList')
            router.push('/admin/email_market')
            ctx.commit('setMessage', {
              type: 'success',
              text: payload.send ? 'Рассылка создана и отправлена' : 'Создание рассылки прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        // .then((res) => {
        //   fetch(`${ctx.getters.getUrlApi}api/admin/mailing/templates`, {
        //     method: 'GET',
        //     headers: new Headers({
        //       Authorization: localStorage.getItem('token')
        //     })
        //   })
        //     .then((result) => {
        //       if (result.status === 200) {
        //         return result.json()
        //       } else {
        //         ctx.commit('setMessage', {
        //           type: 'error',
        //           text: 'Ошибка отправления рассылки, попробуйте отредактировать и ещё раз отправить'
        //         })
        //       }
        //     })
        //     .then(data => {
        //       console.error(data.data[0].id)
        //     })
        // })
        .catch(error => console.log('error', error))
    },
    // ----- Update Mailing Request -----
    // Params
    // title -(не обяз)
    // text - (не обяз)
    // ----- Response -----
    // 200
    async updateMailing (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/mailing/template/${payload.id}`, {
      // await fetch(`http://195.208.58.53:8080/api/admin/mailing/template/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('getMailingList', payload.id)
            // router.push('/admin/email_market')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение рассылки прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // рассылка /api/admin/mailing/template/{id}/send
    async sendingMailing (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/mailing/template/${payload.id}/send`, {
      // await fetch(`http://195.208.58.53:8080/api/admin/mailing/template/${payload.id}/send`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/email_market')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Осуществляется рассылка'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка. Попробуйте еще раз или обратитесь к разработчикам.'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // delete mailing
    async deleteMailing (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/mailing/template/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.dispatch('getMailingList')
          ctx.commit('setMessage', {
            type: 'success',
            text: `Рассылка №${payload} успешно удалена`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
      })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getEmailMarket (s) {
      return s.emailMarket
    },
    GET_MAILING_LIST: (s) => s.mailingList,
    GET_MAILING_ITEM: (s) => s.mailingItem
  }
}
