<template>
    <div class="user">
        <div class="user__icon" @click="activeBlock=!activeBlock">
            <figure v-if="data.photo" :style="`background: url('${data.photo}') center / cover; background-repeat: no-repeat`"></figure>
            <figure v-else :style="`background: url('/assets/icons/profile_photo.svg') center / cover; background-repeat: no-repeat`"></figure>
            <span :class="{'active_arrow': activeBlock}"></span>
        </div>
        <transition name="fade">
            <div class="user__management" v-show="activeBlock" @click="activeBlock=!activeBlock">
                <router-link to="/personal-account"> <p @click="close">{{$store.getters.GET_LANG ? 'Личный кабинет' : 'Personal account'}}</p></router-link>
                <!-- <router-link>Профиль</router-link> -->
                <router-link to="/"><p @click="removeClick">{{$store.getters.GET_LANG ? 'Выйти' : 'Exit'}}</p> </router-link>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
  name: 'PersonalAccountHeader',
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      activeBlock: false
    }
  },
  methods: {
    close () {
      this.$emit('tokenRemove')
    },
    removeClick () {
      this.$store.dispatch('logOut')
      this.$emit('tokenRemove')
    }
  }
}
</script>
<style lang="scss" scoped>
.user{
    position: relative;

    &__icon{
        width: 3.38rem;
        height: 3.38rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px .38rem 2.063rem rgba(36, 108, 183, 0.15);
        position: relative;
        figure{
            width: 2.74rem;
            height: 2.74rem;
            border-radius: 50%;
        }
        span{
            width: 60%;
            height: 25%;
            border-radius: 85% 85% 100% 100%;
            background: #ffffff;
            position: absolute;
            bottom: .07rem;
        }
        span:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: url('../../assets/icons/arrow.svg') center center no-repeat;
            background-size: contain;
            transition: transform .3s;
        }
    }
    &__management{
        position: absolute;
        right: 25%;
        top: calc(100% + 0.813rem);
        width: 10.5rem;

        background: #FFFFFF;
        filter: drop-shadow(0px 2.063rem 5.5rem rgba(37, 70, 103, 0.15));
        border-radius: .19rem;
        padding: .94rem 1.88rem;

        a, p{
            font-size: .81rem;
            line-height: 2.5rem;
            color: #003E78;
            cursor: pointer;
        }
    }
    &__management:before{
        content: '';
        position: absolute;
        top: -0.13rem;
        right: 1.4rem;
        width: 0.68rem;
        height: .41rem;

        background: #FFFFFF;
        transform: matrix(0.71, 0.42, -1.2, 0.71, 0, 0);
        filter: drop-shadow(0px 2.063rem 5.5rem rgba(37, 70, 103, 0.15));
    }
}
.active_arrow:after{
    transform: rotate(180deg);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}
@media screen and (max-width: 420px){
  .user{
    width: 100%;
    display: flex;
    flex-direction: column;
    &__icon{
      width: 4.38rem;
      height: 4.38rem;
      position: initial;

      figure{
        width: 3.74rem;
        height: 3.74rem;
      }
      span{
        width: 2.5rem;
        height: 2rem;
        border-radius: 0;
        background: transparent;
        position: absolute;
        right: 0;
        top: 1.2rem;
      }
    }
    &__management{
      position: initial;
      background: transparent;
      padding: 0;
      margin-top: .9rem;
    }
    &__management:before{
      display: none;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .user__icon span{
        position: absolute;
        left: 50% !important;
        transform: translate(-50%) !important;
    }
}

</style>
