import router from '@/router'

export default {
  state: {
    structure: [],
    detailStructure: null
  },
  mutations: {
    setStructureOp (state, payload) {
      state.structure = payload
    },
    setDetailStructure (state, payload) {
      state.detailStructure = payload
    }
  },
  actions: {
    async setStructureOp (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/chamber-register`)
      res = await res.json()
      console.log(await res)
      ctx.commit('setStructureOp', await res.data)
    },
    async setFilterStructureOp (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/chamber-register?convocation=${payload}`)
      res = await res.json()
      console.log(await res)
      ctx.commit('setStructureOp', await res.data)
    },
    async setDetailStructure (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/chamber-register/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailStructure', await res.data)
      await router.push('/admin/structure/create?type=edit')
    },
    async createStructure (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/chamber-register`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setStructureOp')
            router.push('/admin/structure')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание задела коррупции прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteStructure (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/chamber-register/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setStructureOp')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Раздел был удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editStructure (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/chamber-register/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/structure')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение пункта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getStructureOP (s) {
      return s.structure
    },
    getDetailStructure (s) {
      return s.detailStructure
    }
  }
}
