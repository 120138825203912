import router from '@/router'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    // ----- createForum Region Subforum Request -----
    // Params
    // title: Название (обяз)
    // date_start: Дата начала (обяз)
    // date_end: Дата окончания (обяз)
    // time:23:59 Время окончания от 00:00 до 23:59 (обяз)
    // location: город, местоположения (обяз)
    // about_forum_text:Текст о форуме (обяз)
    // about_forum_left_block:Левый блок (обяз)
    // about_forum_phones:["теляхон"] (обяз)
    // about_forum_emails:["пошта"] (обяз)
    // program_text:Текст программы (обяз)
    // program_link:Сцылка программы (обяз)
    // image: картинка форума (обяз)
    // forum_program: файл программа форума
    // program_file: файл программы
    // ----- Response -----
    // 200
    async createRegionalForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/${payload.id}/regional-forum`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/forum_final')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // ----- createForum Main Request -----
    // Params
    // title: обяз
    // about_text: обяз
    // study_text: обяз
    // contacts_phone: json обяз [“tag”, “tag”]
    // contacts_emails: json обяз [“tag”, “tag”]
    // year: год обяз
    // study_link: обяз
    // about_file: не обяз
    // about_file_title: null,
    // about_file_type: null,
    // about_file_size: null,
    // about_file_url: null,
    // ----- Response -----
    // 200
    async createMainForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/forum')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // ----- createPartners Request -----
    // Params
    // file - картинка
    // link - ссылка
    // ----- Response -----
    // 200
    async createForumPartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/${payload.id}/partners`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/forum')
            console.error('STOREcreateForumPartners')
            // ctx.dispatch('getMainItemForum', payload.id)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание пратнёра форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // -----Regional createPartners Request -----
    // ----- Response -----
    // 200
    async createRegionalForumPartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/${payload.id}/partners`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/forum')
            // ctx.dispatch('getMainItemForum', payload.id)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание пратнёра форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // -----Regional createSpeaker Request -----
    // ----- Response -----
    // 200
    async createRegionalForumSpeakers (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/${payload.id}/speaker`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/forum')
            // ctx.dispatch('getMainItemForum', payload.id)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание спикера форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // ----- createContactsMain Request -----
    // Params
    // phone - телефон
    // email - почта
    // ----- Response -----
    // 200
    async createContactsMain (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/forum/contacts`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            // router.push('/admin/forum')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание контакта форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // Main forum delete
    async deleteMainForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.dispatch('getMainForumsList')
          ctx.commit('setMessage', {
            type: 'success',
            text: `Форум №${payload} успешно удалён`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
      })
        .catch(error => console.log('error', error))
    },
    // Regional forum delete
    async deleteRegionalForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.dispatch('getForumsList')
          ctx.commit('setMessage', {
            type: 'success',
            text: `Форум №${payload} успешно удалён`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
      })
        .catch(error => console.log('error', error))
    },
    // Partners delete
    async deletePartnersForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/partners/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Партнёр №${payload} успешно удалён`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        // ctx.dispatch('getForumsList')
      })
        .catch(error => console.log('error', error))
    },
    // Regional Partners delete
    async deleteRegionalPartnersForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/partners/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Партнёр №${payload} успешно удалён`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        // ctx.dispatch('getForumsList')
      })
        .catch(error => console.log('error', error))
    },
    // Regional Speaker delete
    async deleteRegionalSpeakersForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/speaker/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Докладчик №${payload} успешно удалён`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        // ctx.dispatch('getForumsList')
      })
        .catch(error => console.log('error', error))
    },
    // Contacts delete
    async deleteContactsMain (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/forum/contacts/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Контакты №${payload} успешно удалён`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        // ctx.dispatch('getForumsList')
      })
        .catch(error => console.log('error', error))
    },
    // ----- updateMainForum Request -----
    // Params
    // title: не обяз
    // about_text: не обяз
    // study_text: не обяз
    // contacts_phone: json не обяз [“tag”, “tag”]
    // contacts_emails: json не обяз [“tag”, “tag”]
    // year: год не обяз
    // study_link:не обяз
    // about_file: не обяз
    // ----- Response -----
    // 200
    async updateMainForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/news')
            ctx.dispatch('getMainItemForum', payload.id)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // ----- updateRegional Request -----
    // Params
    //     title: Название (необяз)
    //     date_start: Дата начала (необяз)
    //     date_end: Дата окончания (необяз)
    //     time:23:59 Время окончания от 00:00 до 23:59 (необяз)
    // location: город, местоположения (необяз)
    // about_forum_text:Текст о форуме (необяз)
    // about_forum_left_block:Левый блок (необяз)
    // about_forum_phones:["теляхон"] (необяз)
    // about_forum_emails:["пошта"] (необяз)
    // program_text:Текст программы (необяз)
    // program_link:Сцылка программы (необяз)
    // image: картинка форума (необяз)
    // forum_program: файл программа форума
    // program_file: файл программы

    // ----- Response -----
    // 200
    async updateRegionalForum (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/news')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    async updatePartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/partners/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/news')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение партнёра форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    async updateRegionalPartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/partners/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/news')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение партнёра форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    async updateRegionalSpeakers (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/global-forums/regional-forum/speaker/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/news')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение спикера форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    },
    // ----- updateContactsMain Request -----
    // Params
    // phone - телефон
    // email - почта
    // ----- Response -----
    // 200
    async updateContactsMain (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/forum/contacts/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // router.push('/admin/news')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение контактов форума прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {

  }
}
