import router from '@/router'

export default {
  state: {
    aboutTheWard: [],
    detailedAboutTheWard: []
  },
  mutations: {
    setAboutTheWard (state, payload) {
      state.aboutTheWard = payload
    },
    setDetailedAboutTheWard (state, payload) {
      state.detailedAboutTheWard = payload
    }
  },
  actions: {
    async setAboutTheWard (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/about-the-ward`)
      res = await res.json()
      this.commit('setAboutTheWard', await res)
    },

    async setDetailedAboutTheWard (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/about-the-ward/${payload}`)
      res = await res.json()
      this.commit('setDetailedAboutTheWard', await res)
    },

    async deleteAboutTheWard (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/about-the-ward/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Страница о палате (Англ.) успешно удалена'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setAboutTheWard')
      })
        .catch(error => console.log('error', error))
    },

    async createAboutTheWard (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/about-the-ward`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/about_the_ward')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание страницы о палате (Англ). прошло успешно'
            })
            ctx.dispatch('setAboutTheWard')
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editAboutTheWard (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/about-the-ward/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/about_the_ward')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение страницы о палате (Англ.) прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getAboutTheWard: (s) => s.aboutTheWard,
    getDetailedAboutTheWard: (s) => s.detailedAboutTheWard
  }
}
