import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'

export default {
  state: {
    country: [],
    detailCountry: null
  },
  mutations: {
    setCountry (state, payload) {
      state.country = payload
    },
    setDetailCountry (state, payload) {
      const detail = state.country.filter(item => item.id === payload)
      state.detailCountry = detail[0]
    }
  },
  actions: {
    async setCountry (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/country`)
      res = await res.json()
      ctx.commit('setCountry', await res.data.country)
    },
    async AddCountry (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/country`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCountry')
            router.push('/admin/country')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание страны прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteСountry (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/country/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCountry')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Страна удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    setDetailCountry (ctx, payload) {
      router.push('/admin/country/create_country?type=edit')
      ctx.commit('setDetailCountry', payload)
    },
    async editCountry (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/admin/country/${payload.id}`, payload)
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/country')
            ctx.dispatch('setCountry', ctx.getters.getUrlApi)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация о стране успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getCountry (s) {
      return s.country
    },
    getDetailCountry (s) {
      return s.detailCountry
    }
  }
}
