export default {
  state: {
    memberId: []
  },
  mutations: {
    setMemberId (state, payload) {
      state.memberId = payload
    }
  },
  actions: {
    async setMemberId (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/member/name`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setMemberId', await res.data)
    }
  },
  getters: {
    getMemberId (s) {
      return s.memberId
    }
  }
}
