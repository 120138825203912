export default {
  state: {
    news: [],
    members: [],
    regionalNews: [],
    blogs: [],
    photo: [],
    video: [],
    structure: [],
    comment: [],
    announcement: [],
    loader: false
  },
  mutations: {
    setSearch (state, payload) {
      state.news = payload.news ? payload.news : []
      state.members = payload.members ? payload.members : []
      state.regionalNews = payload.public_chamber_news ? payload.public_chamber_news : []
      state.blogs = payload.blogs ? payload.blogs : []
      state.photo = payload.photo ? payload.photo : []
      state.video = payload.video ? payload.video : []
      state.structure = payload.structure ? payload.structure : []
      state.comment = payload.comment ? payload.comment : []
      state.announcement = payload.announcement ? payload.announcement : []
      state.loader = false
    },
    setLoader (state, payload) {
      state.loader = payload
    }
  },
  actions: {
    async setSearch (ctx, payload) {
      ctx.commit('setLoader', true)

      let url = `${ctx.getters.getUrlApi}api/search?key=${payload.search}`
      payload.tag.forEach(item => {
        if (item.active === true) {
          url += `&${item.code}=${item.active}`
        }
      })
      let res = await fetch(url, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      ctx.commit('setSearch', await res.data)
    }
  },
  getters: {
    getSearchNews (s) {
      return s.news
    },
    getSearchAnnouncement (s) {
      return s.announcement
    },
    getSearchMembers (s) {
      return s.members
    },
    getRegionalNews (s) {
      return s.regionalNews
    },
    getStructure (s) {
      return s.structure
    },
    getComment (s) {
      return s.comment
    },
    getBlogs (s) {
      return s.blogs
    },
    getLoader: s => s.loader
  }
}
