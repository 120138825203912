export default {
  state: {
    documentList: [],
    recommendationDocuments: []
  },

  mutations: {
    SET_DOCUMENT_LIST (state, payload) {
      state.documentList = payload
    },
    SET_RECOMMENDATION_DOCUMENT (state, payload) {
      state.recommendationDocuments = payload
    }
  },

  actions: {
    getRegulatoryDocuments ({ commit }, payload) {
      const regulatoryDocuments = [
        // NORM_DOC_REGULATIONS    Нормативные документы. Регламент
        // NORM_DOC_ORDER          Нормативные документы. Законы
        // NORM_DOC_CODE           Нормативные документы. Кодекс этики
        {
          id: '1',
          name: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
          url: '',
          type: 'pdf',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'NORM_DOC_ORDER',
          createDate: '12.10.2020'
        }, {
          id: '2',
          name: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'NORM_DOC_ORDER',
          createDate: '12.10.2020'
        }, {
          id: '3',
          name: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'NORM_DOC_ORDER',
          createDate: '12.10.2020'
        }, {
          id: '4',
          name: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'NORM_DOC_ORDER',
          createDate: '12.10.2020'
        }, {
          id: '5',
          name: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'NORM_DOC_ORDER',
          createDate: '12.10.2020'
        }, {
          id: '6',
          name: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'NORM_DOC_ORDER',
          createDate: '12.10.2020'
        }, {
          id: '7',
          name: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'NORM_DOC_REGULATIONS',
          createDate: '12.10.2020'
        }, {
          id: '8',
          name: 'Указ Президента Российской Федерации от 1 сентября 2009 г. №986 «Об Уполномоченном при Президенте Российской Федерации по правам ребенка»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'NORM_DOC_CODE',
          createDate: '12.10.2020'
        }, {
          id: '9',
          name: 'Рекомендации Общественной палаты РФ по итогам круглого стола на тему «Проблемы многодетных семей России и пути их решения»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'MATERIALS',
          createDate: '12.10.2020'
        }, {
          id: '10',
          name: 'Рекомендации Общественной палаты РФ по итогам круглого стола на тему «Проблемы многодетных семей России и пути их решения»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'MATERIALS',
          createDate: '12.10.2020'
        }, {
          id: '11',
          name: 'Рекомендации Общественной палаты РФ по итогам круглого стола на тему «Проблемы многодетных семей России и пути их решения»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'MATERIALS',
          createDate: '12.10.2020'
        },
        {
          id: '9',
          name: 'Рекомендации Общественной палаты РФ по итогам круглого стола на тему «Проблемы многодетных семей России и пути их решения»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'RECOMMENDATIONS',
          createDate: '12.10.2020'
        }, {
          id: '10',
          name: 'Рекомендации Общественной палаты Российской Федерации по итогам общественных слушаний на тему: «О подготовке государственного доклада \n' +
            'о положении детей и семей, имеющих детей, в Российской Федерации за 2018 год»',
          url: '',
          type: 'PDF',
          size: '217 КБ',
          tagList: '',
          systemTagList: 'RECOMMENDATIONS',
          createDate: '12.10.2020'
        }
      ]
      commit('SET_DOCUMENT_LIST', regulatoryDocuments)
    },
    apiGetUnitDocumentList ({ commit }, payload) {
      let unitDocumentList = []
      if (payload[0] === 'RECOMMENDATIONS') {
        unitDocumentList = [
          {
            id: '9',
            name: 'Рекомендации Общественной палаты РФ по итогам круглого стола на тему «Проблемы многодетных семей России и пути их решения»',
            url: '',
            type: 'PDF',
            size: '217 КБ',
            tagList: '',
            systemTagList: 'RECOMMENDATIONS',
            createDate: '12.10.2020'
          }, {
            id: '10',
            name: 'Рекомендации Общественной палаты Российской Федерации по итогам общественных слушаний на тему: «О подготовке государственного доклада \n' +
              'о положении детей и семей, имеющих детей, в Российской Федерации за 2018 год»',
            url: '',
            type: 'PDF',
            size: '217 КБ',
            tagList: '',
            systemTagList: 'RECOMMENDATIONS',
            createDate: '12.10.2020'
          }
        ]
      }
      commit('SET_RECOMMENDATION_DOCUMENT', unitDocumentList)
    }
  },

  getters: {
    GET_DOCUMENT_LIST: state => state.documentList,
    GET_RECOMMENDATION_DOCUMENT: state => state.recommendationDocuments
  }
}
