import router from '@/router'

export default {

  state: {
    topics: [],
    detailedTopic: []
  },

  mutations: {
    setTopics (state, payload) {
      state.topics = payload
    },

    setDetailedTopic (state, payload) {
      state.detailedTopic = payload
    }
  },

  actions: {
    // ${ctx.getters.getUrlApi}

    async getTopics (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/topic`)
      res = await res.json()
      this.commit('setTopics', await res.topic)
    },

    async setDetailedTopic (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/topic/${payload}`)
      res = await res.json()
      this.commit('setDetailedTopic', await res)
    },

    async deleteTopic (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/topic/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Актуальная тема №${payload} успешно удалена`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('getTopics')
      })
        .catch(error => console.log('error', error))
    },

    async createTopic (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/topic`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/topic')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание актуальной темы прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editTopic (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/topic/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/topic')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение актуальной темы прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },

  getters: {
    getTopics: (s) => s.topics,
    getDetailedTopic: (s) => s.detailedTopic
  }

}
