import router from '@/router'

export default {
  state: {
    indexTags: [],
    detailedIndexTag: []
  },
  mutations: {
    setIndexTags (state, payload) {
      state.indexTags = payload
    },
    setDetailedIndexTag (state, payload) {
      state.detailedIndexTag = payload
    }
  },
  actions: {
    async setIndexTags (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/index_tags`)
      res = await res.json()
      this.commit('setIndexTags', await res)
    },

    async setDetailedIndexTag (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/index_tags/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setDetailedIndexTag', await res)
    },

    async deleteIndexTag (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/index_tags/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Тэг успешно удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setIndexTags')
      })
        .catch(error => console.log('error', error))
    },

    async createIndexTag (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/index_tags`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token'),
          Accept: 'application/json'
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/index_tags')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание тэга прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editIndexTag (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/index_tags/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/index_tags')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение тэга прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getIndexTags: (s) => s.indexTags,
    getDetailedIndexTag: (s) => s.detailedIndexTag
  }
}
