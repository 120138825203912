import router from '@/router'
export default {
  state: {
    infoAdvice: [],
    detailInfoAdvice: null
  },
  mutations: {
    setInfoAdvice (state, payload) {
      state.infoAdvice = payload
    },
    setDetailInfoAdvice (state, payload) {
      state.detailInfoAdvice = payload
    }
  },
  actions: {
    async setInfoAdvice (ctx) {
      const prepareArray = []
      let res = await fetch(`${ctx.getters.getUrlApi}api/regional-public-сhamber-two`)
      res = await res.json()
      await res.data.map(item => {
        item.data.map(obj => {
          prepareArray.push(obj)
        })
      })
      ctx.commit('setInfoAdvice', await prepareArray)
    },
    async AddInfoAdvice (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/regional-public-сhamber-two`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setInfoAdvice')
            router.push('/admin/info_advice')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteInfoAdvice (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/regional-public-сhamber-two/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setInfoAdvice')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение удалено'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailInfoAdvice (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/regional-public-сhamber-two/${payload}`)
      res = await res.json()
      console.log(await res.data)
      ctx.commit('setDetailInfoAdvice', await res.data)
      await router.push('/admin/info_advice/create?type=edit')
    },
    async editInfoAdvice (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/regional-public-сhamber-two/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/info_advice')
            ctx.dispatch('setInfoAdvice')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getInfoAdvice (s) {
      return s.infoAdvice
    },
    getDetailInfoAdvice (s) {
      return s.detailInfoAdvice
    }
  }
}
