import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index')
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: () => import('../views/Page')
  },
  {
    path: '/contacts',
    name: 'MainContact',
    component: () => import('../views/Contact/MainContact')
  },
  {
    // обращение
    path: '/appeal-main',
    name: 'AppealMain',
    component: () => import('../views/Appeal/AppealMain')
  },
  {
    // обращение
    path: '/appeal',
    name: 'Appeal',
    component: () => import('../views/Appeal/Appeal')
  },
  {
    path: '/appeal_map',
    name: 'AppealMap',
    component: () => import('../views/AppealMap')
  },
  {
    // члены общественной палаты
    path: '/members_chamber',
    name: 'MembersPublicChamber',
    component: () => import('../views/ChamberMember/MembersPublicChamber')
  },
  {
    // новости
    path: '/news',
    name: 'NewsList',
    component: () => import('../views/News/NewsList')
  },
  {
    // новости детальные
    path: '/news/:id',
    name: 'News',
    props: true,
    component: () => import('../views/News/News')
  },
  {
    // медиа детальные
    path: '/media/:id',
    name: 'Media',
    props: true,
    component: () => import('../views/News/Media')
  },
  {
    // стримы детальные
    path: '/live_stream/:id',
    name: 'Stream',
    props: true,
    component: () => import('../views/News/Stream')
  },
  {
    // аннотации детальные
    path: '/announcement/:id',
    name: 'Announcements',
    props: true,
    component: () => import('../views/News/Announcement')
  },
  {
    // аннотации детальные
    path: '/comment/:id',
    name: 'Comments',
    props: true,
    component: () => import('../views/News/Comments')
  },
  {
    // Структура общественной палаты
    path: '/structure_chamber',
    name: 'StructurePublicChamber',
    component: () => import('../views/ChamberMember/StructurePublicChamber')
  },
  {
    // Структура общественной палаты
    path: '/structure_chamber/:id',
    name: 'StructureChamberDetail',
    component: () => import('../views/ChamberMember/StructureChamberDetail')
  },
  {
    // профиль члена палаты
    path: '/member_profile/velixov-evgenii-pavlovic',
    name: 'VelihovEP',
    props: true,
    component: () => import('../views/ChamberMember/VelihovEP')
  },
  {
    // профиль члена палаты
    path: '/member_profile/velikhov-evgeniy-pavlovich',
    name: 'VelihovEP',
    props: true,
    component: () => import('../views/ChamberMember/VelihovEP')
  },
  {
    // профиль члена палаты
    path: '/member_profile/:id',
    name: 'MemberProfile',
    props: true,
    component: () => import('../views/ChamberMember/ChamberMemberProfile')
  },
  {
    //  Нормативная база
    path: '/normative_base',
    name: 'NormativeBase',
    component: () => import('../views/ChamberMember/ChamberNormativeBase')
  },
  {
    //  О палате. детальная страница комиссии
    path: '/structure_list/:id',
    name: 'ChamberCommittee',
    props: true,
    component: () => import('../views/ChamberMember/ChamberCommittee')
  },
  {
    // О палате. Партнеры
    path: '/partners_chamber',
    name: 'PartnersChamber',
    props: true,
    component: () => import('../views/ChamberMember/PartnersChamber')
  },
  {
    // Противодействие коррупции
    path: '/anti_corruption_measures',
    name: 'AntiCorruptionMeasures',
    props: true,
    component: () => import('../views/ChamberMember/AntiCorruptionMeasures')
  },
  {
    // Противодействие коррупции детальная
    path: '/anti_corruption_measures/:id',
    name: 'AntiCorruptionMeasuresDetail',
    props: true,
    component: () => import('../views/ChamberMember/AntiCorruptionMeasuresDetail')
  },
  {
    // Аппарат
    path: '/staff_chamber',
    name: 'DeviceChamber',
    props: true,
    component: () => import('../views/ChamberMember/DeviceChamber')
  },
  {
    // Нормативные правовые Акты
    path: '/normative_legal_act',
    name: 'NormativeLegalAct',
    props: true,
    component: () => import('../views/ChamberMember/NormativeLegalAct')
  },
  {
    // Формы документов антикоррупция
    path: '/document_forms',
    name: 'DocumentForms',
    props: true,
    component: () => import('../views/ChamberMember/DocumentForms')
  },
  {
    // деятельность. опросы
    path: '/surveys_activities',
    name: 'SurveysActivities',
    props: true,
    component: () => import('../views/Activity/SurveysActivities')
  },
  {
    // деятельность. Актуальное
    path: '/actual',
    name: 'Actual',
    props: true,
    component: () => import('../views/Activity/Actual')
  },
  {
    // Деятельность. Актуальная тема
    path: '/actuals_topic/:id',
    name: 'CurrentTopic',
    props: true,
    component: () => import('../views/Activity/CurrentTopic')
  },
  {
    // Деятельность. Актуальные темы
    path: '/actuals_topic',
    name: 'ActualsTopics',
    props: true,
    component: () => import('../views/Activity/ActualsTopics')
  },
  {
    // Деятельность. Актуальные темы
    path: '/bills',
    name: 'Bills',
    props: true,
    component: () => import('../views/Activity/Bills')
  },
  {
    // Деятельность. законопроекты
    path: '/new_bill',
    name: 'NewBill',
    props: true,
    component: () => import('../views/Activity/NewBill')
  },
  {
    // Деятельность. общественные палаты новости
    path: '/public_chamber_news',
    name: 'PublicChamberNews',
    props: true,
    component: () => import('../views/Activity/PublicChamberNews')
  },
  {
    // Деятельность. общественные палаты новости
    path: '/public_chamber_materials',
    name: 'PublicChamberMaterials',
    props: true,
    component: () => import('../views/Activity/PublicChamberMaterials')
  },
  {
    // Деятельность. Общественные наблюдательные комиссии
    path: '/public_monitoring_commissions',
    name: 'PublicMonitoringCommissions',
    props: true,
    component: () => import('../views/Activity/PublicMonitoringCommissions')
  },
  {
    // Деятельность. Принят законопроект на обсуждении
    path: '/adopted_bill/:id',
    name: 'AdoptedBill',
    component: () => import('../views/Activity/AdoptedBill')
  },
  {
    // Деятельность. Общественные палаты регионов
    path: '/public_chamber_regions',
    name: 'ChamberRegions',
    component: () => import('../views/Activity/ChamberRegions')
  },
  {
    // Деятельность. Общественные палаты регионов
    path: '/activity_documents',
    name: 'ActivityDocuments',
    component: () => import('../views/Activity/ActivityDocuments')
  },
  {
    // Деятельность. Проекты
    path: '/activities_projects',
    name: 'ActivitiesProjects',
    component: () => import('../views/Activity/ActivitiesProjects')
  },
  {
    // Деятельность. Проекты детальная. Потом переделать через /:id
    path: '/detail_project/:id',
    name: 'DetailProject',
    component: () => import('../views/Activity/DetailProject')
  },
  {
    // Деятельность. Регионы детальная
    path: '/chamber-region/:id',
    name: 'ChamberRegionsDetail',
    component: () => import('../views/Activity/ChamberRegionsDetail')
  },
  {
    // Деятельность. Прекращение полномочий
    path: '/termination-office',
    name: 'TerminationOffice',
    component: () => import('../views/Activity/TerminationOffice')
  },
  {
    // Деятельность. Общественная экспертиза
    path: '/public-examination',
    name: 'PublicExamination',
    component: () => import('../views/Activity/PublicExamination')
  },
  {
    // Деятельность. Законопроекты для обсуждения
    path: '/public-examination-bills',
    name: 'PublicExaminationBills',
    component: () => import('../views/Activity/PublicExaminationBills')
  },
  {
    // Деятельность. Экспертные заключения
    path: '/public-examination-opinion',
    name: 'PublicExaminationOpinion',
    component: () => import('../views/Activity/PublicExaminationOpinion')
  },
  {
    // Деятельность. Форум
    path: '/forum',
    name: 'Forum',
    component: () => import('../views/Activity/Forum')
  },
  {
    // Деятельность. Финальный Форум
    path: '/forum/:id',
    name: 'ForumFinal',
    component: () => import('../views/Activity/ForumFinal')
  },
  {
    // Деятельность. Кандидату в общественный совет
    path: '/community_councils',
    name: 'CommunityCouncils',
    component: () => import('../views/Activity/CommunityCouncils')
  },
  {
    // Поиск
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search')
  },
  {
    // обращения детальные
    path: '/appeal/:id',
    name: 'AppealDetail',
    props: true,
    component: () => import('../views/Appeal/AppealDetail')
  },
  {
    // обращения форма отправки
    path: '/appeal_forms',
    name: 'AppealForms',
    props: true,
    component: () => import('../views/Appeal/AppealForms')
  },
  {
    // Реестр шаблонов
    path: '/feedback',
    name: 'FeedbackTemplates',
    component: () => import('../views/Feedback/FeedbackTemplates')
  },
  {
    // ДЕтальный просмотр шаблона
    path: '/feedback/:id',
    name: 'FeedbackDetail',
    props: true,
    component: () => import('../views/Feedback/FeedbackDetail')
  },
  {
    // Админка
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin/index'),
    beforeEnter: async (to, from, next) => {
      if (localStorage.getItem('token') !== null) {
        const res = await fetch(`${store.getters.getUrlApi}api/user/me`, {
          headers: {
            Authorization: localStorage.getItem('token')
          }
        })
        await res.json()
          .then(res => {
            if (res.data.role === 'Администратор' || res.data.role === 'Контент менеджер') {
              // запросы для работы страницы члены ОПРФ
              store.dispatch('setAllMembers')
              store.dispatch('setAllPosition')
              store.dispatch('setConvocation')
              store.dispatch('setInclusionGround')
              // запрос для добавление Системных тегов
              // store.dispatch('setSystemTagList')
              // запрос для получение типов подразделений, которые статичны
              store.dispatch('setUnitType')
              store.commit('setMessage', {
                type: 'success',
                text: 'Добро пожаловать в админ панель'
              })
              return next()
            } else {
              store.commit('setMessage', {
                type: 'error',
                text: 'У вас нету прав на использование админ панели'
              })
              return next({
                path: '/'
              })
            }
          })
      }
    },
    children: [
      {
        path: '',
        name: 'indexAdmin',
        component: () => import('../views/Admin/HelloPages')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/Admin/user/Users')
      },
      {
        path: 'users/create_user',
        name: 'createUser',
        component: () => import('../views/Admin/user/CreateUser')
      },
      {
        path: 'members',
        name: 'members',
        component: () => import('../views/Admin/member/members')
      },
      {
        path: 'members/create_member',
        name: 'createMember',
        component: () => import('../views/Admin/member/createMember')
      },
      {
        path: 'polls',
        name: 'polls',
        component: () => import('../views/Admin/feedback/Polls')
      },
      {
        path: 'polls/create_polls',
        name: 'createPolls',
        component: () => import('../views/Admin/feedback/CreatePolls')
      },
      {
        path: 'country',
        name: 'country',
        component: () => import('../views/Admin/summaryData/country/Country')
      },
      {
        path: 'country/create_country',
        name: 'createCountry',
        component: () => import('../views/Admin/summaryData/country/CreateCountry')
      },

      {
        path: 'news',
        name: 'news',
        component: () => import('../views/Admin/news/News')
      },
      {
        path: 'news/create',
        name: 'createNews',
        component: () => import('../views/Admin/news/CreateNews')
      },
      {
        path: 'news/:id/edit',
        name: 'editNews',
        component: () => import('../views/Admin/news/CreateNews'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'news_comments',
        name: 'newsComments',
        component: () => import('../views/Admin/newsComments/NewsComments')
      },
      {
        path: 'news_comments/:id/create',
        name: 'createNewsComment',
        component: () => import('../views/Admin/newsComments/CreateNewsComment')
      },
      {
        path: 'news_comments/:id/edit/:commentId',
        name: 'editNewsComment',
        component: () => import('../views/Admin/newsComments/CreateNewsComment'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'announcement',
        name: 'announcement',
        component: () => import('../views/Admin/announcement/Announcements')
      },
      {
        path: 'announcement/create',
        name: 'createAnnouncement',
        component: () => import('../views/Admin/announcement/CreateAnnouncement')
      },
      {
        path: 'announcement/:id/edit',
        name: 'editAnnouncement',
        component: () => import('../views/Admin/announcement/CreateAnnouncement'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'media',
        name: 'media',
        component: () => import('../views/Admin/media/Medias')
      },
      {
        path: 'media/create',
        name: 'createMedia',
        component: () => import('../views/Admin/media/CreateMedia')
      },
      {
        path: 'media/:id/edit',
        name: 'editMedia',
        component: () => import('../views/Admin/media/CreateMedia'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'live_stream',
        name: 'liveStream',
        component: () => import('../views/Admin/liveStream/LiveStreams')
      },
      {
        path: 'live_stream/create',
        name: 'createLiveStream',
        component: () => import('../views/Admin/liveStream/CreateLiveStream')
      },
      {
        path: 'live_stream/:id/edit',
        name: 'editLiveStream',
        component: () => import('../views/Admin/liveStream/CreateLiveStream'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'topic',
        name: 'topic',
        component: () => import('../views/Admin/topic/Topics')
      },
      {
        path: 'topic/create',
        name: 'createTopic',
        component: () => import('../views/Admin/topic/CreateTopic')
      },
      {
        path: 'topic/:id/edit',
        name: 'editTopic',
        component: () => import('../views/Admin/topic/CreateTopic'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: () => import('../views/Admin/contacts/Contacts')
      },
      {
        path: 'for_media_contacts',
        name: 'forMediaContacts',
        component: () => import('../views/Admin/contacts/ContactsForMedia')
      },
      {
        path: 'contacts/create',
        name: 'createContacts',
        component: () => import('../views/Admin/contacts/CreateContact')
      },

      {
        path: 'contacts/:id/edit',
        name: 'editContact',
        component: () => import('../views/Admin/contacts/CreateContact'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'convocation_list',
        name: 'convocationList',
        component: () => import('../views/Admin/summaryData/convocationList/ConvocationList')
      },
      {
        path: 'convocation_list/create',
        name: 'createConvocationList',
        component: () => import('../views/Admin/summaryData/convocationList/CreateConvocationList')
      },
      {
        path: 'unit',
        name: 'unit',
        component: () => import('../views/Admin/summaryData/unit/Unit')
      },
      {
        path: 'unit/create',
        name: 'createUnit',
        component: () => import('../views/Admin/summaryData/unit/CreateUnit')
      },
      {
        path: 'documents',
        name: 'documents',
        component: () => import('../views/Admin/active/documents/Documents')
      },
      {
        path: 'documents/create',
        name: 'createDocuments',
        component: () => import('../views/Admin/active/documents/CreateDocuments')
      },
      {
        path: 'bill_draft',
        name: 'billDraft',
        component: () => import('../views/Admin/active/billDraft/billDraft')
      },
      {
        path: 'bill_draft/create',
        name: 'createBillDraft',
        component: () => import('../views/Admin/active/billDraft/createbillDraft')
      },
      {
        path: 'apparatus',
        name: 'apparatus',
        component: () => import('../views/Admin/aboutChamber/apparatus/Documents')
      },
      {
        path: 'apparatus/create',
        name: 'createApparatus',
        component: () => import('../views/Admin/aboutChamber/apparatus/CreateDocuments')
      },
      {
        path: 'apparatus_category',
        name: 'apparatusСategory',
        component: () => import('../views/Admin/aboutChamber/apparatusСategory/Сategory')
      },
      {
        path: 'apparatus_category/create',
        name: 'createApparatusСategory',
        component: () => import('../views/Admin/aboutChamber/apparatusСategory/CreateСategory')
      },
      {
        path: 'partners',
        name: 'partners',
        component: () => import('../views/Admin/aboutChamber/partners/Partners')
      },
      {
        path: 'partners/create',
        name: 'createPartners',
        component: () => import('../views/Admin/aboutChamber/partners/CreatePartners')
      },
      {
        path: 'project',
        name: 'project',
        component: () => import('../views/Admin/active/project/Project')
      },
      {
        path: 'project/create',
        name: 'createProject',
        component: () => import('../views/Admin/active/project/CreateProject')
      },
      {
        path: 'email_market',
        name: 'emailMarket',
        component: () => import('../views/Admin/emailMarket/Email')
      },
      {
        path: 'email_market/create',
        name: 'mailing',
        component: () => import('../views/Admin/emailMarket/CreateMailing')
      },
      {
        path: 'email_market/:id/edit',
        name: 'editMailing',
        component: () => import('../views/Admin/emailMarket/CreateMailing'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'blogs',
        name: 'blogs',
        component: () => import('../views/Admin/blogs/BlogsList')
      },
      {
        path: 'blog_create',
        name: 'BlogCreateAdmin',
        component: () => import('../views/Admin/blogs/BlogCreateAdmin')
      },
      {
        path: 'blog_create/:member/:id',
        name: 'editBlog',
        component: () => import('../views/Admin/blogs/BlogCreateAdmin'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'accreditation',
        name: 'accreditation',
        component: () => import('../views/Admin/accreditation/Accreditation')
      },
      {
        path: 'accreditation/create',
        name: 'createАccreditation',
        component: () => import('../views/Admin/accreditation/CreateAccreditation')
      },
      {
        path: 'positions',
        name: 'positions',
        component: () => import('../views/Admin/summaryData/positions/Positions')
      },
      {
        path: 'positions/create',
        name: 'createPositions',
        component: () => import('../views/Admin/summaryData/positions/CreatePositions')
      },
      {
        path: 'corruption',
        name: 'corruption',
        component: () => import('../views/Admin/aboutChamber/corruption/Сorruption')
      },
      {
        path: 'corruption/create',
        name: 'createCorruption',
        component: () => import('../views/Admin/aboutChamber/corruption/CreateСorruption')
      },
      {
        path: 'structure',
        name: 'structure',
        component: () => import('../views/Admin/aboutChamber/structureOP/structure')
      },
      {
        path: 'structure/create',
        name: 'createStructure',
        component: () => import('../views/Admin/aboutChamber/structureOP/CreateStructure')
      },
      {
        path: 'commissionUpdate',
        name: 'commissionUpdate',
        component: () => import('../views/Admin/active/commissionUpdate/CommissionUpdate.vue')
      },
      {
        path: 'commissionUpdate/create',
        name: 'createCommissionUpdate',
        component: () => import('../views/Admin/active/commissionUpdate/createCommissionUpdate')
      },
      {
        path: 'comments_blogs',
        name: 'commentsBlogs',
        component: () => import('../views/Admin/comments/CommentsBlogs')
      },
      {
        path: 'comment_blog/:id/create',
        name: 'commentsCreate',
        component: () => import('../views/Admin/comments/CommentsBlogsCreate'),
        props: {
          isEdit: false
        }
      },
      {
        path: 'comment_blog/:id/edit',
        name: 'commentsEdit',
        component: () => import('../views/Admin/comments/CommentsBlogsCreate'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'comments_bill',
        name: 'commentsBill',
        component: () => import('../views/Admin/comments/CommentsBill')
      },
      {
        path: 'comment_bill/:id/create',
        name: 'CommentsBillCreate',
        component: () => import('../views/Admin/comments/CommentsBillCreate'),
        props: {
          isEdit: false
        }
      },
      {
        path: 'comment_bill/:id/edit',
        name: 'CommentsBillEdit',
        component: () => import('../views/Admin/comments/CommentsBillCreate'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'comments_topical',
        name: 'commentsTopical',
        component: () => import('../views/Admin/comments/CommentsTopical')
      },
      {
        path: 'comment_topical/:id/create',
        name: 'commentsTopicalCreate',
        component: () => import('../views/Admin/comments/CommentsTopicalCreate'),
        props: {
          isEdit: false
        }
      },
      {
        path: 'comment_topical/:id/edit',
        name: 'commentsTopicalEdit',
        component: () => import('../views/Admin/comments/CommentsTopicalCreate'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'expert_opinions',
        name: 'expertOpinions',
        component: () => import('../views/Admin/active/expertOpinions/Documents')
      },
      {
        path: 'expert_opinions/create',
        name: 'createExpertOpinions',
        component: () => import('../views/Admin/active/expertOpinions/CreateDocuments')
      },
      {
        path: 'activity_plan',
        name: 'expertActivityPlan',
        component: () => import('../views/Admin/active/expertActivityPlan/Documents')
      },
      {
        path: 'activity_plan/create',
        name: 'createExpertActivityPlan',
        component: () => import('../views/Admin/active/expertActivityPlan/CreateDocuments')
      },
      {
        path: 'onk_document',
        name: 'onkDocuments',
        component: () => import('../views/Admin/active/onkDocuments/Documents')
      },
      {
        path: 'onk_document/create',
        name: 'createOnkDocuments',
        component: () => import('../views/Admin/active/onkDocuments/CreateDocuments')
      },
      {
        path: 'info_nko',
        name: 'infoNko',
        component: () => import('../views/Admin/active/infoNko/infoNko')
      },
      {
        path: 'info_nko/create',
        name: 'createInfoNko',
        component: () => import('../views/Admin/active/infoNko/createInfoNk')
      },
      {
        path: 'info_advice',
        name: 'infoAdvice',
        component: () => import('../views/Admin/active/infoAdvice/infoAdvice')
      },
      {
        path: 'info_advice/create',
        name: 'createInfoAdvice',
        component: () => import('../views/Admin/active/infoAdvice/createInfoAdvice')
      },
      {
        path: 'nko_partners',
        name: 'OnkPartners',
        component: () => import('../views/Admin/active/onkPartners/OnkPartners')
      },
      {
        path: 'nko_partners/create',
        name: 'createOnkPartners',
        component: () => import('../views/Admin/active/onkPartners/CreateOnkPartners')
      },
      {
        path: 'asked_questions',
        name: 'AskedQuestions',
        component: () => import('../views/Admin/active/askedQuestions/AskedQuestions')
      },
      {
        path: 'asked_questions/create',
        name: 'createAskedQuestions',
        component: () => import('../views/Admin/active/askedQuestions/CreateAskedQuestions')
      },
      {
        path: 'asked_questions/:id/edit',
        name: 'AskedQuestionsEdit',
        component: () => import('../views/Admin/active/askedQuestions/CreateAskedQuestions'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'public_doc',
        name: 'publicDocuments',
        component: () => import('../views/Admin/active/publicDocuments/Documents')
      },
      {
        path: 'public_doc/create',
        name: 'createPublicDocuments',
        component: () => import('../views/Admin/active/publicDocuments/CreateDocuments')
      },
      {
        path: 'normative_base',
        name: 'normativeBase',
        component: () => import('../views/Admin/aboutChamber/normativeBase/NormativeBase')
      },
      {
        path: 'normative_base/create',
        name: 'createNormativeBases',
        component: () => import('../views/Admin/aboutChamber/normativeBase/CreateNormativeBase')
      },
      {
        path: 'forum',
        name: 'forum',
        component: () => import('../views/Admin/forum/Forum')
      },
      {
        path: 'forum_final',
        name: 'forumFinal',
        component: () => import('../views/Admin/forum/forumFinal/Index')
      },
      {
        path: 'forum/create',
        name: 'createForum',
        component: () => import('../views/Admin/forum/CreateForum')
      },
      {
        path: 'forum/:id/edit',
        name: 'editForum',
        component: () => import('../views/Admin/forum/CreateForum'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'forum_final/create',
        name: 'createForumFinal',
        component: () => import('../views/Admin/forum/forumFinal/CreateForumFinal')
      },
      {
        path: 'forum_final/:id/edit',
        name: 'editForumFinal',
        component: () => import('../views/Admin/forum/forumFinal/CreateForumFinal'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'inclusion_ground',
        name: 'inclusionGround',
        component: () => import('../views/Admin/summaryData/inclusionGround/InclusionGround')
      },
      {
        path: 'inclusion_ground/create',
        name: 'createInclusionGround',
        component: () => import('../views/Admin/summaryData/inclusionGround/CreateInclusionGround')
      },
      {
        path: 'candidate_public_council',
        name: 'CandidatePublicCouncil',
        component: () => import('../views/Admin/active/candidate/CandidatePublicCouncil')
      },
      {
        path: 'candidate_public_council/create',
        name: 'CandidatePublicCouncilCreate',
        component: () => import('../views/Admin/active/candidate/CandidatePublicCouncilCreate')
      },
      {
        path: 'formation_councils',
        name: 'FormationCouncils',
        component: () => import('../views/Admin/active/formationCouncils/FormationCouncils')
      },
      {
        path: 'formation_councils/create',
        name: 'CreateFormationCouncils',
        component: () => import('../views/Admin/active/formationCouncils/CreateFormationCouncils')
      },
      {
        path: 'formation_councils/:id/edit',
        name: 'EditFormationCouncils',
        component: () => import('../views/Admin/active/formationCouncils/CreateFormationCouncils'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'community_councils_file',
        name: 'communityCouncilsFile',
        component: () => import('../views/Admin/active/communityCouncilsFile/Documents')
      },
      {
        path: 'community_councils_file/create',
        name: 'createCommunityCouncilsFile',
        component: () => import('../views/Admin/active/communityCouncilsFile/CreateDocuments')
      },
      {
        path: 'corruption_section',
        name: 'corruptionSection',
        component: () => import('../views/Admin/summaryData/corruptionSection/CorruptionSection')
      },
      {
        path: 'corruption_section/create',
        name: 'createCorruptionSection',
        component: () => import('../views/Admin/summaryData/corruptionSection/CreateCorruptionSection')
      },
      {
        path: 'corruption_group',
        name: 'corruptionGroup',
        component: () => import('../views/Admin/summaryData/corruptionGroup/CorruptionGroup')
      },
      {
        path: 'corruption_group/create',
        name: 'createCorruptionGroup',
        component: () => import('../views/Admin/summaryData/corruptionGroup/CreateCorruptionGroup')
      },
      {
        path: 'recommendation',
        name: 'recommendation',
        component: () => import('../views/Admin/aboutChamber/recommendation/Recommendation')
      },
      {
        path: 'recommendation/create',
        name: 'createRecommendation',
        component: () => import('../views/Admin/aboutChamber/recommendation/CreateRecommendation')
      },
      {
        path: 'connect_contacts',
        name: 'ConnectContacts',
        component: () => import('../views/Admin/contacts/ConnectContacts')
      },
      {
        path: 'connect_contacts/hotLine/edit',
        name: 'createConnectContacts',
        component: () => import('../views/Admin/contacts/CreateConnectContacts')
      },
      {
        path: 'connect_contacts/certificate_admission/edit',
        name: 'CreatePhoneCertificate',
        component: () => import('../views/Admin/contacts/CreatePhoneCertificate')
      },
      {
        path: 'connect_contacts/information_service/edit',
        name: 'CreateInformationService',
        component: () => import('../views/Admin/contacts/CreateInformationService')
      },
      {
        path: 'connect_contacts/internet_reception/edit',
        name: 'CreateInformationService',
        component: () => import('../views/Admin/contacts/InternetReceptionCreate')
      },
      {
        path: 'general_editing_doc/:id',
        name: 'GeneralEditingDocument',
        component: () => import('../views/Admin/GeneralEditingDocument')
      },
      {
        path: 'unit_type',
        name: 'unitType',
        component: () => import('../views/Admin/summaryData/unitType/unitType')
      },
      {
        path: 'nomination',
        name: 'Nomination',
        component: () => import('../views/Admin/myProject/Nomination')
      },
      {
        path: 'unit_type/create',
        name: 'createUnitType',
        component: () => import('../views/Admin/summaryData/unitType/CreateTypeUnit')
      },
      {
        path: 'nomination/create',
        name: 'createNomination',
        component: () => import('../views/Admin/myProject/CreateNomination')
      },
      {
        path: 'nomination/:id/edit',
        name: 'EditNomination',
        component: () => import('../views/Admin/myProject/CreateNomination'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'additional_materials',
        name: 'AdditionalMaterial',
        component: () => import('../views/Admin/myProject/AdditionalMaterial')
      },
      {
        path: 'additional_materials/create',
        name: 'createAdditionalMaterial',
        component: () => import('../views/Admin/myProject/CreateAdditionalMaterial')
      },
      {
        path: 'additional_materials/:id/edit',
        name: 'EditAdditionalMaterial',
        component: () => import('../views/Admin/myProject/CreateAdditionalMaterial'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'logs',
        name: 'Logs',
        component: () => import('../views/Admin/Log')
      },

      {
        path: 'section',
        name: 'Section',
        component: () => import('../views/Admin/sections/Section')
      },
      {
        path: 'section/create',
        name: 'CreateSection',
        component: () => import('../views/Admin/sections/CreateSection')
      },
      {
        path: 'section/:id/edit',
        name: 'EditSection',
        component: () => import('../views/Admin/sections/CreateSection'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'page',
        name: 'Page',
        component: () => import('../views/Admin/sections/Page')
      },
      {
        path: 'page/create',
        name: 'CreatePage',
        component: () => import('../views/Admin/sections/CreatePage')
      },
      {
        path: 'page/:id/edit',
        name: 'EditPage',
        component: () => import('../views/Admin/sections/CreatePage'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'eng_flag',
        name: 'EngFlag',
        component: () => import('../views/Admin/myProject/EngFlag')
      },
      {
        path: 'eng_flag/create',
        name: 'createEngFlag',
        component: () => import('../views/Admin/myProject/CreateEngFlag')
      },
      {
        path: 'eng_flag/:id/edit',
        name: 'EditEngFlag',
        component: () => import('../views/Admin/myProject/CreateEngFlag'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'about_the_ward',
        name: 'AboutTheWard',
        component: () => import('../views/Admin/aboutTheWard/AboutTheWard')
      },
      {
        path: 'about_the_ward/create',
        name: 'createAboutTheWard',
        component: () => import('../views/Admin/aboutTheWard/CreateAboutTheWard')
      },
      {
        path: 'about_the_ward/:id/edit',
        name: 'EditAboutTheWard',
        component: () => import('../views/Admin/aboutTheWard/CreateAboutTheWard'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'project_appeal_texts',
        name: 'ProjectAppealText',
        component: () => import('../views/Admin/myProject/ProjectAppealText')
      },
      {
        path: 'project_appeal_texts/create',
        name: 'createProjectAppealText',
        component: () => import('../views/Admin/myProject/CreateProjectAppealText')
      },
      {
        path: 'project_appeal_texts/:id/edit',
        name: 'EditProjectAppealText',
        component: () => import('../views/Admin/myProject/CreateProjectAppealText'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'project_principles',
        name: 'ProjectPrinciple',
        component: () => import('../views/Admin/myProject/ProjectPrinciple')
      },
      {
        path: 'project_principles/create',
        name: 'createProjectPrinciple',
        component: () => import('../views/Admin/myProject/CreateProjectPrinciple')
      },
      {
        path: 'project_principles/:id/edit',
        name: 'EditProjectPrinciple',
        component: () => import('../views/Admin/myProject/CreateProjectPrinciple'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'project_appeal_periods',
        name: 'ProjectPeriod',
        component: () => import('../views/Admin/myProject/Period')
      },
      {
        path: 'project_appeal_periods/create',
        name: 'CreateProjectPeriod',
        component: () => import('../views/Admin/myProject/CreatePeriod')
      },
      {
        path: 'project_appeal_periods/:id/edit',
        name: 'EditProjectPeriod',
        component: () => import('../views/Admin/myProject/CreatePeriod'),
        props: {
          isEdit: true
        }
      },

      {
        path: 'main_information',
        name: 'MainInformation',
        component: () => import('../views/Admin/myProject/MainInformation')
      },
      {
        path: 'main_information/create',
        name: 'CreateMainInformation',
        component: () => import('../views/Admin/myProject/CreateMainInformation')
      },
      {
        path: 'main_information/:id/edit',
        name: 'EditMainInformation',
        component: () => import('../views/Admin/myProject/CreateMainInformation'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'project_title_links',
        name: 'CreateProjectTitleLink',
        component: () => import('../views/Admin/myProject/ProjectTitleLink')
      },
      {
        path: 'project_title_links/create',
        name: 'CreateProjectTitleLink',
        component: () => import('../views/Admin/myProject/CreateProjectTitleLink')
      },
      {
        path: 'project_title_links/:id/edit',
        name: 'EditProjectTitleLink',
        component: () => import('../views/Admin/myProject/CreateProjectTitleLink'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'index_tags',
        name: 'IndexTag',
        component: () => import('../views/Admin/news/IndexTag')
      },
      {
        path: 'index_tags/create',
        name: 'createIndexTag',
        component: () => import('../views/Admin/news/CreateIndexTag')
      },
      {
        path: 'index_tags/:id/edit',
        name: 'EditIndexTag',
        component: () => import('../views/Admin/news/CreateIndexTag'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'project_email',
        name: 'ProjectEmail',
        component: () => import('../views/Admin/myProject/ProjectEmail')
      },
      {
        path: 'project_email/create',
        name: 'CreateProjectEmail',
        component: () => import('../views/Admin/myProject/CreateProjectEmail')
      },
      {
        path: 'project_email/:id/edit',
        name: 'EditProjectEmail',
        component: () => import('../views/Admin/myProject/CreateProjectEmail'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'rubric',
        name: 'Rubric',
        component: () => import('../views/Admin/rubric/Rubrics')
      },
      {
        path: 'rubric/create',
        name: 'createRubric',
        component: () => import('../views/Admin/rubric/CreateRubric')
      },
      {
        path: 'rubric/:id/edit',
        name: 'editRubric',
        component: () => import('../views/Admin/rubric/CreateRubric'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'subject',
        name: 'Subject',
        component: () => import('../views/Admin/subject/Subjects')
      },
      {
        path: 'subject/create',
        name: 'createSubject',
        component: () => import('../views/Admin/subject/CreateSubject')
      },
      {
        path: 'subject/:id/edit',
        name: 'editSubject',
        component: () => import('../views/Admin/subject/CreateSubject'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'public_council_banner',
        name: 'PublicCouncilBanner',
        component: () => import('../views/Admin/publicCouncilBanner/PublicCouncilBanners')
      },
      {
        path: 'public_council_banner/create',
        name: 'createPublicCouncilBanner',
        component: () => import('../views/Admin/publicCouncilBanner/CreatePublicCouncilBanner')
      },
      {
        path: 'public_council_banner/:id/edit',
        name: 'editPublicCouncilBanner',
        component: () => import('../views/Admin/publicCouncilBanner/CreatePublicCouncilBanner'),
        props: {
          isEdit: true
        }
      },
      // ///////////
      {
        path: 'key_dates',
        name: 'KeyDates',
        component: () => import('../views/Admin/myProject/KeyDates')
      },
      {
        path: 'key_dates/create',
        name: 'createKeyDates',
        component: () => import('../views/Admin/myProject/CreateKeyDates')
      },
      {
        path: 'key_dates/:id/edit',
        name: 'EditKeyDates',
        component: () => import('../views/Admin/myProject/CreateKeyDates'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'email_subscription',
        name: 'emailSubscription',
        component: () => import('../views/Admin/emailSubscription/email/Email')
      },
      {
        path: 'project_evaluation',
        name: 'projectEvaluation',
        component: () => import('../views/Admin/myProject/ProjectEvaluation')
      },
      {
        path: 'project_evaluation/create',
        name: 'createProjectEvaluation',
        component: () => import('../views/Admin/myProject/CreateProjectEvaluation')
      },
      {
        path: 'project_evaluation/:id/edit',
        name: 'EditProjectEvaluation',
        component: () => import('../views/Admin/myProject/CreateProjectEvaluation'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'social_network',
        name: 'SocialNetwork',
        component: () => import('../views/Admin/contacts/SocialNetwork')
      },
      {
        path: 'social_network/create',
        name: 'CreateSocialNetwork',
        component: () => import('../views/Admin/contacts/SocialNetworkCreate')
      },
      {
        path: 'social_network/:id/edit',
        name: 'EditSocialNetwork',
        component: () => import('../views/Admin/contacts/SocialNetworkCreate'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'contact_information_onk',
        name: 'ContactInformationOnk',
        component: () => import('../views/Admin/active/onkContactInformation/ContactInformationOnk')
      },
      {
        path: 'contact_information_onk/edit',
        name: 'EditContactInformationOnk',
        component: () => import('../views/Admin/active/onkContactInformation/EditContactInformationOnk')
      },
      // //////
      {
        path: 'hotline_info',
        name: 'HotlineInfo',
        component: () => import('../views/Admin/homePage/HotlineInfo')
      },
      {
        path: 'hotline_info/create',
        name: 'CreateHotlineInfo',
        component: () => import('../views/Admin/homePage/CreateHotlineInfo')
      },
      {
        path: 'hotline_info/:id/edit',
        name: 'EditHotlineInfo',
        component: () => import('../views/Admin/homePage/CreateHotlineInfo'),
        props: {
          isEdit: true
        }
      },
      {
        path: 'feedback_hotline',
        name: 'FeedbackHotline',
        component: () => import('../views/Admin/contacts/FeedbackHotline')
      },
      {
        path: 'feedback_hotline/create',
        name: 'CreateFeedbackHotline',
        component: () => import('../views/Admin/contacts/CreateFeedbackHotline')
      },
      {
        path: 'status_bill',
        name: 'StatusBill',
        component: () => import('../views/Admin/statusBill/StatusBill')
      },
      {
        path: 'status_bill/create',
        name: 'CreateStatusBill',
        component: () => import('../views/Admin/statusBill/CreateStatusBill')
      },
      {
        path: 'status_bill/:id/edit',
        name: 'EditStatusBill',
        component: () => import('../views/Admin/statusBill/CreateStatusBill'),
        props: {
          isEdit: true
        }
      }
      // feedback hotline
    ]
  },
  {
    // Лк блог
    path: '/blog',
    name: 'blog',
    component: () => import('../views/Blog/index')
  },
  {
    // Лк блог- создание
    path: '/create_blog',
    name: 'create_blog',
    component: () => import('../views/Blog/CreateBlog')
  },
  {
    // Лк блог детальный просмотр
    path: '/detail_blog/:id',
    name: 'DetailBlog',
    component: () => import('../views/Blog/DetailBlog')
  },
  {
    // Лк блог модератор
    path: '/moderation_appeal',
    name: 'ModerationAppeal',
    component: () => import('../views/Appeal/ModerationAppeal')
  },
  {
    // Лк блог модератор
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration')
  },
  {
    // Проекты. Мой проект - моей стране
    path: '/my-project',
    name: 'MyProject',
    component: () => import('../views/Project/MyProject')
  },
  {
    // Проекты. Личный кабинет
    path: '/personal-account',
    name: 'PersonalAccount',
    component: () => import('../views/Project/PersonalAccount'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token') !== null) {
        return next()
      } else {
        store.commit('setMessage', {
          type: 'error',
          text: 'Необходимо авторизироваться'
        })
        return next({
          path: '/'
        })
      }
    }
  },
  {
    // Проекты. Отправить заявку
    path: '/project-submit-appeal',
    name: 'ProjectSubmitAppeal',
    // component: () => import('../views/Index'),
    component: () => import('../views/Project/SubmitAppeal'),
    props: {
      alert: 'Преим заявок окончен'
    }
  },
  {
    // Проекты. Редактировать заявку
    path: '/project-submit-appeal/:id/edit',
    name: 'ProjectSubmitAppeal',
    // component: () => import('../views/Index'),
    component: () => import('../views/Project/SubmitAppeal'),
    props: {
      isEdit: true,
      alert: 'Преим заявок окончен'
    }
  },
  {
    // Проекты. Карта проекта
    path: '/project-map/:id',
    name: 'ProjectMap',
    component: () => import('../views/Project/ProjectMap')
  },
  {
    // Проекты. Картотека проектов
    path: '/projects-list',
    name: 'ProjectsList',
    component: () => import('../views/Project/ProjectsList')
  },
  {
    // Связаться. для СМИ
    path: '/for_media',
    name: 'ForMedia',
    component: () => import('../views/Contact/ForMedia')
  },
  // {
  //   // ЛК. Редактирование профиля
  //   path: '/profile_editing',
  //   name: 'ProfileEditing',
  //   component: () => import('../views/Project/ProfileEditing')
  // },
  {
    // общественные советы
    path: '/public_councils',
    name: 'PublicCouncils',
    component: () => import('../views/Activity/PublicCouncils')
  },
  {
    path: '/formation_councils/:id',
    name: 'FormationCouncilsDetail',
    component: () => import('../views/Activity/FormationCouncilsDetail')
  },
  {
    // общественные советы . Часто задаваемые вопросы
    path: '/public_councils_faq',
    name: 'PublicCouncilsFAQ',
    component: () => import('../views/Activity/PublicCouncilsFAQ')
  },
  {
    path: '/about_the_chamber/:id',
    name: 'AboutTheWardDetail',
    component: () => import('../views/AboutTheWard')
  },
  {
    path: '*',
    name: 'Index',
    component: () => import('../views/Index')
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  document.title = 'Общественная палата Российской Федерации'
  next()
})

export default router
