import router from '@/router'

export default {
  state: {
    blockHotLine: [],
    blockHotLineDetails: []
  },
  mutations: {
    setBlockHotLine (state, payload) {
      state.blockHotLine = payload
    },
    setBlockHotLineDetails (state, payload) {
      state.blockHotLineDetails = payload
    }
  },
  actions: {
    async setBlockHotLine (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/index-page`)
      res = await res.json()
      this.commit('setBlockHotLine', await res.data)
    },

    async setBlockHotLineDetails (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/index-page/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setBlockHotLineDetails', await res.data)
    },

    async deleteBlockHotLine (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/index-page/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Блок успешно удален'
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('setBlockHotLine')
      })
        .catch(error => console.log('error', error))
    },

    async createBlockHotLine (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/index-page`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/hotline_info')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание блока прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editBlockHotLine (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/index-page/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/hotline_info')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение блока прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getBlockHotLine: (s) => s.blockHotLine,
    getBlockHotLineDetails: (s) => s.blockHotLineDetails
  }
}
