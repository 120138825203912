<template>
  <div class="main-search">
    <v-input-search class="main-search__input" @closeHeaderSearch="$emit('closeHeaderSearch')" @click="$emit('click')" placeholder="Введите слово для поиска"/>
  </div>
</template>

<script>
import VInputSearch from './VInputSearch'
export default {
  name: 'VSearch',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      // search: ''
    }
  },
  computed: {
    // getMemberInfo () {
    //   return this.$store.getters.GET_MEMBER
    // }
  },
  methods: {
    // clickDetails () {
    //   this.isDetails = !this.isDetails
    // }
  },
  components: {
    'v-input-search': VInputSearch
  }
}
</script>

<style scoped lang="scss">
  .main-search {
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 5px 15px rgba(189, 205, 221, 0.35);

    &__input {
      padding: 7rem 0 1.875rem 0;
      width: 85.37rem;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 768px){
    .main-search {
      &__input {
        width: 92%;
      }
    }
  }
  @media screen and (max-width: 420px){
    .main-search {
      display: none;
    }
  }
</style>
