import router from '@/router'

export default {
  state: {
    nkoPartners: [],
    detailNkoPartners: null
  },
  mutations: {
    setNkoPartners (state, payload) {
      state.nkoPartners = payload
    },
    setDetailNkoPartners (state, payload) {
      state.detailNkoPartners = payload
    }
  },
  actions: {
    async setNkoPartners (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/onk-partners`)
      res = await res.json()
      ctx.commit('setNkoPartners', await res.data)
    },
    async AddNkoPartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/onk-partners`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.ok) {
            ctx.dispatch('setNkoPartners')
            router.push('/admin/nko_partners')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание документа прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteNkoPartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/onk-partners/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setNkoPartners')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Партнер удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailNkoPartners (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/onk-partners/${payload}`)
      res = await res.json()
      console.log(await res.data)
      ctx.commit('setDetailNkoPartners', await res.data)
      await router.push('/admin/nko_partners/create?type=edit')
    },
    async editNkoPartners (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/onk-partners/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/nko_partners')
            ctx.dispatch('setNkoPartners')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getNkoPartners (s) {
      return s.nkoPartners
    },
    getDetailNkoPartners (s) {
      return s.detailNkoPartners
    }
  }
}
