import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'
export default {
  state: {
    commissionUpdate: [],
    statusCommissionUpdate: [],
    detailCommissionUpdate: null,
    commissionTotal: 0
  },
  mutations: {
    setCommissionUpdate (state, payload) {
      state.commissionUpdate = payload
    },
    setCommissionTotal (state, payload) {
      state.commissionTotal = payload
    },
    setStatusCommissionUpdate (state, payload) {
      state.statusCommissionUpdate = payload
    },
    setDetailCommissionUpdate (state, payload) {
      state.detailCommissionUpdate = payload
    }
  },
  actions: {
    async setCommissionUpdate (ctx, payload) {
      let pagination = ''

      if (payload && payload.offset) {
        pagination = `?offset=${payload.offset}`
      }

      if (payload && payload.limit) {
        pagination += pagination ? `&limit=${payload.limit}` : `?limit=${payload.limit}`
      } else {
        pagination = '?limit=1000'
      }

      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdate${pagination}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.debug(await res)
      console.log(await res)
      ctx.commit('setCommissionUpdate', await res.data.commissionUpdates)
      ctx.commit('setCommissionTotal', await res.data.total)
    },
    async setFilterCommissionUpdate (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/admin/commissionUpdate`, payload)
      let res = await fetch(url, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setCommissionUpdate', await res.data.commissionUpdates)
    },
    async setStatusCommissionUpdate (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdateTypes`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setStatusCommissionUpdate', await res.data)
    },
    async AddCommissionUpdate (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdate`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCommissionUpdate')
            router.push('/admin/commissionUpdate')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteCommissionUpdate (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdate/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCommissionUpdate')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение удалено'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailCommissionUpdate (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdate/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailCommissionUpdate', await res.data[0])
      await router.push('/admin/commissionUpdate/create?type=edit')
    },
    async setCurrentCommissionUpdat (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/bill-draft/${payload}`)
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailBillDraft', await res.data)
      // await router.push('/admin/bill_draft/create?type=edit')
    },
    async editCommissionUpdate (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/commissionUpdate/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/commissionUpdate')
            ctx.dispatch('setCommissionUpdate')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getCommissionUpdate (s) {
      return s.commissionUpdate
    },
    getStatusCommissionUpdate (s) {
      return s.statusCommissionUpdate
    },
    getDetailCommissionUpdate (s) {
      return s.detailCommissionUpdate
    },
    getCommissionTotal (s) {
      return s.commissionTotal
    }
  }
}
