export default {
  state: {
    publicBlogs: []
  },

  mutations: {
    setPublicBlogs (state, payload) {
      state.publicBlogs = payload
    }
  },

  actions: {
    async getPublicBlogs (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/main-page/blogs${ctx.getters.getPaginationParams}${ctx.getters.getFilters}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
      res = await res.json()
      this.commit('setPublicBlogs', await res.data)
      this.commit('setPagination', await res.meta)
    }
  },

  getters: {
    getPublicBlogs: (s) => s.publicBlogs
  }
}
