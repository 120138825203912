import router from '@/router'

export default {
  state: {
    projectPrinciple: [],
    detailedProjectPrinciple: []
  },
  mutations: {
    setProjectPrinciple (state, payload) {
      state.projectPrinciple = payload
    },
    setDetailedProjectPrinciple (state, payload) {
      state.detailedProjectPrinciple = payload
    }
  },
  actions: {
    async setProjectPrinciple (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/project_principles`)
      res = await res.json()
      this.commit('setProjectPrinciple', await res)
    },

    async setDetailedProjectPrinciple (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/project_principles/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setDetailedProjectPrinciple', await res)
    },

    async deleteProjectPrinciple (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_principles/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Принцип успешно удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setProjectPrinciple')
      })
        .catch(error => console.log('error', error))
    },

    async createProjectPrinciple (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_principles`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/project_principles')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание принципа прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editProjectPrinciple (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_principles/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/project_principles')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение принципа прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getProjectPrinciple: (s) => s.projectPrinciple,
    getDetailedProjectPrinciple: (s) => s.detailedProjectPrinciple
  }
}
