export default {
  state: {
    list_template: [],
    template: {}
  },

  mutations: {
    SET_TEMPLATES (state, payload) {
      state.list_template = payload
    },
    SET_TEMPLATE (state, payload) {
      state.template = payload
    }

  },

  actions: {
    getListTemplate ({ commit }, payload) {
      const templateRegistry = [
        {
          id: '1',
          name: 'Опрос "Комплексное развитие сельских территорий"',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        },
        {
          id: '2',
          name: 'Опрос законопроект',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        },
        {
          id: '3',
          name: 'Форма регистрации на форум Сообщество',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        },
        {
          id: '4',
          name: 'Форма сбора комментариев',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        },
        {
          id: '5',
          name: 'Опрос законопроект',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        },
        {
          id: '6',
          name: 'Форма регистрации на форум Сообщество',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        },
        {
          id: '7',
          name: 'Форма сбора комментариев',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        },
        {
          id: '8',
          name: 'Опрос законопроект',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        },
        {
          id: '9',
          name: 'Форма регистрации на форум Сообщество',
          date_publication: '10.04.2020 17:15',
          date_change: '11.04.2020 17:15',
          content: '<iframe src="https://forms.yandex.ru/u/5f8890b16c7ed60aa40b37c5/?iframe=1" frameborder="0" name="ya-form-5f8890b16c7ed60aa40b37c5" width="100%"></iframe>'
        }
      ]
      commit('SET_TEMPLATES', templateRegistry)
    },
    getTemplate ({ commit, state }, payload) {
      const temp = state.list_template.filter(el => {
        return Number(el.id) === Number(payload)
      })
      commit('SET_TEMPLATE', temp[0])
    }
  },

  getters: {
    GET_TEMPLATES: state => state.list_template,
    GET_TEMPLATE: state => state.template
  }
}
