import router from '@/router'

export default {

  state: {
    liveStreams: [],
    adminLiveStream: null,

    adminLiveStreamSearch: {
      key: null,
      date_start: null,
      date_end: null,
      rubric: null
    },
    adminLiveStreamPagination: {
      offset: 0,
      limit: 9,
      count: 0,
      total: 0
    }
  },

  mutations: {
    setLiveStreams (state, payload) {
      state.liveStreams = payload
    },

    setAdminLiveStream (state, payload) {
      state.adminLiveStream = payload
    },

    setAdminLiveStreamSearch (state, payload) {
      state.adminLiveStreamSearch = payload
    },

    setAdminLiveStreamPaginationOffset (state, payload) {
      state.adminLiveStreamPagination.offset = payload
    },

    setAdminLiveStreamPagination (state, payload) {
      state.adminLiveStreamPagination = {
        offset: payload.offset,
        limit: payload.limit,
        count: payload.count,
        total: payload.total
      }
    }
  },

  actions: {

    async getAdminLiveStream (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/live_stream/${payload}`, {
        method: 'GET',
        headers: {
          Localization: 'admin'
        }
      })
      res = await res.json()
      this.commit('setAdminLiveStream', await res)
    },

    async getLiveStreams (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/live_stream${ctx.getters.getAdminLiveStreamPaginationParams}${ctx.getters.getAdminLiveStreamSearch}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setAdminLiveStreamPagination', await res)
      this.commit('setLiveStreams', await res.live_stream)
    },

    async deleteLiveStream (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/live_stream/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: `Прямая трансляция №${payload} успешно удалена`
          })
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Ошибка удаления'
          })
        }
        ctx.dispatch('getLiveStreams')
      })
        .catch(error => console.log('error', error))
    },

    async createLiveStream (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/live_stream`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/live_stream')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание прямой трансляции прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async updateLiveStream (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/live_stream/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/live_stream')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение прямой трансляции прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },

  getters: {
    getLiveStreams: (s) => s.liveStreams,
    getAdminLiveStreamSearch: (s) => {
      let queryString = ''

      for (const key in s.adminLiveStreamSearch) {
        if (s.adminLiveStreamSearch[key]) {
          queryString += `&${key}=${s.adminLiveStreamSearch[key]}`
        }
      }

      return queryString
    },
    getAdminLiveStreamPaginationParams: s => {
      return `?offset=${s.adminLiveStreamPagination.offset}&limit=${s.adminLiveStreamPagination.limit}`
    },
    getAdminLiveStreamPagination: (s) => s.adminLiveStreamPagination,
    getAdminLiveStream: (s) => s.adminLiveStream
  }

}
