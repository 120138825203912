import router from '@/router'

export default {
  state: {
    projectEmail: [],
    detailedProjectEmail: []
  },
  mutations: {
    setProjectEmail (state, payload) {
      state.projectEmail = payload
    },
    setDetailedProjectEmail (state, payload) {
      state.detailedProjectEmail = payload
    }
  },
  actions: {
    async setProjectEmail (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/project_email`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setProjectEmail', await res)
    },

    async setDetailedProjectEmail (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/project_email/${payload}`)
      res = await res.json()
      this.commit('setDetailedProjectEmail', await res)
    },

    async deleteProjectEmail (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_email/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Эл. почта успешно удалена'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setProjectEmail')
      })
        .catch(error => console.log('error', error))
    },

    async createProjectEmail (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_email`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/project_email')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание эл. почты прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editProjectEmail (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/project_email/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/project_email')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение эл. почты прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getProjectEmail: (s) => s.projectEmail,
    getDetailedProjectEmail: (s) => s.detailedProjectEmail
  }
}
