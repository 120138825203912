import router from '@/router'

export default {
  state: {
    currentPost: null,
    listPosts: [],
    tags: []
  },

  mutations: {
    SET_CURRENT_POST (state, payload) {
      state.currentPost = payload
    },
    SET_LIST_POSTS (state, payload) {
      state.listPosts = payload
    }
  },

  actions: {
    /*
    request: id-member
    response: all posts
    */
    apiMemberPost ({ commit }, payload) {
      fetch(`${this.getters.getUrlApi}api/member/${payload.id}/posts${payload.isPublic ? '?nado=PUBLISHED' : ''}${payload.limit ? '&limit=' + payload.limit : ''}${payload.offset ? '&offset=' + payload.offset : ''}`, {
        headers: {
          Localization: this.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              commit('SET_LIST_POSTS', data.data)
            })
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },
    /*
    request: { id_member, id_post }
    response: current posts
    */
    apiDetailMemberPost ({ commit }, payload) {
      fetch(`${this.getters.getUrlApi}api/member/${payload.id_member}/posts/${payload.id_post}`)
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              commit('SET_CURRENT_POST', data.data)
            })
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },
    /*
    request: idMember
    response: status added post
    */
    async apiAddPost (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/user/blog`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
          // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // ctx.dispatch('setBillDraft', ctx.getters.getUrlApi)
            console.log(payload)
            router.push(`/blog?idUser=${payload.idMember}`)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание поста для блога прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    /*
    request: { data, idMember, idPost }
    response: status  add updated post
    */
    async apiAUpdatePost (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/user/posts/${payload.idPost}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            // ctx.dispatch('setBillDraft', ctx.getters.getUrlApi)
            router.push(`/blog?idUser=${payload.idMember}`)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Обновление поста для блога прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    /*
    request: idMember
    response: status deleted post
    */
    async deletePost (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/user/posts/${payload.idPost}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Пост был удален'
            })
            router.push(`/blog?idUser=${payload.idMember}`)
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    /*
    request: { idPost, statusPost, idMember }
    response: status change status post
    */
    async changeStatus (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/user/posts/${payload.idPost}?status=${payload.statusPost}`, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('apiMemberPost', 69)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Статус пост обновлён'
            })
            ctx.dispatch('apiDetailMemberPost', { id_member: payload.idMember, id_post: payload.idPost })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    GET_CURRENT_POST: state => state.currentPost,
    // getImportantPublicNews: state => {
    //   return state.publicNews.filter((news) => news.important)
    // },
    GET_LIST_POSTS: state => state.listPosts
  }
}
