import router from '@/router'
export default {
  state: {
    infoNko: [],
    statusInfoNko: [],
    detailInfoNko: null
  },
  mutations: {
    setInfoNko (state, payload) {
      state.infoNko = payload
    },
    setStatusInfoNko (state, payload) {
      state.statusInfoNko = payload
    },
    setDetailInfoNko (state, payload) {
      state.detailInfoNko = payload
    }
  },
  actions: {
    async setInfoNko (ctx) {
      const prepareArray = []
      let res = await fetch(`${ctx.getters.getUrlApi}api/regional-public-сhamber`)
      res = await res.json()
      await res.data.map(item => {
        item.data.map(obj => {
          prepareArray.push(obj)
        })
      })
      console.log(await prepareArray)
      ctx.commit('setInfoNko', await prepareArray)
    },
    async setStatusInfoNko (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/directory/federal-district`)
      res = await res.json()
      ctx.commit('setStatusInfoNko', await res.data)
    },
    async AddInfoNko (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/regional-public-сhamber`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setInfoNko')
            router.push('/admin/info_nko')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteInfoNko (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/regional-public-сhamber/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setInfoNko')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение удалено'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailInfoNko (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/regional-public-сhamber/${payload}`)
      res = await res.json()
      console.log(await res.data)
      ctx.commit('setDetailInfoNko', await res.data)
      await router.push('/admin/info_nko/create?type=edit')
    },
    async editInfoNko (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/regional-public-сhamber/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/info_nko')
            ctx.dispatch('setInfoNko')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getInfoNko (s) {
      return s.infoNko
    },
    getStatusInfoNko (s) {
      return s.statusInfoNko
    },
    getDetailInfoNko (s) {
      return s.detailInfoNko
    }
  }
}
