export default {
  state: {
    publicTopics: [],
    currentTopic: null
  },

  mutations: {
    setPublicTopics (state, payload) {
      state.publicTopics = payload
    },

    setCurrentTopic (state, payload) {
      state.currentTopic = payload
    }
  },

  actions: {
    async getPublicTopics (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/topic`)
      res = await res.json()
      this.commit('setPublicTopics', await res.topic)
    },

    async getCurrentTopic (ctx, id) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/topic/${id}`)
      res = await res.json()
      this.commit('setCurrentTopic', await res)
    }
  },
  /*
  request: { data, idMember, idPost }
  response: status  add updated post
  */
  async apiAddTopic (ctx, payload) {
    await fetch(`${ctx.getters.getUrlApi}api/user/topic/${payload.idPost}`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token')
      },
      body: payload.data
    })
      .then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Комментарий отправлен'
          })
          ctx.dispatch('getCurrentTopic', ctx.getters.getUrlApi)
        } else {
          ctx.commit('setMessage', {
            type: 'error',
            text: 'Произошла ошибка'
          })
        }
      })
  },

  getters: {
    getPublicTopics: (s) => s.publicTopics,
    getCurrentTopic: (s) => s.currentTopic
  }
}
