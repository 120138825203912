<template>
  <main class="main">
    <div class="main-content">
      <main-header :is-index-page="indexPage"/>
      <light-box-image/>
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>

    <main-footer v-if="noMap"/>

    <transition name="fade" mode="out-in">
      <div class="loader-container" v-if="isLoading">
        <loader/>
      </div>
    </transition>
  </main>
</template>

<script>
import MainFooter from '@/components/navigation/MainFooter'
import MainHeader from '@/components/navigation/MainHeader'
import LightBoxImage from './components/news/LightBoxImage'
import Loader from '@/components/Loader'

export default {
  data () {
    return {
      indexPage: false,
      noMap: false,
      isLoading: true
    }
  },
  components: {
    LightBoxImage,
    Loader,
    'main-header': MainHeader,
    'main-footer': MainFooter
  },
  created () {
    if (localStorage.getItem('local') === null) {
      this.$store.dispatch('SET_LANG', true)
    }
  },
  mounted () {
    this.$store.dispatch('SET_LANG', localStorage.getItem('local'))
    this.startComponents()
    window.onload = () => {
      this.isLoading = false
    }
  },
  watch: {
    '$route' () {
      if (this.$route.query.lang && this.$route.query.lang === 'en') {
        this.$store.dispatch('SET_LANG', false)
      }
      this.setLang()
      this.startComponents()
    },
    '$store.getters.getMessage' (value) {
      this.$noty[value.type](value.text)
    },
    '$store.getters.GET_LANG' (value) {
      this.setLang()
    }
  },
  methods: {
    setLang () {
      console.debug(this.$route)
      if (this.$store.getters.GET_LANG === false) {
        let url = this.$route.fullPath
        if (!('lang' in this.$route.query)) {
          url += Object.keys(this.$route.query).length === 0 ? '?lang=en' : '&lang=en'
        }
        history.pushState(
          {},
          null,
          url
        )
      } else {
        const query = Object.assign({}, this.$route.query)
        delete query.lang
        this.$router.replace({ query, hash: this.$route.hash })
      }
    },
    startComponents () {
      this.indexPage = this.$route.fullPath === '/' ? 'true' : false
      if (this.$route.fullPath === '/appeal_map') {
        return (this.noMap = false)
      } else {
        this.noMap = true
      }
      // Почему не работает?
      // this.noMap = this.$route.fullPath === '/appeal_map' ? 'false' : 'true'
    }
  }
}
</script>

<style lang="scss">
  .loader-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1500;
    background-color: white;

    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    width: 100%;

    &-content {
      flex: 1;
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  html {
    scroll-behavior: smooth;
  }
  @import "assets/scss/main";

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    main {
      display: inline-block !important;
    }
  }

</style>
