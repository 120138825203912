export function queryBulder (link, payload) {
  const arrayName = []
  let url = link
  for (const item in payload) {
    if (payload[item]) {
      arrayName.push(item)
    }
  }
  arrayName.map((item, i) => {
    if (i === 0) {
      url += `?${item}=${payload[item]}`
    } else {
      url += `&${item}=${payload[item]}`
    }
  })
  return url
}
