import router from '@/router'

export default {
  state: {
    allComments: [],
    detailCommentAdmin: [],
    allBills: []
  },
  mutations: {
    setAllBill (state, payload) {
      state.allBills = payload
    },
    setAllCommentsBill (state, payload) {
      state.allComments = payload
    },
    setDetailCommentBill (state, payload) {
      state.detailCommentAdmin = payload
    }
  },
  actions: {
    async setAllBill (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/bill-draft`)
      res = await res.json()
      ctx.commit('setAllBill', await res.data.list)
    },
    async setAllCommentsBill (ctx, id) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/bill-draft/${id}/comment`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setAllCommentsBill', await res.data)
    },
    async deleteCommentBill (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/bill-draft/comment/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            // ctx.dispatch('setAllMembersBlog', ctx.getters.getUrlApi)
            // ctx.dispatch('setSpecificMembersBlogs', payload.allBlog)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Блог удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailCommentBill (ctx, payload) {
      // детальная информация комментарий
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/bill-draft/comment/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setDetailCommentBill', await res.data)
    },
    async updateCommemtBill (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/bill-draft/comment/${payload[1]}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/comments_bill')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Редактирование комментария прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async createCommemtBill (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/bill-draft/${payload[1]}/comment`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/comments_bill')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание комментария прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getAllBill (s) {
      return s.allBills
    },
    getAllCommentsBill (s) {
      return s.allComments
    },
    getDetailCommentBill (s) {
      return s.detailCommentAdmin
    }
  }
}
