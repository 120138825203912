import router from '@/router'

export default {
  state: {
    apparatusDocuments: [],
    detailDocuments: null
  },
  mutations: {
    setApparatusDocuments (state, payload) {
      state.apparatusDocuments = payload
    },
    setDetailApparatusDocuments (state, payload) {
      state.detailDocuments = payload
    }
  },
  actions: {
    async setApparatusDocuments (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/apparat-document`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setApparatusDocuments', await res.data)
    },
    async setDetailApparatusDocuments (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/apparat-document/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setDetailApparatusDocuments', await res.data)
      await router.push('/admin/apparatus/create?type=edit')
    },
    async createApparatusDocuments (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/apparat-document`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/apparatus')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteApparatusDocuments (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/apparat-document/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setApparatusDocuments')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Документ была удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editApparatusDocuments (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/apparat-document/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/apparatus')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getApparatusDocuments (s) {
      return s.apparatusDocuments
    },
    getDetailApparatusDocuments (s) {
      return s.detailDocuments
    }
  }
}
