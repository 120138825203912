import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'
export default {
  state: {
    convocation: [],
    detailConvocation: null
  },
  mutations: {
    setConvocation (state, payload) {
      state.convocation = payload
    },
    setDetailConvocation (state, payload) {
      state.detailConvocation = payload
    }
  },
  actions: {
    async setConvocation (ctx) {
      // год созыва
      let convocation = await fetch(`${ctx.getters.getUrlApi}api/directory/convocation-list`)
      convocation = await convocation.json()
      ctx.commit('setConvocation', await convocation.data.convocation)
    },
    async createConvocation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/convocation`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setConvocation')
            router.push('/admin/convocation_list')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание пользователя прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка, проверьте год созыва'
            })
          }
        })
    },
    async deleteConvocation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/convocation/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setConvocation')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Состав был удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async setDetailConvocation (ctx, payload) {
      // детальная информация члена ОПРФ для редактирования
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/convocation/${payload}`)
      res = await res.json()
      ctx.commit('setDetailConvocation', await res.data)
      await router.push('/admin/convocation_list/create?type=edit')
    },
    async editConvocation (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/admin/convocation/${payload.id}`, payload)
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setConvocation')
            router.push('/admin/convocation_list')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение состава прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getConvocation (s) {
      return s.convocation
    },
    getDetailConvocation (s) {
      return s.detailConvocation
    }
  }
}
