export default {
  state: {
    list_view: true,
    // filter
    activeLetter: '',
    searchFio: '',
    inclusion: '',
    unit: '',
    // filter End
    inclusionList: [],
    unitTypeList: [],
    memberList: null
  },

  mutations: {
    setView (state, payload) {
      state.list_view = payload
    },

    setLetter (state, payload) {
      state.activeLetter = payload
    },

    setSearchFio (state, payload) {
      state.searchFio = payload
    },

    setInclusionList (state, payload) {
      state.inclusionList = payload.data
    },

    setUnitTypeList (state, payload) {
      state.unitTypeList = payload.data
    },

    setMemberList (state, payload) {
      state.memberList = payload.data
    },

    setInclusion (state, payload) {
      state.inclusion = payload
    },

    setUnit (state, payload) {
      state.unit = payload
    }
  },

  actions: {

    setView ({ commit }, payload) {
      commit('setView', payload)
    },

    apiUnitTypeList ({ commit }, payload) {
      fetch(`${this.getters.getUrlApi}api/directory/unit-type-list`)
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              commit('setUnitTypeList', data)
            })
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },

    apiInclusionList ({ commit }, payload) {
      fetch(`${this.getters.getUrlApi}api/admin/directory/inclusion-ground`)
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              commit('setInclusionList', data)
            })
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    },

    apiMemberList (ctx, payload) {
      const queryParamsObj = new URLSearchParams(payload)
      fetch(`${this.getters.getUrlApi}api/member/brief?${queryParamsObj.toString()}`, {
        headers: {
          Localization: ctx.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
        .then(
          function (response) {
            if (response.status !== 200) {
              console.log('Status Code: ' + response.status)
              return
            }
            response.json().then(function (data) {
              ctx.commit('setMemberList', data)
            })
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err)
        })
    }
  },

  getters: {
    getListView: state => state.list_view,
    getLetter: state => state.activeLetter,
    getSearchFio: state => state.searchFio,
    getInclusionList: state => state.inclusionList,
    getUnitTypeList: state => state.unitTypeList,
    getMemberList: state => state.memberList,
    getInclusion: state => state.inclusion,
    getUnit: state => state.unit
  }
}
