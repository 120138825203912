export default {
  state: {
    emailSubscription: []
  },
  mutations: {
    setEmailSubscription (state, payload) {
      state.emailSubscription = payload
    }
  },
  actions: {
    async setEmailSubscription (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/mailing`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      ctx.commit('setEmailSubscription', await res.data)
    },
    async AddEmailSubscription (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/mailing`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.commit('setMessage', {
              type: 'success',
              text: ctx.getters.GET_LANG ? 'Вы подписались на email рассылку' : 'You have subscribed to the email newsletter'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: ctx.getters.GET_LANG ? 'Произошла ошибка' : 'An error has occurred'
            })
          }
        })
    },
    async deleteEmailSubscription (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/delete-mailing/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setEmailSubscription')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Почта удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    }
  },
  getters: {
    getEmailSubscription (s) {
      return s.emailSubscription
    }
  }
}
