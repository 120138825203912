export default {
  state: {
    logs: []
  },
  mutations: {
    setLogs (state, payload) {
      state.logs = payload
    }
  },
  actions: {
    async setLogs (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/event-log`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setLogs', await res)
    }
  },
  getters: {
    getLogs: (s) => s.logs
  }
}
