export default {
  state: {
    url: process.env.API_URL
  },
  mutations: {},
  actions: {},
  getters: {
    getUrl (s) {
      return s.url
    }
  }
}
