export default {
  state: {
    // true - ru
    // false - eng
    lang: true
  },
  mutations: {
    SET_LANG (state, payload) {
      state.lang = payload
    }
  },
  actions: {
    SET_LANG ({ commit }, payload) {
      commit('SET_LANG', payload)
      localStorage.setItem('local', payload)
    }
  },
  getters: {
    GET_LANG: s => s.lang === true || s.lang === 'true'
  }
}
