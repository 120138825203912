import router from '@/router'

export default {
  state: {
    recommendation: [],
    detailRecommendation: null
  },
  mutations: {
    setRecommendation (state, payload) {
      state.recommendation = payload
    },
    setDetailRecommendation (state, payload) {
      state.detailRecommendation = payload
    }
  },
  actions: {
    async seRecommendation (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/unit/${payload}/recommendation`)
      res = await res.json()
      console.log(await res)
      ctx.commit('setRecommendation', await res.data)
    },
    async setDetailRecommendation (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/unit/recommendation/${payload}`)
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailRecommendation', await res.data)
      await router.push('/admin/recommendation/create?type=edit')
    },
    async createRecommendation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/unit/${payload.id}/recommendation`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/recommendation')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание рекомендации прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteRecommendation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/unit/recommendation/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            location.reload()
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Рекомендация была удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editRecommendation (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/unit/recommendation/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/recommendation')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение рекомендации прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getRecommendation (s) {
      return s.recommendation
    },
    getDetailRecommendation (s) {
      return s.detailRecommendation
    }
  }
}
