export default {
  state: {
    api: 'https://files.oprf.ru/'
  },
  mutations: {},
  actions: {},
  getters: {
    getUrlApi (s) {
      return s.api
    }
  }
}
