import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'

export default {
  state: {
    unitType: [],
    detailUnitType: null
  },
  mutations: {
    setUnitType (state, payload) {
      state.unitType = payload
    },
    setDetailUnitType (state, payload) {
      state.detailUnitType = payload
    }
  },
  actions: {
    async setUnitType (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/directory/unit-type-list`)
      res = await res.json()
      ctx.commit('setUnitType', await res.data)
    },
    async setDetailUnitType (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/directory/unit-type/${payload}`)
      res = await res.json()
      ctx.commit('setDetailUnitType', await res.data)
      await router.push('/admin/unit_type/create?type=edit')
    },
    async editUnitType (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/admin/unit-type/${payload.id}`, payload)
      await fetch(url, {
        method: 'PUT',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/unit_type')
            ctx.dispatch('setUnitType')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация о типе подразделения успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getUnitType (s) {
      return s.unitType
    },
    getDetailUnitType (s) {
      return s.detailUnitType
    }
  }
}
