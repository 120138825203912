import router from '@/router'

export default {
  state: {
    additionalMaterial: [],
    detailedAdditionalMaterial: []
  },
  mutations: {
    setAdditionalMaterial (state, payload) {
      state.additionalMaterial = payload
    },
    setDetailedAdditionalMaterial (state, payload) {
      state.detailedAdditionalMaterial = payload
    }
  },
  actions: {
    async setAdditionalMaterial (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/additional_materials`)
      res = await res.json()
      this.commit('setAdditionalMaterial', await res)
    },

    async setDetailedAdditionalMaterial (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/additional_materials/${payload}`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      this.commit('setDetailedAdditionalMaterial', await res)
    },

    async deleteAdditionalMaterial (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/additional_materials/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      }).then(res => {
        if (res.status === 200) {
          ctx.commit('setMessage', {
            type: 'success',
            text: 'Дополнительный материал успешно удален'
          })
        } else {
          ctx.commit('setMessage', { type: 'error', text: 'Ошибка удаления' })
        }
        ctx.dispatch('setAdditionalMaterial')
      })
        .catch(error => console.log('error', error))
    },

    async createAdditionalMaterial (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/additional_materials`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            router.push('/admin/additional_materials')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание дополнительного материала прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    },

    async editAdditionalMaterial (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/additional_materials/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/additional_materials')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение дополнительного материала прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Ошибка создания'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getAdditionalMaterial: (s) => s.additionalMaterial,
    getDetailedAdditionalMaterial: (s) => s.detailedAdditionalMaterial
  }
}
