import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'
export default {
  state: {
    billDraft: [],
    statusBillDraft: [],
    detailBillDraft: null
  },
  mutations: {
    setBillDraft (state, payload) {
      state.billDraft = payload
    },
    setStatusBillDraft (state, payload) {
      state.statusBillDraft = payload
    },
    setDetailBillDraft (state, payload) {
      state.detailBillDraft = payload
    }
  },
  actions: {
    async setBillDraft (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/bill-draft`)
      res = await res.json()
      ctx.commit('setBillDraft', await res.data.list)
    },
    async setFilterBillDraft (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/bill-draft`, payload)
      let res = await fetch(url)
      res = await res.json()
      ctx.commit('setBillDraft', await res.data.list)
    },
    async setStatusBillDraft (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/bill-draft-state`)
      res = await res.json()
      ctx.commit('setStatusBillDraft', await res.data)
    },
    async AddBillDraft (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/bill-draft`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setBillDraft', ctx.getters.getUrlApi)
            router.push('/admin/bill_draft')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание законопроекта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteBillDraft (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/bill-draft/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setBillDraft')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Законопроект удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailBillDraft (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/bill-draft/${payload}`)
      res = await res.json()
      ctx.commit('setDetailBillDraft', await res.data)
      await router.push('/admin/bill_draft/create?type=edit')
    },
    // Получение детальной новости без перехода в админку
    async setCurrentBillDraft (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/bill-draft/${payload}`)
      res = await res.json()
      ctx.commit('setDetailBillDraft', await res.data)
      // await router.push('/admin/bill_draft/create?type=edit')
    },
    async editBillDraft (ctx, payload) {
      const url = `${ctx.getters.getUrlApi}api/admin/bill-draft/${payload.id}`
      await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/bill_draft')
            ctx.dispatch('setBillDraft', ctx.getters.getUrlApi)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация о законопроекте успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getBillDraft (s) {
      return s.billDraft
    },
    getStatusBillDraft (s) {
      return s.statusBillDraft
    },
    getDetailBillDraft (s) {
      return s.detailBillDraft
    }
  }
}
