import router from '@/router'

export default {
  state: {
    allTopic: [],
    allComments: [],
    detailCommentAdmin: []
  },
  mutations: {
    setAllTopical (state, payload) {
      state.allTopic = payload
    },
    setAllCommentsTopical (state, payload) {
      state.allComments = payload
    },
    setDetailCommentTopic (state, payload) {
      state.detailCommentAdmin = payload
    }
  },
  actions: {
    async setAllTopical (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/topic`)
      res = await res.json()
      ctx.commit('setAllTopical', await res.topic)
    },
    async setAllCommentsTopical (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/topic/${payload}?status=ALL`)
      res = await res.json()
      ctx.commit('setAllCommentsTopical', await res)
    },
    async setDetailCommentTopic (ctx, payload) {
      // детальная информация - комментарий
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/topic/comment/${payload}`)
      res = await res.json()
      ctx.commit('setDetailCommentTopic', await res.data)
    },
    async deleteCommentAdminTopic (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/topic/comment/${payload.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllCommentsTopical', payload.member)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Блог удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async updateCommemtTopic (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/topic/comment/${payload[1]}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/comments_topical')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Редактирование комментария прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async createCommemtAdminTopic (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/topic/${payload[1]}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload[0]
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/comments_topical')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание комментария прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getAllCommentsTopical (s) {
      return s.allComments
    },
    getAllTopical (s) {
      return s.allTopic
    },
    getDetailCommentTopic (s) {
      return s.detailCommentAdmin
    }
  }
}
