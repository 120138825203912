import router from '@/router'

export default {
  state: {
    normativeBase: [],
    detailNormativeBase: null
  },
  mutations: {
    setNormativeBase (state, payload) {
      state.normativeBase = payload
    },
    setDetailNormativeBase (state, payload) {
      state.detailNormativeBase = payload
    }
  },
  actions: {
    async setNormativeBase (ctx, settings) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/normative-base`, settings)
      res = await res.json()
      console.log(await res)
      ctx.commit('setNormativeBase', await res.data)
    },
    async setDetailNormativeBase (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/normative-base/${payload}`, {
        headers: {
          admin: true,
          Authorization: localStorage.getItem('token')
        }
      })
      res = await res.json()
      console.log(await res)
      ctx.commit('setDetailNormativeBase', await res.data)
      await router.push('/admin/normative_base/create?type=edit')
    },
    async createNormativeBase (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/normative-base`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setNormativeBase')
            router.push('/admin/normative_base')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание нормативной базы прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteNormativeBase (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/normative-base/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setNormativeBase')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Раздел был удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async editNormativeBase (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/normative-base/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setNormativeBase')
            router.push('/admin/normative_base')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение пункта прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getNormativeBase (s) {
      return s.normativeBase
    },
    getDetailNormativeBase (s) {
      return s.detailNormativeBase
    }
  }
}
