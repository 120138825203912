import router from '@/router'
import { queryBulder } from '@/methods/queryBulder'

export default {
  state: {
    members: [],
    department: [],
    inclusionGround: [],
    selectYear: [],
    detailMember: null
  },
  mutations: {
    setMembers (state, payload) {
      state.members = payload
    },
    setDepartment (state, payload) {
      state.department = payload
    },
    setInclusionGround (state, payload) {
      state.inclusionGround = payload
    },
    setSelectYear (state, payload) {
      const data = state.selectYear
      data[payload.index] = payload.data
      state.selectYear = []
      state.selectYear = data
    },
    delSelectYear (state, payload) {
      state.selectYear = state.selectYear.splice(payload, 1)
    },
    setDetailMembers (state, payload) {
      state.detailMember = payload
    }
  },
  actions: {
    async setAllMembers (ctx) {
      // список всех членов ОПРФ и список отделов
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/member`)
      res = await res.json()
      console.log(await res.data.members)
      ctx.commit('setMembers', await res.data.members)
      ctx.commit('setDepartment', await res.data.units)
    },
    async setFilterMembers (ctx, payload) {
      const url = queryBulder(`${ctx.getters.getUrlApi}api/admin/member`, payload)
      let res = await fetch(url)
      res = await res.json()
      ctx.commit('setMembers', await res.data.members)
    },
    async setInclusionGround (ctx) {
      // кем утвержден
      let code = await fetch(`${ctx.getters.getUrlApi}api/admin/directory/inclusion-ground`)
      code = await code.json()
      ctx.commit('setInclusionGround', await code.data)
    },
    async setSelectYear (ctx, payload) {
      // Получаю данные после того, как человек выбрал год созыва
      let unit = await fetch(`${ctx.getters.getUrlApi}api/unit/convocation/${payload.value}`)
      unit = await unit.json()
      ctx.commit('setSelectYear', await {
        data: unit.data,
        index: payload.index
      })
    },
    async createMember (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/member/create`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload
      })
        .then(res => {
          if (res.status === 201) {
            ctx.dispatch('setAllMembers', ctx.getters.getUrlApi)
            router.push('/admin/members')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание члена ОПРФ прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    },
    async deleteMember (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/member/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllMembers', ctx.getters.getUrlApi)
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Член ОПРФ удален'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailMembers (ctx, payload) {
      // детальная информация члена ОПРФ для редактирования
      let res = await fetch(`${ctx.getters.getUrlApi}api/admin/member/${payload}`)
      res = await res.json()
      console.debug('--------')
      console.debug(await res.data)
      console.debug('--------')
      ctx.commit('setDetailMembers', await res.data)
      await router.push('/admin/members/create_member?type=edit')
    },
    async editMember (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/member/${payload.id}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setAllMembers', ctx.getters.getUrlApi)
            router.push('/admin/members')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Изменение профиля прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
          return res.json()
        })
        .catch(error => console.log('error', error))
    }
  },
  getters: {
    getInclusionGround (s) {
      return s.inclusionGround
    },
    getDepartment (s) {
      return s.department
    },
    getMembers (s) {
      return s.members
    },
    getSelectYear (s) {
      console.log(s.selectYear)
      return s.selectYear
    },
    getDetailMember (s) {
      return s.detailMember
    }
  }
}
