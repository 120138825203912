import router from '@/router'

export default {
  state: {
    corruptionGroup: [],
    detailCorruptionGroup: null
  },
  mutations: {
    setCorruptionGroup (state, payload) {
      state.corruptionGroup = payload
    },
    setDetailCorruptionGroup (state, payload) {
      state.detailCorruptionGroup = payload
    }
  },
  actions: {
    async setCorruptionGroup (ctx) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/corruption/section/group`)
      res = await res.json()
      ctx.commit('setCorruptionGroup', await res.data)
    },
    async AddCorruptionGroup (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/corruption/section/${payload.id}/group`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/corruption_group')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Создание группы прошло успешно'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    },
    async deleteCorruptionGroup (ctx, payload) {
      await fetch(`${ctx.getters.getUrlApi}api/admin/corruption/section/group/${payload}`, {
        method: 'DELETE',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            ctx.dispatch('setCorruptionGroup')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Группа удалена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка удаления'
            })
          }
        })
    },
    async setDetailCorruptionGroup (ctx, payload) {
      let res = await fetch(`${ctx.getters.getUrlApi}api/corruption/section/group/${payload}`, {
        headers: {
          admin: true
        }
      })
      res = await res.json()
      ctx.commit('setDetailCorruptionGroup', await res.data)
      await router.push('/admin/corruption_group/create?type=edit')
    },
    async editCorruptionGroup (ctx, payload) {
      const url = `${ctx.getters.getUrlApi}api/admin/corruption/section/group/${payload.id}`
      await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: payload.data
      })
        .then(res => {
          if (res.status === 200) {
            router.push('/admin/corruption_group')
            ctx.commit('setMessage', {
              type: 'success',
              text: 'Информация о группе успешно обновлена'
            })
          } else {
            ctx.commit('setMessage', {
              type: 'error',
              text: 'Произошла ошибка'
            })
          }
        })
    }
  },
  getters: {
    getCorruptionGroup (s) {
      return s.corruptionGroup
    },
    getDetailCorruptionGroup (s) {
      return s.detailCorruptionGroup
    }
  }
}
